import { AccordionPanel } from "@chakra-ui/react";
import React from "react";

const QuizAccordionPanel = ({ children, ...props }) => {
  return (
    <AccordionPanel
      border="1px"
      borderColor="gray.200"
      m="10px"
      p="8px"
      borderRadius="md"
      {...props}
    >
      {children}
    </AccordionPanel>
  );
};

export default QuizAccordionPanel;
