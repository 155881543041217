import { useState } from 'react';
import styled from 'styled-components'
import { ReactComponent as DateImg } from '@images/icons/date.svg';
import { ReactComponent as ArrowLeftImg } from '@images/icons/arrow-left.svg';
import { ReactComponent as ArrowRightImg } from '@images/icons/arrow-right.svg';
import ChooseDate from './ChooseDate';

const DatePrevBtn = styled.button`
  width: 20px;
  height: 20px;
  border: 1px solid #212120;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const DateBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 10px;  
  color: #212120;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.25px;
`
const DateNextBtn = styled.button`
  width: 20px;
  height: 20px;
  border: 1px solid #212120;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AttendanceCalendarDatePicker = ({ year, month, setYear, setMonth }) => {
  const [isShowDateContainer, setIsShowDateContainer] = useState(false)

  return (
    <>
      <DatePrevBtn onClick={() => {
        if (month === 1) {
          setYear(year - 1)
          setMonth(12)
        } else {
          setMonth(month - 1)
        }
      }}>
        <ArrowLeftImg />
      </DatePrevBtn>
      <DateBtn onClick={() => { setIsShowDateContainer(true) }}>
        <DateImg />
        {year}년 {month}월
      </DateBtn>
      <DateNextBtn onClick={() => {
        if (month === 12) {
          setYear(year + 1)
          setMonth(1)
        } else {
          setMonth(month + 1)
        }
      }}>
        <ArrowRightImg />
      </DateNextBtn>

      {
        isShowDateContainer && (
          <div style={{
            position: 'absolute',
            top: 'calc(100% + 10px)'
          }}>
            <ChooseDate year={year}
                        month={month}
                        changeYear={ (year) => setYear(year) }
                        changeMonth={ (month) => setMonth(month) }
                        close={ () => { setIsShowDateContainer(false) } }
            />
          </div>
        )
      }
    </>
  )
}

export default AttendanceCalendarDatePicker