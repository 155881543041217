import React, {
  memo,
  useEffect,
  useMemo,
  useRef,
  useCallback,
  useState,
} from "react";
import EraserIcon from "@icons/material/EraserIcon";
import { ReactSketchCanvas } from "react-sketch-canvas";
import { GithubPicker } from "react-color";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  setMyCommentsShared,
  setReportLog,
  setScreenData,
} from "@store/actions";
import { selectActiveClassroom } from "@store/selectors";
import { Replay, Create, ScreenShare } from "@mui/icons-material";
import { ButtonConstants } from "constants/buttonConstants";

const CommentsLayerComponent = ({ isVisible }) => {
  const dispatch = useDispatch();
  const {
    teacherComments,
    studentComments,
    commentsTarget,
    strokeColor,
    isMyCommentsSharing,
  } = useSelector(state => state.commentsLayer);
  const { selectedStd } = useSelector(state => state.control);
  const { clientId } = useSelector(state => state.user);
  const { groupClientIds } = useSelector(state => state.membership);
  const otherGroupClientId = groupClientIds.filter(id => id !== clientId);
  const activeClassroom = useSelector(selectActiveClassroom);

  const canvasRef = useRef(null);
  const [isEraserMode, setIsEraserMode] = useState(false);

  const isScreenSharingTarget = useMemo(
    () => activeClassroom?.screenShared && selectedStd === clientId,
    [activeClassroom?.screenShared, clientId, selectedStd],
  );

  const isCommentsTarget = useMemo(
    () => commentsTarget.findIndex(id => id === clientId) !== -1,
    [clientId, commentsTarget],
  );

  const onSendComments = useCallback(
    (data, targetClients) => {
      if (!activeClassroom?.groupId) {
        return;
      }
      dispatch(
        setScreenData({
          method: "POST",
          uri: "/classroom/sendImage",
          type: "COMMENTS_STUDENT",
          clientId,
          groupId: activeClassroom.groupId,
          data,
          targetClients,
        }),
      );
    },
    [activeClassroom?.groupId, clientId, dispatch],
  );
  const onPathChange = useCallback(async () => {
    if (!canvasRef?.current || !activeClassroom?.groupId) {
      return;
    }

    let targetClients = otherGroupClientId;
    const data = await canvasRef.current.exportImage("png");

    if (!isScreenSharingTarget || !isMyCommentsSharing) {
      targetClients = [];
    }
    onSendComments(data, targetClients);
  }, [
    activeClassroom?.groupId,
    isScreenSharingTarget,
    isMyCommentsSharing,
    otherGroupClientId,
    onSendComments,
  ]);

  const onChangeEraserMode = useCallback(isEraser => {
    canvasRef?.current.eraseMode(isEraser);
    setIsEraserMode(isEraser);
  }, []);

  const onClear = useCallback(async () => {
    await canvasRef?.current.clearCanvas();
    onPathChange();
  }, [onPathChange]);

  const onIsMyCommentsShared = useCallback(() => {
    dispatch(setMyCommentsShared(!isMyCommentsSharing));
  }, [dispatch, isMyCommentsSharing]);

  useEffect(() => {
    if (selectedStd !== clientId) {
      onSendComments("", otherGroupClientId);
    } else {
      onPathChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStd, clientId]);

  if (!isVisible) {
    return null;
  }

  return (
    <StyledContainer>
      <StyledToolkitWrap>
        <StyledModeButton
          $isActive={!isEraserMode}
          onClick={() => {
            onChangeEraserMode(false);
            dispatch(setReportLog(ButtonConstants.COMMENTS_LAYER.PEN));
          }}
        >
          <Create />
        </StyledModeButton>
        <StyledModeButton
          $isActive={isEraserMode}
          onClick={() => {
            onChangeEraserMode(true);
            dispatch(setReportLog(ButtonConstants.COMMENTS_LAYER.ERASER));
          }}
        >
          <EraserIcon />
        </StyledModeButton>
        <StyledModeButton
          onClick={() => {
            onClear();
            dispatch(setReportLog(ButtonConstants.COMMENTS_LAYER.CLEAR));
          }}
        >
          <Replay />
        </StyledModeButton>
        <StyledModeButton
          $isActive={isMyCommentsSharing}
          onClick={() => {
            onIsMyCommentsShared();
            dispatch(setReportLog(ButtonConstants.COMMENTS_LAYER.SCREENSHARE));
          }}
        >
          <ScreenShare />
        </StyledModeButton>
      </StyledToolkitWrap>
      {otherGroupClientId
        .filter(id => !!studentComments[id])
        .map(id => (
          <StyledBackgroundComments key={`comments-${id}`}>
            <img src={studentComments[id]} alt="student-comments" />
          </StyledBackgroundComments>
        ))}
      {isCommentsTarget && teacherComments && (
        <StyledBackgroundComments>
          <img src={teacherComments} alt="teacher-comments" />
        </StyledBackgroundComments>
      )}
      <ReactSketchCanvas
        ref={canvasRef}
        id="react-sketch-canvas-student"
        style={{
          position: "absolute",
          inset: 0,
          zIndex: 2,
        }}
        eraserWidth={80}
        strokeWidth={4}
        strokeColor={strokeColor}
        backgroundImage="none"
        onStroke={onPathChange}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: crosshair;
`;
const StyledToolkitWrap = styled.div`
  display: flex;
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 8px 4px;
  color: white;
  z-index: 5;
  border-radius: 8px;
`;
const StyledModeButton = styled.button`
  padding: 8px;
  font-size: 12px;
  background-color: ${({ $backgroundColor }) => $backgroundColor || "#969696"};
  border-radius: 50%;
  margin: 0 4px;

  ${({ $isActive }) =>
    $isActive &&
    `
      background-color: white;
      color: black;
  `}
`;
const StyledStudentLastImage = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
  }
`;
const StyledGithubPicker = styled(GithubPicker)`
  position: absolute !important;
  right: 10px;
  bottom: -90px;
  width: 188px !important;
`;
const StyledBackgroundComments = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const CommentsLayer = memo(CommentsLayerComponent);
