import { Input, TextField } from "@mui/material";
import QuizKeyboardLogger from "@pages/QuizPang/quiz-keyboard-logger";
import { debounce } from "@pages/Survey/utils";
import { createSurveyresponse } from "@store/actions";
import useKeyboardLogger from "hooks/useKeyboardLogger";
import React, { useEffect, useRef, useState } from "react";
import { Stack } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { useDispatch } from "react-redux";

export const ShortChoices = ({
  title,
  quizId,
  quizType,
  submit,
  handleSubmit,
  keyboardData,
  setKeyboardData,
  deviceType,
  link,
  setSurveyAnswers,
  quizCurrentIndex,
  upload,
}) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(null);

  const handleKeyData = (data) => {
    console.log(data, "short-choices");
    setKeyboardData((prevData) => [...data]);
  };

  useKeyboardLogger(handleKeyData, inputValue);

  useEffect(() => {
    if (submit) {
      handleSubmit(inputValue, quizId);
    }
  }, [submit, handleSubmit, inputValue, quizId]);

  const handleTextChange = (text) => {
    const inputValue = text.target.value;
    setInputValue(inputValue);
  };

  useEffect(() => {
    dispatch(createSurveyresponse(quizId, null, quizType, inputValue, null));
  }, [upload, inputValue]);

  return (
    <Stack direction='column'>
      <ReactQuill
        value={title}
        readOnly
        theme='bubble'
        style={{
          width: "100%",
          height: "auto",
        }}
      />
      {link && link !== "NotSet" ? (
        <img
          crossOrigin='anonymous'
          src={link}
          alt='quiz-image'
          style={{
            width: "480px",
            height: "auto",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "20px",
            marginTop: "120px",
          }}
        />
      ) : null}
      <Input
        id='outlined-multiline-static'
        defaultValue={inputValue}
        value={inputValue}
        onChange={handleTextChange}
        sx={{
          width: "70%",
          marginTop: "20px",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "20px",
        }}
        multiline={true}
      />
      {/* <div
        style={{
          display: 'flex',
          width: '100%',
          marginBottom: '20px',
        }}
      >
        <QuizKeyboardLogger
          input={inputValue}
          setInput={setInputValue}
          keyboardData={keyboardData}
          setKeyboardData={setKeyboardData}
          deviceType={deviceType}
          quizCurrentIndex={quizCurrentIndex}
        />
      </div> */}
      {deviceType !== "iOS" && deviceType !== "Android" ? null : (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <QuizKeyboardLogger
            input={inputValue}
            setInput={setInputValue}
            keyboardData={keyboardData}
            setKeyboardData={setKeyboardData}
            deviceType={deviceType}
            quizCurrentIndex={quizCurrentIndex}
          />
        </div>
      )}
    </Stack>
  );
};
