import { AccordionButton, AccordionIcon, Text, HStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";

const QuizResultAccordionButton = ({ resultDataItem }) => {
  const { title, quizType } = resultDataItem;
  const { t } = useTranslation();
  const renderQuizTypeText = (quizType) => {
    switch (quizType) {
      case "OX":
        return "O/X";
      case "MULTIPLE":
        return t("quiztype.multiple");
      case "SHORT":
        // return "단답형";
        return t("quiztype.shortanswer");
      case "STYLUS":
        // return "필기형";
        return t("quiztype.pen");
      case "LONG":
        // return "서술형";
        return t("quiztype.longanswer");
      default:
        return null;
    }
  };
  return (
    <AccordionButton>
      <HStack
        w="full"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        overflow="hidden"
        whiteSpace="normal"
        wordBreak="break-word"
      >
        <ReactQuill
          value={title}
          readOnly={true}
          theme="bubble"
          modules={{
            toolbar: false,
          }}
        />
        <HStack>
          <Text fontSize="sm" fontWeight="bold" minWidth="70px">
            {renderQuizTypeText(quizType)}
          </Text>
          <AccordionIcon />
        </HStack>
      </HStack>
    </AccordionButton>
  );
};

export default QuizResultAccordionButton;
