import {
  PURGE_NOTIFICATION,
  CREATE_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION_FAIL,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL,
  FIND_NOTIFICATION_SUCCESS,
  FIND_NOTIFICATION_FAIL,
  GET_ALL_NOTIFICATION_SUCCESS,
  GET_ALL_NOTIFICATION_FAIL,
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
} from './actionTypes';
import { makeFreshAllIds } from '../helpers';

const initialState = {
  notifications: [],
  byId: {},
  allIds: [],
  fetchedAllNotification: false,
  selectedNotificationId: null,
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_NOTIFICATION:
      return initialState;
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.notifications,
      };
    case READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.map((noti) => {
          if (noti.notificationId === action.notification) {
            return action.notification;
          }
          return noti;
        }),
      };

    case CREATE_NOTIFICATION_SUCCESS:
    case FIND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.notification.id]: action.notification },
        allIds: makeFreshAllIds(state.allIds, [action.notification.id]),
      };

    case DELETE_NOTIFICATION_SUCCESS:
      const newAllIds = [
        ...state.allIds.filter((id) => id !== action.notificationId),
      ];
      const newById = { ...state.byId };
      if (action.notificationId in newById) {
        delete newById[action.notificationId];
      }
      return { ...state, allIds: newAllIds, byId: newById };

    case GET_ALL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        fetchedAllNotification: true,
        byId: { ...state.byId, ...action.byId },
        allIds: makeFreshAllIds(state.allIds, action.allIds),
      };

    case CREATE_NOTIFICATION_FAIL:
    case GET_NOTIFICATION_FAIL:
    case FIND_NOTIFICATION_FAIL:
    case GET_ALL_NOTIFICATION_FAIL:
    case READ_NOTIFICATION_FAIL:
    case DELETE_NOTIFICATION_FAIL:
      // console.error('[Notification Reducer] ', action.payload)
      return state;
    default:
      break;
  }
  return state;
};

export default notification;
