import React, { useEffect } from 'react';
import {
  getProgramsPromise,
  updateExtensionProgramconfigsPromise,
} from '@store/actions';
import {
  selectAllPrograms,
  selectManagingProgramconfigs,
} from '@store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  selectActiveClassroom,
  selectClassroomGroup,
  selectGroupProgramconfigs,
} from '@app/store/selectors';
import { useInterval } from './utils';

const BlockingManager = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProgramsPromise());
  }, []);

  // const chromeExtensionId = 'bdaakfpecncidfoeiadgodljhkbcnekk';
  const chromeExtensionId = process.env.REACT_APP_CHROME_EXTENSION_ID;

  const user = useSelector((state) => state.user);
  const allPrograms = useSelector((state) => selectAllPrograms(state));
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const groupInfoOfActiveClassroom = useSelector((state) =>
    selectClassroomGroup(state, activeClassroom)
  );
  const activeClassroomProgramconfigs = useSelector((state) =>
    selectGroupProgramconfigs(state, groupInfoOfActiveClassroom?.groupId)
  );

  const activeManaging = useSelector(
    (state) => state.managing?.managingSession
  );
  const activeManagingProgramconfigs = useSelector((state) =>
    selectManagingProgramconfigs(state, activeManaging)
  );

  const programconfigsManaging = useSelector((state) =>
    selectGroupProgramconfigs(state, activeManaging?.managingId)
  );

  //make webBlockList
  const blockedProgrmconfigs = activeClassroomProgramconfigs.filter(
    (activeClassroomProgramconfig) =>
      activeClassroomProgramconfig.isAllowed !== true
  );

  const blockedPrograms = blockedProgrmconfigs?.map(
    (blockedProgrmconfig) =>
      allPrograms?.filter(
        (program) => program.programId === blockedProgrmconfig.programId
      )[0]
  );

  const buildProgramconfigWebPageList = (blockedPrograms) => {
    if (!blockedPrograms) {
      return;
    }
    const blockedWebPageList = blockedPrograms?.map((blockedProgram) => {
      if (blockedProgram?.web == undefined) {
        return '_____';
      }
      return blockedProgram.web;
    });

    const blockedWebPageListString = blockedWebPageList.join(',');

    try {
      chrome.runtime.sendMessage(
        chromeExtensionId,
        { type: 'CLASS_START', data: blockedWebPageListString },
        function(response) {
          console.log('[BlockingManager]: ', response);
        }
      );
    } catch (error) {}
  };

  useEffect(() => {
    if (!activeClassroom) {
      try {
        chrome.runtime.sendMessage(
          chromeExtensionId,
          { type: 'CLASS_END' },
          function(response) {
            console.log('[BlockingManager]: ', response);
          }
        );
      } catch (error) {}

      return;
    }
    if (blockedPrograms.length === 0) {
      return;
    }
    buildProgramconfigWebPageList(blockedPrograms);
  }, [activeClassroom, activeClassroomProgramconfigs]);

  // const managingPrograms = programconfigsManaging?.map(
  //   (programconfig) =>
  //     allPrograms?.filter(
  //       (program) => program.programId === programconfig.programId
  //     )[0]
  // );

  const managingPrograms = programconfigsManaging
    ? programconfigsManaging.map(
        (programconfig) =>
          allPrograms?.filter(
            (program) => program.programId === programconfig.programId
          )[0]
      )
    : [];

  const managingProgramsWebAndDayMax = managingPrograms?.map((program) => {
    const programconfig = programconfigsManaging?.filter(
      (programconfig) => programconfig.programId === program.programId
    )[0];
    if (program.web === undefined) {
      return {
        programId: program.programId,
        web: '______',
        dayMax: programconfig.dayMax,
        usage: 0,
      };
    }

    return {
      programId: program.programId,
      web: program.web,
      dayMax: programconfig.dayMax,
      usage: 0,
    };
  });

  const managingProgramsWebAndDayMaxArray = managingProgramsWebAndDayMax?.map(
    (program) => {
      return [
        activeManaging?.managingId,
        {
          url: program.web,
          dayMax: program.dayMax,
          usage: program.usage,
          clientId: user?.clientId,
          programId: program.programId,
        },
      ];
    }
  );

  useEffect(() => {
    try {
      chrome.runtime.sendMessage(
        chromeExtensionId,
        { type: 'SET_MANAGING', data: activeManaging?.managingId },
        function(response) {
          console.log('[BlockingManager]: ', response);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }, [activeManaging?.managingId]);

  useInterval(() => {
    if (!activeManaging) {
      try {
        chrome.runtime.sendMessage(
          chromeExtensionId,
          { type: 'MANAGING_PROGRAMS_INIT' },
          (response) => {}
        );
      } catch (error) {
        console.error(
          '크롬 확장 프로그램이 설치되어 있지 않거나 확장 프로그램 아이디가 잘못되었습니다.'
        );
      }
    }

    try {
      chrome.runtime.sendMessage(
        chromeExtensionId,
        { type: 'MANAGING_PROGRAMS', data: managingProgramsWebAndDayMaxArray },
        function(response) {
          dispatch(updateExtensionProgramconfigsPromise(response));
        }
      );
    } catch (error) {
      console.error(
        '크롬 확장 프로그램이 설치되어 있지 않거나 확장 프로그램 아이디가 잘못되었습니다.'
      );
    }
  }, 5000);

  const buildProgrmconfigInitializationParameters = (programs) => {
    if (!activeClassroom) {
      return null;
    }
    const availablePrograms = programs?.filter(
      (program) =>
        program.windows !== '' ||
        program.windows !== null ||
        program.windows !== undefined ||
        program.web !== '' ||
        program.web !== null ||
        program.web !== undefined
    );

    const buildParams = availablePrograms
      ?.map((program) => {
        const programconfig = activeClassroomProgramconfigs?.find(
          (programconfig) => programconfig?.programId === program?.programId
        );

        if (
          programconfig === null ||
          programconfig === undefined ||
          programconfig === ''
        )
          return null;

        return {
          programconfigId: programconfig?.programconfigId,
          programId: programconfig?.programId,
          clientId: programconfig?.clientId,
          groupId: programconfig?.groupId,
          dayMax: programconfig?.dayMax,
          isAllowed: programconfig?.isAllowed,

          alias: program?.alias,
          name: program?.engName,
          exeName: program?.windows,
          webpageName: program?.web,
        };
      })
      .filter((data) => data !== null);

    return buildParams;
  };

  useDeepCompareEffect(() => {
    // unified with programconfig
    if (activeClassroomProgramconfigs?.length === 0) {
      return;
    }

    if (allPrograms?.length !== 0) {
      const initParams = buildProgrmconfigInitializationParameters(allPrograms);

      console.log(
        '[BlockingManagerOnClass]',
        'Initializing monitored programconfigs..',
        initParams
      );
      try {
        window.cAPI.initializeProgramconfigs(initParams);
      } catch (error) {
        // it's OK, when it is browser app, the error is normal.
        // console.log(error);
      }
    }
  }, [allPrograms, activeClassroomProgramconfigs, activeClassroom]);

  useEffect(() => {
    if (user?.token !== undefined) {
      // NativeModules.HelperModule.setToken(user.token);
    }
  }, []);

  return <div />;
};

export default BlockingManager;
