import {
  CREATE_SURVEY_RESPONSE,
  PURGE_SURVEYRESPONSE,
  PURGE_SURVEY_LIST_IDS,
  SET_SURVEY_LIST_IDS,
  UPDATE_SRUVEY_LIST_IDS,
} from "./actionTypes";

const initialState = {
  quizResponse: {},
  quizsetIdList: [],
};

const surveyResponse = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_SURVEYRESPONSE:
      return initialState;

    case CREATE_SURVEY_RESPONSE:
      return {
        ...state,
        quizResponse: {
          ...state.quizResponse,
          [action.payload.quizId]: action.payload,
        },
      };
    case SET_SURVEY_LIST_IDS:
      return {
        ...state,
        quizsetIdList: action.payload,
      };
    case PURGE_SURVEY_LIST_IDS:
      return {
        ...state,
        quizsetIdList: [],
      };
    case UPDATE_SRUVEY_LIST_IDS:
      const quizsetId = action.payload;
      const quizsetIdList = state.quizsetIdList;
      const quizsetIdIndex = state.quizsetIdIndex;

      if (quizsetIdList.includes(quizsetId)) {
        quizsetIdIndex[quizsetId] = true;
      }

      return {
        ...state,
        quizsetIdIndex: quizsetIdIndex,
      };

    default:
      break;
  }
  return state;
};
export default surveyResponse;
