import { getTestCrewType } from "@api";
import { useQuery } from "@tanstack/react-query";

export const useTestCrewType = (quizsetSessionId, testCrewType, clientId) => {
  const { data: crewType } = useQuery({
    queryKey: ["quizsetSession", quizsetSessionId],
    queryFn: () => getTestCrewType(quizsetSessionId, testCrewType, clientId),
    enabled: !!quizsetSessionId,
    select: ({ data }) => data.testCrewType,
  });

  return {
    crewType,
  };
};
