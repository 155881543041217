import { Stack } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Column } from '@pages/Survey/common';
import { createSurveyresponse } from '@store/actions';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { useDispatch } from 'react-redux';

export const TimeChoices = ({
  title,
  quizId,
  submit,
  quizType,
  handleSubmit,
  link,
  setSurveyAnswers,
  quizCurrentIndex,
  upload,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState();

  // TIME형이라 responseText는 input text 전달임

  useEffect(() => {
    if (submit) {
      handleSubmit(value, quizId);
    }
  }, [submit, handleSubmit, value, quizId]);

  useEffect(() => {
    // quizType와 quizId,입력값을 저장
    if (value) {
      setSurveyAnswers((prev) => {
        return {
          ...prev,
        };
      });
      setValue(null);
    }
  }, [quizCurrentIndex]);

  useEffect(() => {
    dispatch(createSurveyresponse(quizId, null, quizType, value, null));
  }, [upload, value]);

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        mb: 2,
      }}
    >
      <ReactQuill
        value={title}
        readOnly
        theme="bubble"
        style={{
          width: '100%',
          height: 'auto',
        }}
      />
      {link && link !== 'NotSet' ? (
        <img
          crossOrigin="anonymous"
          src={link}
          alt="quiz-image"
          style={{
            width: '50%',
            height: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '20px',
          }}
        />
      ) : null}

      <div className="flex justify-center items-center">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['TimePicker', 'TimePicker']}>
            <TimePicker
              label="시간을 입력해주세요"
              // ERR: value.hours 관련 에러 발생
              // 참조가 잘되어있는지 확인이 필요합니다.
              // value={selectedValue}
              value={value}
              onChange={(time) => {
                setValue(time);
              }}
              sx={{ margin: '40px auto' }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </div>
    </Stack>
  );
};
