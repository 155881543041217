import { setReportLog } from "@store/actions";
import { ButtonConstants } from "constants/buttonConstants";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

const Layout = styled.div`
  position: relative;
  margin: 16px;
  background: ${props => (props.active ? "#E4F6F8" : "#fff")};
  border-radius: 8px;
  padding: 12px 16px;
  cursor: pointer;

  :hover {
    background: ${props =>
      props.active ? "rgba(5, 134, 146, 0.15)" : "#E4F6F8"};
  }
`;

const InnerLayout = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: ${props => (props.active ? "#058692" : "#757575")};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Badge = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  /* border: 2px solid #fff; */
  width: 24px;
  height: 24px;
  font-size: 12px;
  background-color: #ff5252;
  border-radius: 4px;
`;

const SidebarTab = props => {
  const { icon, text = "", active = false, rightComponent, href, noti } = props;

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  return (
    <Layout
      active={active}
      onClick={() => {
        href && history.replace(href, location.state);
        dispatch(setReportLog(ButtonConstants.SIDEBAR.SIDEBAR));
      }}
    >
      <InnerLayout active={active}>
        <Left>
          {icon}
          <span>{text}</span>
        </Left>
        {rightComponent && rightComponent}
      </InnerLayout>
      {Boolean(noti) && typeof noti === "number" && <Badge>{noti}</Badge>}
    </Layout>
  );
};

export default memo(SidebarTab, (prev, next) => {
  return (
    prev.active === next.active &&
    prev.href === next.href &&
    prev.text === next.text
  );
});
