import React from "react";
import { Redirect } from "react-router-dom";

// Pages Component
import Classroom from "@pages/Classroom/classroom";
import Home from "@pages/Home";
import Attendance from "@pages/Attendance";
import SignIn from "@pages/Auth";
import Settings from "@pages/Settings/index";
import Dev from "@pages/Dev";
import AuthManager from "@managers/AuthManager";
import OffClass from "@pages/Classroom/off-class";
import SetFillInfo from "@pages/Settings/edit-profile";
import BandAssignment from "@pages/Classroom/band-assignment";
import Managing from "@pages/Classroom/managing";
import { Notice } from "@pages/Notice"; // 공지사항
import { Assignment } from "@pages/Assignment"; // 과제
import ScreenLock from "@pages/ScreenLock";
import Survey from "@pages/Survey";
import { PermissionPage } from "@pages/Permission";
import QuizPang from "@pages/QuizPang";
import QuizReadyStart from "@pages/QuizPang/quiz-ready-start";
import { QuizsetSurveyReady } from "@pages/QuizPang/quizset-survey-ready";
import QuizResult from "@pages/QuizPang/QuizResult";
import { QuizBefore } from "@pages/QuizPang/QuizBefore";
import { QuizSession } from "@pages/QuizPang/QuizSession";
import { QuizWait } from "@pages/QuizPang/QuizWait";
import { QuizPostureCheckPage } from "@pages/QuizPang/QuizBefore";
import SurveyBase from "@pages/Survey/survey-base";
import FocusSurvey from "@pages/FocusSurvey";
import SurveyWrapper from "@pages/FocusSurvey/SurveyWrapper";
import SurveyPackageWrapper from "@pages/FocusSurvey/SurveyPackageWrapper";

const authProtectedRoutes = [
  { path: "/home/", component: Home },
  { path: "/attendance", component: Attendance },
  // NOTE: groupId가 없을경우에도 라우팅이 되어야하기 떄문에 순서를 classroom위쪽으로 넣고 (/:groupId)가 없어도 작동되도록 해야함
  { path: "/permission", component: PermissionPage },
  { path: "/managing/:groupId?", component: Managing },
  { path: "/classroom/:groupId", component: Classroom },
  { path: "/classroom/band/:groupId", component: BandAssignment },
  { path: "/classroom/off/:groupId", component: OffClass },
  { path: "/auth", component: SignIn },
  { path: "/auth-manager", component: AuthManager },
  { path: "/settings", component: Settings },
  { path: "/classroom/:className", component: Classroom },
  { path: "/dev", component: Dev },
  { path: "/setfillinfo", component: SetFillInfo },
  { path: "/notice/:groupId", component: Notice }, // 공지사항
  { path: "/assignment/:groupId", component: Assignment }, // 과제
  { path: "/screenlock", component: ScreenLock },
  { path: "/quizpang/:groupId", component: QuizPang },
  { path: "/quizpang/:groupId/wait", component: QuizWait },
  { path: "/quizpang/:groupId/posture", component: QuizPostureCheckPage },
  { path: "/quizpang/:groupId/ready/:quizsetId", component: QuizBefore },
  {
    path: "/quizpang/:groupId/session/:quizsetSessionId",
    component: QuizSession,
  },
  {
    path: "/quizpang/:groupId/result/:quizsetSessionId",
    component: QuizResult,
  },

  { path: "/inquizpang/before/:groupId", component: QuizBefore },
  { path: "/inquizpang/ready/:quizsetSessionId", component: QuizReadyStart },
  {
    path: "/inquizpang/session/:quizsetSessionId/:quizIndex",
    component: QuizSession,
  },
  {
    path: "/inquizpang/result/:quizsetSessionId/:quizIndex",
    component: QuizResult,
  },
  { path: "/focussurvey/:groupId", component: FocusSurvey },
  {
    path: "/focussurvey/:groupId/:quizsetId/:quizsetSessionId",
    component: SurveyWrapper,
  },
  // 심리검사
  { path: "/survey/:groupId", component: SurveyPackageWrapper },
  //{ path: '/survey/:quizsetSessionId', component: Survey },
  { path: "/survey/:groupId/:quizsetId/:quizsetSessionId", component: Survey },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  // useEffect(()=>{
  //   const unlisten = history.listen(()=>{});
  //   return () => {
  //     unlisten();
  //   }
  // },[history]),
  {
    path: "/",
    exact: true,
    component: () => (
      <Redirect
        to={{
          pathname: "/auth-manager",
        }}
      />
    ),
  },
];

const publicRoutes = [{ path: "/classroom", component: Classroom }];

export { authProtectedRoutes, publicRoutes };
