import React, { useState } from "react";
import styles from "./index.module.scss";
import InviteCode from "@components/Modal/InviteCode";
import BaseCard from "@components/Card/baseCard";
import MultiLanguageSelectMenu from "@components/Language/MultiLanguageSelectMenu";
import { useTranslation } from "react-i18next";

const SignIn = () => {
  const [showInvite, setShowInvite] = useState(false);

  const openInviteCode = () => {
    setShowInvite(true);
  };
  const { t } = useTranslation();
  return (
    <BaseCard>
      <div className={styles["inner-box"]}>
        <div className={styles["container"]}>
          <div className={styles["base-ground"]}>
            <div className={styles["Span-greeting"]}>
              {/* 학생용 포커스팡에 오신 것을 */}
              {t("login.text.welcome")}
              {"\n"}
              {/* 환영합니다!  */}
              {t("login.text.welcome2")}
            </div>
            <div>
              <span className={styles["choose-text"]}>
                {/* 선생님께  */}
                {t("login.text.description.teacher")}
                <span className={styles["orange"]}>
                  {/* 초대코드 */} {t("login.text.description.emphasis")}
                </span>{" "}
                {/* 를 받으셨나요? */}
                {t("login.text.description.receive")}
              </span>
              <br />
              <br />
              <button
                type="button"
                className={styles["invite-submit"]}
                onClick={openInviteCode}
              >
                {/* 초대 코드로 로그인 */}
                {t("login.button.enter")}
              </button>
              <MultiLanguageSelectMenu />
            </div>
            <InviteCode showInvite={showInvite} setShowInvite={setShowInvite} />
          </div>
        </div>
      </div>
    </BaseCard>
  );
};

export default SignIn;
