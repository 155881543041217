import React, { useEffect, useMemo, useRef, useState } from "react";
// import { SpinnerCountdown } from '@app/pages/QuizPang/spinner-countdown'
import styled from "styled-components";
// import events from '@app/events'
import { useDispatch, useSelector } from "react-redux";
import { createQuizresponsePromise } from "@app/store/quizresponse/actions";
import {
  QuizInput,
  QuizChoice,
  QuizOX,
  QuizLongInput,
  QuizStylusWrite,
} from ".";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import getYoutubeId from "get-youtube-id";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
//import QuizAccelGyroLogger from './quiz-accelgyro-logger.js';
import QuizAccelGyroLogger from "../quiz-accel-gyro-logger-buffer.js";
//import QuizGPSLogger from './quiz-gps-logger.js';
import QuizGPSLogger from "../quiz-gps-logger-buffer.js";
//import QuizTouchLogger from './quiz-touch-logger-container'
import ArchiveModule from "../data-archive.js";
import QuizTouchLoggerContainer from "../quiz-touch-logger-container";
import QuizAudioLogger from "../quiz-audio-logger";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import QuizEyetrackingLogger from "../quiz-eyetracking-logger";
import { useHistory } from "react-router-dom";
import {
  notificationKind,
  useSystemNotification,
} from "hooks/useSystemNotification";
import { getUserAgent } from "../utils";
import {
  useQuiz,
  useQuizset,
  useQuizsetSession,
  useTestCrewType,
} from "../hooks";
import Loading from "@components/Loading/Loading";
import {
  imageToFile,
  uploadQuizStylusWrite,
  getTestCrewType,
  getCrewQuizset,
} from "@api";
import {
  Box,
  Container,
  Image,
  Text,
  VStack,
  Button,
  Spinner,
} from "@chakra-ui/react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { Countdown } from "../countdown";
import { purgeFocusType, setFocusType, setSocket } from "@store/actions";
import { ConstansType } from "common/Constans";
import { postEyeTrackingData } from "@api/quizpang/quizpangApi";
import {
  createEyetrackingDataPromise,
  setEyetrackingData,
} from "@store/actionFeedbacks/actions";
import { useTranslation } from "react-i18next";

const sessionDeviceType = getUserAgent();

export const QuizSession = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { groupId, quizsetSessionId } = useParams();
  const user = useSelector((state) => state.user);

  const {
    quizsetSession,
    quizOrder,
    isLoading,
    refetch,
    storageQuizTime,
    saveQuizTime,
    clearQuizTime,
  } = useQuizsetSession(groupId);

  const { crewType } = useTestCrewType(quizsetSessionId, null, user.clientId);
  const { quizset } = useQuizset(quizsetSession?.quizsetId, crewType);
  const { quiz } = useQuiz(
    quizset?.quizzes?.length > quizOrder
      ? quizset?.quizzes?.[quizOrder]?.quizId
      : null
  );
  const [isSpinner, setIsSpinner] = useState(false);
  const [textAnswer, setTextAnswer] = useState(null);
  const [choiceId, setChoiceId] = useState(null);
  const [stylusImage, setStylusImage] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [gpsData, setGpsData] = useState(null);
  const [accelgyroData, setAceelgyroData] = useState(null);
  const [sessionTouchData, setSessionTouchData] = useState([]);
  const [sessionKeyboardData, setSessionKeyboardData] = useState([]);
  const [sessionStylusData, setSessionStylusData] = useState([]);
  const [isStartRecording, setIsStartRecording] = useState(true);
  const [voiceData, setVoiceData] = useState(null);
  const [eyetrackingData, setEyeTrackingData] = useState(null);
  const [sessionDeviceInfo, setSessionDeviceInfo] = useState("");
  const [quizEnded, setQuizEnded] = useState(false);
  const [timer, setTimer] = useState(0);
  const [input, setInput] = useState("");
  const [random, setRandom] = useState(10);
  const [ablabsEyeData, setAblabsEyeData] = useState([]);

  const history = useHistory();
  const containerRef = useRef(null);
  const { onSendMessage } = useSystemNotification();
  const quizSessionType = "QUIZ";
  const eventHandler = useRef(() => {});
  //html태그들을 제외했을때 공백문자열만 있는지 검사
  const checkIfItOnlyContainsTags = (str) => {
    const strWithoutTags = str.replace(/(<([^>]+)>)/gi, "");
    if (strWithoutTags.trim().length === 0) {
      return null;
    }
    return str;
  };
  const handleQuizExit = () => {
    if (window.confirm("퀴즈를 나가시겠습니까?")) {
      clearQuizTime();
      history.replace(`/quizpang/${groupId}?tabKey=quiz`);
    }
  };

  const onTimeEnd = async () => {
    setQuizEnded(true);

    const data = {};
    if (choiceId !== null) data.choiceId = choiceId;
    if (stylusImage !== null) {
      const file = imageToFile(stylusImage, user.clientId);
      const res = await uploadQuizStylusWrite(
        file,
        quizsetSessionId,
        quiz.quizId
      );
      data.responseLink = res;
    }
    if (input.trim() !== "") data.responseText = input;

    if (input.trim() !== "" || choiceId !== null || stylusImage !== null) {
      await dispatch(
        createQuizresponsePromise({
          clientId: user.clientId,
          quizsetSessionId,
          quizId: quiz.quizId,
          ...data,
        })
      ).then(() => {
        dispatch(
          setSocket({
            method: "POST",
            uri: "/classroom/sendImage",
            groupId: groupId,
            clientId: user?.clientId,
            type: "QUIZ_SUBMIT",
            data: "",
          })
        );
      });
    }

    onSendMessage({
      notificationKind: notificationKind.QUIZSET_STUDENT_STATUS,
      payload: "QUIZ_SUBMIT",
    });

    setTimeout(() => {
      clearQuizTime();
      setQuizEnded(false);
      history.replace(`/quizpang/${groupId}/result/${quizsetSessionId}`);
    }, 3000);
  };
  useEffect(() => {
    setIsSpinner(!isLoading && quizOrder === 0 && !storageQuizTime);
  }, [isLoading, quizOrder, storageQuizTime]);

  useEffect(() => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const deviceInfo = `${screenWidth}x${screenHeight}`;
    setSessionDeviceInfo(deviceInfo);
  }, [refetch]);

  useEffect(() => {
    const handler = (params) => eventHandler.current(params);
    global.eventEmitter.once("WS_QUIZ_SUMMARY", handler);

    onSendMessage({
      notificationKind: notificationKind.QUIZSET_STUDENT_STATUS,
      payload: "QUIZ_INPROGRESS",
    });

    return () => {
      global.eventEmitter.off("WS_QUIZ_SUMMARY", handler);
    };
  }, []);

  const [focusState, setFocusState] = useState(0);
  const focusTimeState = useSelector((state) => state.control.focusTimeState);
  const focusType = useSelector((state) => state.control.focusType);

  useEffect(() => {
    if (focusType === ConstansType.ADD_STUDENT_FOCUS_STATUS_LIST) {
      if (focusState !== 1) {
        console.log("setFocusState(1)");
        setFocusState(1);
      }
    } else if (focusType === ConstansType.ADD_STUDENT_GOOD_FOCUS_STATUS_LIST) {
      if (focusState !== 2) {
        console.log("setFocusState(2)");
        setFocusState(2);
      }
    } else {
      if (focusState !== 0) {
        console.log("setFocusState(0)");
        setFocusState(0);
      }
    }
  }, [focusType, focusTimeState]);

  // 0: not focused, 1: focused, 2: well focused
  const [currentScreenState, setCurrentScreenState] = useState(1);
  const [insideCnt, setInsideCnt] = useState(0);
  const [totalCnt, setTotalCnt] = useState(0);
  const [focusCnt, setFocusCnt] = useState(0);

  useEffect(() => {
    if (totalCnt >= 10) {
      const insideRatio = insideCnt / totalCnt;

      if (insideRatio >= 0.5) {
        setFocusCnt(focusCnt + 1);
        if (currentScreenState === 0) {
          setCurrentScreenState(1);
          dispatch(purgeFocusType());
        }
      } else {
        setFocusCnt(0);
        if (currentScreenState !== 0) {
          setCurrentScreenState(0);
          dispatch(setFocusType(ConstansType.ADD_STUDENT_FOCUS_STATUS_LIST));
        }
      }

      setInsideCnt(0);
      setTotalCnt(0);
    } else {
      if (eyetrackingData?.screenState === "inside")
        setInsideCnt(insideCnt + 1);
      setTotalCnt(totalCnt + 1);
    }

    if (focusCnt >= 2) {
      if (currentScreenState !== 2) {
        setCurrentScreenState(2);
        dispatch(setFocusType(ConstansType.ADD_STUDENT_GOOD_FOCUS_STATUS_LIST));
      }
      setFocusCnt(0);
    }
  }, [eyetrackingData]);

  useEffect(() => {
    dispatch(purgeFocusType());
    setRandom(Math.floor(Math.random() * 10) + 1);
  }, [quizOrder]);

  useEffect(() => {
    const processEyetrackingData = (eyetrackingData) => {
      if (random <= 3 && quizset?.quizsetType === "ABTEST") {
        if (eyetrackingData?.screenState === "inside") {
          setAblabsEyeData([
            ...ablabsEyeData,
            {
              eyeState: "IN_SCREEN",
              createdAt: eyetrackingData.timeStamp,
            },
          ]);
        } else {
          setAblabsEyeData([
            ...ablabsEyeData,
            {
              eyeState: "OUT_SCREEN",
              createdAt: eyetrackingData.timeStamp,
            },
          ]);
        }
      }
    };

    const asyncPostEyeTracking = async () => {
      if ((ablabsEyeData.length > 10 && random <= 4) || quizEnded === true) {
        await postEyeTrackingData(user.clientId, quiz.quizId, ablabsEyeData);
        setAblabsEyeData([]);
      }
    };

    if (eyetrackingData) {
      asyncPostEyeTracking();
      processEyetrackingData(eyetrackingData);
    }
  }, [eyetrackingData?.screenState, quizEnded]);

  if (!quizsetSession || !quizset || !quiz || isLoading) {
    if (quizset?.quizzes?.length <= quizOrder) {
      return (
        <>
          <SpinnerContainer>
            <TopRightButton>
              <Button onClick={handleQuizExit}>퀴즈 나가기</Button>
            </TopRightButton>
            <TextContainer>
              <Spinner
                thickness="4px"
                speed="3s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
                marginBottom="20px"
              />
              <br />
              {user.userName}님의 퀴즈 풀이가 완료되었습니다. <br />
              다른 그룹의 퀴즈가 아직 진행중입니다. 잠시만 기다려주세요.
            </TextContainer>
          </SpinnerContainer>
        </>
      );
    }
    return <SpinnerContainer />;
  }

  return (
    <Box w="full" h="full" p={4} overflow="auto" zIndex="100">
      <Box
        borderRadius="lg"
        boxShadow={
          random <= 4
            ? focusState === 2
              ? "0px 5px 5px 5px rgba(0, 255, 0, 0.5)" // 초록
              : focusState === 1
              ? "0px 5px 5px 5px rgba(255, 165, 0, 0.5)" //주황
              : "0px 1px 3px 1px rgba(0, 0, 0, 0)"
            : "0px 1px 3px 1px rgba(0, 0, 0, 0)"
        }
        transition="box-shadow 0.3s ease-in-out"
      >
        <QuizEyetrackingLogger
          name="QuizSessionLogger"
          quizType={quiz.quizType}
          quizIndex={quizOrder + 1}
          onEyeTrackingData={setEyeTrackingData}
          quizSessionType={quizSessionType}
        >
          <QuizGPSLogger
            name="QuizSessionLogger"
            quizType={quiz.quizType}
            onGpsData={setGpsData}
            quizSessionType={quizSessionType}
          >
            <QuizAccelGyroLogger
              name="QuizSessionLogger"
              quizType={quiz.quizType}
              onAccelgyroData={setAceelgyroData}
              quizSessionType={quizSessionType}
            >
              <QuizTouchLoggerContainer
                name="QuizSessionLogger"
                myRef={containerRef}
                touchData={sessionTouchData}
                setTouchData={setSessionTouchData}
                quizType={quiz.quizType}
                deviceType={sessionDeviceType}
                quizSessionType={quizSessionType}
              >
                <QuizAudioLogger
                  name="QuizSessionLogger"
                  quizType={quiz.quizType}
                  isStartRecording={isStartRecording}
                  onVoiceData={setVoiceData}
                  clientId={user.clientId}
                  quizId={quiz.quizId}
                  quizSessionType={quizSessionType}
                >
                  <VStack
                    spacing={4}
                    transition={"box-shadow 0.3s ease-in-out"}
                  >
                    <Container maxW={"container.lg"}>
                      <Box
                        borderRadius={"lg"}
                        p={8}
                        overflow={"auto"}
                        maxHeight={"200px"}
                        display="flex"
                        width="full"
                        justifyContent={"space-between"}
                      >
                        <VStack spacing={2}>
                          <Text>
                            {quizOrder + 1} / {quizset.quizzes.length}
                          </Text>
                          {[1, 2, 3].includes(quiz.quizLevel) && (
                            <Text>
                              {/* 난이도: {['쉬움', '보통', '어려움'][quizLevel - 1]} */}
                              {`${t("quizpang.test.text.level")}`}:{" "}
                              {
                                [
                                  t("quizpang.test.text.level.easy"),
                                  t("quizpang.test.text.level.normal"),
                                  t("quizpang.test.text.level.hard"),
                                ][quiz.quizLevel - 1]
                              }
                            </Text>
                          )}
                        </VStack>
                        <Text>{quizset.title}</Text>
                        <Countdown
                          isSmall={true}
                          saveQuizTime={saveQuizTime}
                          startSecond={
                            storageQuizTime?.second ?? quiz.timelimit
                          }
                          onZero={onTimeEnd}
                          setTimer={setTimer}
                        />
                      </Box>
                    </Container>

                    <Container maxW={"container.lg"}>
                      {checkIfItOnlyContainsTags(quiz.title) && (
                        <Box
                          border="1px"
                          borderColor="gray.200"
                          borderRadius={"lg"}
                          p={2}
                          overflow={"auto"}
                          minHeight={[100, 100, 100, 100, 100, 100]}
                          maxHeight={[300, 300, 300, 300, 350, 400]}
                          width="100%"
                          display="flex"
                          justifyContent={"center"}
                        >
                          <ReactQuill
                            value={quiz.title}
                            readOnly
                            theme="bubble"
                            style={{
                              width: "100%",
                            }}
                          />
                        </Box>
                      )}
                    </Container>

                    <Container maxW={"container.lg"}>
                      <VStack spacing={4}>
                        {!["NotSet", ""].includes(quiz.link) && (
                          <>
                            <Box
                              border="1px"
                              borderColor="gray.200"
                              borderRadius={"lg"}
                              p={2}
                              overflow={"auto"}
                              width="100%"
                              display="flex"
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              <ImageContainer>
                                {quiz.linkType === "YOUTUBE" ? (
                                  <LiteYouTubeEmbed
                                    id={getYoutubeId(quiz.link)}
                                    title="video"
                                  />
                                ) : (
                                  <Image
                                    src={quiz.link}
                                    style={{
                                      width: 480,
                                      height: "auto",
                                      objectFit: "contain",
                                    }}
                                    alt="quizImage"
                                    lazy
                                  />
                                )}
                              </ImageContainer>
                            </Box>
                          </>
                        )}
                        <Box
                          border="1px"
                          borderColor="gray.200"
                          borderRadius={"lg"}
                          p={8}
                          width="100%"
                          padding="20px"
                        >
                          {quiz.quizType === "OX" && quiz.choices && (
                            <QuizOX
                              answerBoolean={
                                choiceId === null
                                  ? null
                                  : choiceId === quiz.choices[0].choiceId
                              }
                              setAnswerBoolean={(v) => {
                                setChoiceId(quiz.choices[v ? 0 : 1].choiceId);
                              }}
                              setClicked={setClicked}
                              clicked={clicked}
                            />
                          )}
                          {quiz.quizType === "STYLUS" && (
                            <QuizStylusWrite
                              quizsetSessionId={quizsetSessionId}
                              quizId={quiz.quizId}
                              quizTimelimit={quiz.timelimit}
                              stylusImage={stylusImage}
                              setStylusImage={setStylusImage}
                              containerRef={containerRef}
                              stylusData={sessionStylusData}
                              setStylusData={setSessionStylusData}
                              deviceType={sessionDeviceType}
                              quizEnded={quizEnded}
                            />
                          )}
                          {quiz.quizType === "MULTIPLE" &&
                            quiz.choices?.map((c, index) => {
                              return (
                                <QuizChoice
                                  key={index}
                                  index={index + 1}
                                  option={c.description}
                                  isSelected={choiceId === c.choiceId}
                                  onPress={() => setChoiceId(c.choiceId)}
                                  setClicked={setClicked}
                                  clicked={clicked}
                                />
                              );
                            })}
                          {quiz.quizType === "SHORT" && (
                            <QuizInput
                              setShortResult={textAnswer}
                              quizTimelimit={quiz.timelimit}
                              quizId={quiz.quizId}
                              keyboardData={sessionKeyboardData}
                              setKeyboardData={setSessionKeyboardData}
                              deviceType={sessionDeviceType}
                              input={input}
                              setInput={setInput}
                            />
                          )}
                          {quiz.quizType === "LONG" && (
                            <QuizLongInput
                              setLongResult={setTextAnswer}
                              quizTimelimit={quiz.timelimit}
                              quizId={quiz.quizId}
                              keyboardData={sessionKeyboardData}
                              setKeyboardData={setSessionKeyboardData}
                              deviceType={sessionDeviceType}
                              input={input}
                              setInput={setInput}
                              timer={timer}
                            />
                          )}
                        </Box>
                      </VStack>
                    </Container>

                    <ArchiveModule
                      clientId={user.clientId}
                      quizId={quiz.quizId}
                      quizEnded={quizEnded}
                      quizTimelimit={quiz.timelimit}
                      gpsData={gpsData}
                      accelgyroData={accelgyroData}
                      touchData={sessionTouchData}
                      keyboardData={sessionKeyboardData}
                      stylusData={sessionStylusData}
                      voiceData={voiceData}
                      eyetrackingData={eyetrackingData}
                    />
                    {quizEnded && <Loading />}
                  </VStack>
                </QuizAudioLogger>
              </QuizTouchLoggerContainer>
            </QuizAccelGyroLogger>
          </QuizGPSLogger>
        </QuizEyetrackingLogger>
      </Box>
    </Box>
  );
};

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  border-radius: 8px;
  width: 480px;
  margin: 16px 0;
  align-items: center;
  justify-content: center;
  align-self: center;

  position: relative;
  overflow: hidden;
`;

const TopRightButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const TextContainer = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;
