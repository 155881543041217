import { useEffect, useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 550px;
  height: 280px;
  border-radius: 10px;
  border: 1px solid #E4E4E4;
  padding: 15px 10px;
  background: var(--White, #FFF);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.25);
  display: flex;
  position: relative;
  z-index: 1;
`
const YearContainer = styled.div`
  border-right: 1px solid #E4E4E4;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
`
const MonthContainer = styled.div`
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
`
const DateContainerTitle = styled.div`
  color: #212120;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.25px;
`
const YearList = styled.div`
  min-height: 0;
  flex: 1;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  overflow-y: auto;
`
const YearItem = styled.button`
  width: 80px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #E4E4E4;
  background: ${props => props.selected ? '#212120' : 'var(--White, #FFF)' };
  color: ${props => props.selected ? 'var(--White, #FFF)' : '#212120' };
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0.25px;
`
const MonthList = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
`
const MonthItem = styled.button`
  width: 80px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #E4E4E4;
  background: ${props => props.selected ? '#212120' : 'var(--White, #FFF)' };
  color: ${props => props.selected ? 'var(--White, #FFF)' : '#212120' };
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0.25px;
`
const CloseContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 280px;
  bottom: 0;
  z-index: 1;
`

const ChooseDate = ({ year, month, minYear, maxYear, minMonth, maxMonth, changeYear, changeMonth, close }) => {
  const [yearList] = useState((() => {
    const yearList = []
    const count = 20
    for (let i = 0; i < count; i++) {
      yearList.push(new Date().getFullYear() - i)
    }
    return yearList
  })())
  const [monthList] = useState([1,2,3,4,5,6,7,8,9,10,11,12])
  const [selectedYear, setSelectedYear] = useState(year)
  const [selectedMonth, setSelectedMonth] = useState(month)

  return (
    <>
      <CloseContainer onClick={close}></CloseContainer>
      <Container>
        <YearContainer>
          <DateContainerTitle>연도 선택</DateContainerTitle>
          <YearList>
            {
              yearList.map((year, index) => (
                <YearItem key={index}
                          selected={selectedYear === year}
                          onClick={() => {
                            if (minYear && year < minYear) return
                            else if (maxYear && year > maxYear) return
                            else if (maxYear && minMonth && year === minYear && selectedMonth < minMonth) return

                            setSelectedYear(year)
                            changeYear(year)
                          }}
                >{year}</YearItem>
              ))
            }
          </YearList>
        </YearContainer>
        <MonthContainer>
          <DateContainerTitle>월 선택</DateContainerTitle>
          <MonthList>
            {
              monthList.map((month, index) => (
                <MonthItem key={index}
                          selected={selectedMonth === month}
                          onClick={() => {
                            if (minMonth && selectedYear === minYear && month < minMonth) return
                            else if (maxMonth && selectedYear === maxYear && month > maxMonth) return

                            setSelectedMonth(month)
                            changeMonth(month)
                          }}
                >{month}월</MonthItem>
              ))
            }
          </MonthList>
        </MonthContainer>
      </Container>
    </>
  )
}

export default ChooseDate