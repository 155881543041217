import styled from 'styled-components';

const Table = styled.table`
  margin-top: 30px;
  text-align: center;
`
const ColGroup = styled.colgroup``
const Col = styled.col``
const THead = styled.thead``
const TBody = styled.tbody``
const Tr = styled.tr``
const Th = styled.th`
  height: 60px;
  background: #F2F2F2;
  color: ${props => props.color || '#212120'};
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.25px;
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`
const Td = styled.td`
  height: 60px;
  color: ${props => props.color || '#212120'};
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.25px;
`

const AttendanceList = ({ fromDate, toDate, selectDate }) => {
  const diffDays = Math.floor((toDate - fromDate) / (1000 * 60 * 60 * 24) + 1)

  return (
    <Table>
      <ColGroup>
        <Col width="1%" />
        <Col width="1%" />
        <Col width="1%" />
        <Col width="1%" />
      </ColGroup>
      <THead>
        <Tr>
          <Th>날짜</Th>
          <Th>출석 체크 시간</Th>
          <Th>출석 상태</Th>
          <Th>상세 내용</Th>
        </Tr>
      </THead>
      <TBody>
        {
          Array(diffDays).fill(0).map((_, index) => {
            const date = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate() - index)
            const day = date.getDay()
            const dayString = ['일', '월', '화', '수', '목', '금', '토'][day]
            const dateString = (date.getMonth() + 1) + '월 ' + date.getDate() + '일 (' + dayString + ')'

            return (
              <Tr key={index} style={{ cursor: 'pointer' }} onClick={selectDate}>
                <Td>{dateString}</Td>
                <Td>08:30</Td>
                <Td>출석</Td>
                <Td>병원 방문</Td>
              </Tr>
            )
          })
        }
      </TBody>
    </Table>
  )
}

export default AttendanceList