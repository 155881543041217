import { HStack, Select } from "@chakra-ui/react";
import React, { useState } from "react";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "react-i18next";

const MultiLanguageSelectMenu = ({ width }) => {
  const { i18n } = useTranslation();
  const [langState, setLangState] = useState("ko");

  const handleChange = (event) => {
    const lang = event.target.value;
    setLangState(lang);
    console.log("lang", lang);
    i18n.changeLanguage(lang);
    // localStorage.setItem("i18nextLng", lang);
  };

  return (
    <HStack spacing={1} width={width ? width : "70%"} pt={7}>
      {/* <Tooltip label="언어 설정" aria-label="언어 설정"> */}
      <LanguageIcon backgroundColor="gray.200" />
      {/* </Tooltip> */}
      <Select size="sm" defaultValue={i18n.language} onChange={handleChange}>
        <option value="ko" style={{ display: "block" }}>
          한국어
        </option>
        <option value="en">English</option>
      </Select>
    </HStack>
  );
};

export default MultiLanguageSelectMenu;
