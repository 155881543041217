import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import SidebarClassRoom from "@components/Layout/SidebarClassRoom";
import { Divider, Typography } from "@mui/material";
import QuizRecord from "./quiz-record";
import { useParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getBackend, getQuizsetSessionHistories } from "@api";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BottomContentLayer } from "@app/pages/QuizPang/common";
import { QuizsetSurveyCard } from "./quizset-card";
import QueryString from "qs";
import { useQuizsetSurvey } from "./hooks/useSurvey";
import SurveyCardWrapper from "@pages/FocusSurvey/SurveyCardWrapper";
import SurveyMainWrapper from "./survey-main-wrapper";
import {
  Button,
  Container,
  Grid,
  GridItem,
  HStack,
  Heading,
  IconButton,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { setReportLog } from "@store/actions";
import { ButtonConstants } from "constants/buttonConstants";
import { useTranslation } from "react-i18next";

const QuizPang = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView();
  const { groupId } = useParams();
  const { tabKey } = QueryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const user = useSelector((state) => state.user);

  //   const historyList = useSelector((state) => state.quizsetSession.histories);
  const [historyList, setHistoryList] = useState([]);
  const participated = historyList.filter((x) => {
    return x.leaderboard.some((y) => y.clientId === user.clientId);
  });
  const [isLoading, setIsLoading] = useState(true);
  const [index, setIndex] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const dispatch = useDispatch();

  const handleLoadMore = () => {
    setIsLoadingMore(true);
    setIndex(index + 1);
  };

  const sessionHistory = useCallback(async () => {
    try {
      const res = await getQuizsetSessionHistories({
        groupId: groupId,
        index: index,
      });
      setHistoryList((prev) => prev.concat(res.data.quizsetSessionHistories));
      setIsLoadingMore(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoadingMore(false);
      setIsLoading(false);
    }
  }, [groupId, index]);

  useEffect(() => {
    sessionHistory();
  }, [groupId, index]);

  // 클릭 시 퀴즈팡 개발자 모드로 전환
  const history = useHistory();

  const [selected, setSelected] = useState(tabKey || "quiz");
  const [activeButton2, setActiveButton2] = useState(0);
  const [quizsetSurveys, setQuizsetSurveys] = useState([]);

  return (
    <>
      <Grid gridTemplateColumns={"280px 1fr"} templateAreas={`"sidebar main"`}>
        <GridItem area={"sidebar"}>
          <SidebarClassRoom inClass />
        </GridItem>
        <GridItem area={"main"} p={16}>
          <Stack spacing={4}>
            <HStack spacing={4}>
              <Heading as="h1" size="lg">
                {/* 퀴즈팡 */}
                {t("quizpang.text.title")}
              </Heading>
              {/* <IconButton
                aria-label="quizpang"
                icon={<RepeatIcon />}
                onClick={() => {
                  refetch();
                }}
              /> */}
            </HStack>
            <Selector>
              <Select
                onClick={() => {
                  setSelected("quiz");
                  dispatch(
                    setReportLog(ButtonConstants.QUIZPANG.QUIZ_BATTLE_LOG_TAP)
                  );
                }}
                style={
                  selected === "quiz"
                    ? {
                        color: "#13305A",
                        borderBottom: "#13305A 3px solid",
                      }
                    : { borderBottom: "transparent 3px solid" }
                }
              >
                {/* 퀴즈 배틀 기록 */}
                {t("quizpang.nav.text.quizbattle")}
              </Select>
              <Select
                onClick={() => {
                  setSelected("survey");
                  dispatch(
                    setReportLog(ButtonConstants.QUIZPANG.QUIZ_BATTLE_LOG_TAP)
                  );
                }}
                style={
                  selected === "survey"
                    ? {
                        color: "#13305A",
                        borderBottom: "#13305A 3px solid",
                      }
                    : { borderBottom: "transparent 3px solid" }
                }
              >
                {/* 설문지 */}
                {t("quizpang.nav.text.survey")}
              </Select>
            </Selector>
            {selected === "quiz" ? (
              <>
                <Divider />
                {/* <SubTitle>나의 퀴즈 기록</SubTitle> */}
                <SubTitle>{t("quizpang.text.myquizlist")}</SubTitle>
                <Header>
                  <Column1>
                    {/* <HeaderText>참여날짜</HeaderText> */}
                    <HeaderText>{t("quizpang.table.head.date")}</HeaderText>
                  </Column1>
                  <Column2>
                    {/* <HeaderText>퀴즈 제목</HeaderText> */}
                    <HeaderText>
                      {t("quizpang.table.head.quiztitle")}
                    </HeaderText>
                  </Column2>
                  <Column3>
                    {/* <HeaderText>순위</HeaderText> */}
                    <HeaderText>{t("quizpang.table.head.ranking")}</HeaderText>
                  </Column3>
                </Header>
                {isLoading ? (
                  <VStack spacing={2}>
                    <Spinner size={"xl"} />
                    <Heading>{t("quizpang.heading.morelist")}</Heading>
                  </VStack>
                ) : participated.length > 0 ? (
                  participated.map((x, i) => <QuizRecord key={i} data={x} />)
                ) : (
                  <VStack spacing={2}>
                    {/* <Heading>참여한 퀴즈팡 기록이 없어요...</Heading> */}
                    <Heading>{t("quizpang.heading.nolist")}</Heading>
                  </VStack>
                )}
                {isLoadingMore ? (
                  <VStack spacing={2}>
                    <Spinner size={"xl"} />
                    {/* <Heading>퀴즈팡 기록을 더 불러오고 있어요...</Heading> */}
                    <Heading>{t("quizpang.heading.morelist")}</Heading>
                  </VStack>
                ) : (
                  historyList.length > 4 && (
                    <VStack>
                      <Button
                        onClick={() => {
                          handleLoadMore();
                          dispatch(
                            setReportLog(ButtonConstants.QUIZPANG.LOAD_MORE)
                          );
                        }}
                        colorScheme={"teal"}
                        size={"lg"}
                      >
                        {/* 더보기 */}
                        {t("quizpang.table.more")}
                      </Button>
                    </VStack>
                  )
                )}
              </>
            ) : (
              <BottomContentLayer>
                <SurveyMainWrapper />
              </BottomContentLayer>
            )}
          </Stack>
        </GridItem>
      </Grid>
    </>
  );
};

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 16px;
`;

const Header = styled.div`
  display: flex;

  background-color: #424242;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 10px 16px;
  flex-direction: row;
`;

const Column1 = styled.div`
  width: 20%;
`;

const Column2 = styled.div`
  width: 30%;
`;

const Column3 = styled.div`
  width: 50%;
`;

const HeaderText = styled.div`
  color: #fafafa;
`;

const Caption = styled.div`
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #00000099;
`;

const RefetchButton = styled.button`
  width: 20%;
  height: 54px;
  background-color: #0ba1ae;
  padding: auto;
  border-radius: 8px;
  color: #fff;
  margin: 0 auto;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledGrid = styled.div`
  display: grid;
  /*grid-template-columns: repeat(1, minmax(4, 1fr));*/
  grid-gap: 10px;
  /*grid-auto-flow: row;*/
  @media (min-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1500px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 1800px) {
    grid-template-columns: repeat(5, 1fr);
  }
  /*@media (min-width: 2000px) {
    grid-template-columns: repeat(6, 1fr);
  }*/
`;
const SortButton = styled.button`
  font-family: "Noto Sans KR", "Roboto", sans-serif;
  font-size: 12px;
`;
const Selector = styled.div`
  display: flex;
  gap: 15px;
`;
const Select = styled.div`
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
`;
export default QuizPang;
