import {
  CREATE_QUIZSET_SESSION_BY_QUIZ_FAIL,
  CREATE_QUIZSET_SESSION_BY_QUIZ_SUCCESS,
  CREATE_QUIZSET_SESSION_FAIL,
  CREATE_QUIZSET_SESSION_SUCCESS,
  GET_QUIZSET_SESSION_SUCCESS,
  GET_QUIZSET_SESSION_FAIL,
  GET_QUIZSET_SESSION_HISTORY_FAIL,
  GET_QUIZSET_SESSION_HISTORY_SUCCESS,
  GET_QUIZSET_SESSION_RESULT_FAIL,
  GET_QUIZSET_SESSION_RESULT_SUCCESS,
  PURGE_QUIZSET_SESSION,
  SET_QUIZPANG_DEV_MODE_SUCCESS,
  SET_QUIZPANG_DEV_MODE_FAIL,
  EXIT_QUIZPANG_DEV_MODE_SUCCESS,
  EXIT_QUIZPANG_DEV_MODE_FAIL,
  SET_QUIZSET_SESSION_SUCCESS,
  SET_QUIZSET_SESSION_FAIL,
  CLEAR_QUIZPANG_SESSION_SUCCESS,
  CLEAR_QUIZPANG_SESSION_FAIL,
  GRANT_PERMISSION,
} from './actionTypes';

const initialState = {
  histories: [],
  sessionsById: {},
  resultsById: {},
  currentQuizsetSession: null,
  isDevMode: false,
  camera: false,
  microphone: false,
  location: false,
  devicemotion: false,
};

const quizsetSession = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_QUIZSET_SESSION:
      return initialState;

    case GET_QUIZSET_SESSION_HISTORY_SUCCESS:
      return {
        ...state,
        histories: action.data,
      };

    case GET_QUIZSET_SESSION_SUCCESS:
      return {
        ...state,
        currentQuizsetSession: action.data,
      };

    case CREATE_QUIZSET_SESSION_SUCCESS:
    case CREATE_QUIZSET_SESSION_BY_QUIZ_SUCCESS:
      return {
        ...state,
        sessionsById: {
          ...state.sessionsById,
          [action.data.quizsetSession.quizsetSessionId]: action.data,
        },
      };

    case GET_QUIZSET_SESSION_RESULT_SUCCESS:
      return {
        ...state,
        resultsById: {
          ...state.resultsById,
          [action.data.quizsetSessionId]: action.data.result,
        },
      };

    case GET_QUIZSET_SESSION_FAIL:
    case GET_QUIZSET_SESSION_HISTORY_FAIL:
    case CREATE_QUIZSET_SESSION_FAIL:
    case CREATE_QUIZSET_SESSION_BY_QUIZ_FAIL:
    case GET_QUIZSET_SESSION_RESULT_FAIL:
      // console.error('[QuizsetSession Reducer] ', action.payload);
      return state;

    case SET_QUIZSET_SESSION_SUCCESS:
      return {
        ...state,
        sessionsById: action.data,
      };
    case SET_QUIZSET_SESSION_FAIL:
      // console.error('[QuizsetSession Reducer] ', action.payload);
      return state;

    case CLEAR_QUIZPANG_SESSION_SUCCESS:
      return {
        ...state,
        sessionsById: {},
      };
    case CLEAR_QUIZPANG_SESSION_FAIL:
      // console.error('[QuizsetSession Reducer] ', action.payload);
      return state;

    case SET_QUIZPANG_DEV_MODE_SUCCESS:
      return {
        ...state,
        isDevMode: true,
      };
    case SET_QUIZPANG_DEV_MODE_FAIL:
      // console.error('[QuizsetSession Reducer] ', action.payload);
      return state;
    case EXIT_QUIZPANG_DEV_MODE_SUCCESS:
      return {
        ...state,
        isDevMode: false,
      };
    case EXIT_QUIZPANG_DEV_MODE_FAIL:
      // console.error('[QuizsetSession Reducer] ', action.payload);
      return state;

    case GRANT_PERMISSION:
      return {
        ...state,
        [action.payload.permission]: action.payload.granted,
      };
    default:
      break;
  }
  return state;
};

export default quizsetSession;
