import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveClassroom,
  selectActiveManaging,
  selectAllManaging,
  selectClassroomGroup,
  selectGroupTimetables,
} from "@store/selectors";
import {
  getActiveClassroomPromise,
  getGroupProgramconfigsPromise,
  getParticipationPromise,
  updateParticipationPromise,
  setSocketData,
  updateManagingPromise,
  getActiveManagingPromise,
  createManagingPromise,
  setSelectedClass,
  setSelectedPage,
  createParticipationPromise,
  getCommentsLayerPromise,
} from "@store/actions";
import { useHistory, useLocation } from "react-router-dom";
import { useInterval } from "./utils";
import moment from "moment";
import { useTimetableByClientId } from "@pages/Classroom/hooks/useTimetable";

const ClassManager = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const activeClassroom = useSelector((state) => state.classrooms.classSession);
  const activeClassroomGroup = useSelector((state) =>
    selectClassroomGroup(state, activeClassroom)
  );
  const getTimetableData = useTimetableByClientId(user.clientId);
  const activeManaging = useSelector((state) => selectActiveManaging(state));
  const managingSchedule = useSelector((state) => selectAllManaging(state));
  const userClientId = useSelector((state) => state.user?.clientId);
  const userSignedIn = useSelector((state) => state.user?.signedIn);
  const activeClassroomState = useSelector(
    (state) => state.classrooms.classSession?.state
  );

  useEffect(() => {
    if (!userSignedIn || !userClientId) {
      return;
    }
    console.log(
      "[ClassManager]: 현재 진행중인 수업과 관리중인 스케줄을 조회합니다..."
    );
    dispatch(getActiveClassroomPromise(userClientId));
    dispatch(getActiveManagingPromise(userClientId));
  }, [userClientId, userSignedIn]);

  useEffect(() => {
    if (!activeClassroom?.classroomId) {
      return;
    }
    dispatch(getCommentsLayerPromise(activeClassroom.classroomId));
  }, [activeClassroom?.classroomId, dispatch]);

  useEffect(() => {
    if (!user.signedIn) {
      return;
    }
    if (!activeClassroom) {
      console.log("[ClassManager]", "진행 중인 수업이 존재하지 않습니다.");
      history.push(`/home`);
    }
    if (activeClassroom) {
      dispatch(
        createParticipationPromise({
          classroomId: activeClassroom?.classroomId,
          clientId: user?.clientId,
          attendedAt: moment().toISOString(),
        })
      ).then(() => {
        dispatch(
          getParticipationPromise({
            classroomId: activeClassroom?.classroomId,
            clientId: user?.clientId,
          })
        ).then((participation) => {
          if (participation?.state === "ABSENT") {
            console.log("[Class Manager]", "출석이 필요합니다.");
            dispatch(
              updateParticipationPromise({
                classroomId: activeClassroom?.classroomId,
                clientId: user.clientId,
                state: "ATTEND",
                attendedAt: moment().toISOString(),
              })
            ).then(() => {
              dispatch(
                setSocketData({
                  method: "POST",
                  uri: "/classroom/sendImage",
                  groupId: activeClassroom.groupId,
                  clientId: user.clientId,
                  type: "JOIN_GROUP",
                  data: "",
                })
              );
              dispatch(
                setSocketData({
                  method: "POST",
                  uri: "/classroom/sendImage",
                  groupId: activeClassroom.groupId,
                  clientId: user.clientId,
                  type: "ATTEND_CLASS",
                  data: "",
                })
              );
            });
          }
        });
        dispatch(
          setSocketData({
            method: "POST",
            uri: "/classroom/sendImage",
            groupId: activeClassroom.groupId,
            clientId: user.clientId,
            type: "ATTEND_CLASS",
            data: "",
          })
        );
      });
      dispatch(setSelectedClass(activeClassroom.groupId));
    }
    dispatch(setSelectedPage("ACTIVE_CLASSROOM"));
  }, [activeClassroom]);

  useEffect(() => {
    if (!activeClassroom || !activeClassroomGroup) return;

    if (!getTimetableData.timetable) {
      return;
    }

    console.log("[ClassManager]", "activeClassroom:", activeClassroom);

    const validTimetables = getTimetableData.timetable.data
      .flatMap((dataItem) => dataItem?.timetables || [])
      .filter((timetable) => timetable != null);

    const group = activeClassroomGroup;
    const timetable = validTimetables.find(
      (timetable) => timetable.groupId === group.groupId
    );
    const state = { group, timetable };

    // history.push({
    //   pathname: `/classroom/${activeClassroomGroup.groupId}`,
    //   state,
    // });

    // switch (activeClassroomGroup.groupType) {
    //   case 'SUBJECT':
    //     console.log('[ClassManager]', 'navigating to ClassInformation');
    //     history.push({
    //       pathname: `/classroom/${activeClassroomGroup.groupId}`,
    //       state,
    //     });
    //     break;
    //   default:
    //     console.log('[ClassManager] 알 수 없는 수업입니다.');
    //     break;
    // }
  }, [activeClassroom]);

  useEffect(() => {
    const isQuizPangPage = location.pathname.includes("quizpang");
    if (!activeClassroomState && !isQuizPangPage) {
      console.log("[ClassManager]: 수업이 진행중이지 않습니다.");
    }
    if (location.pathname.includes("survey")) {
      return;
    }

    // if (activeClassroom && activeManaging && !isQuizPangPage) {
    //   history.push(`/classroom/${activeClassroom.groupId}`);
    // } else if (activeClassroom && !activeManaging && !isQuizPangPage) {
    //   history.push(`/classroom/${activeClassroom.groupId}`);
    // } else if (!activeClassroom && activeManaging && !isQuizPangPage) {
    //   history.push(`/managing/${activeManaging.groupId}`);
    // } else if (!activeClassroom && !activeManaging && !isQuizPangPage) {
    //   history.push(`/home`);
    // }

    const isClassroomRelatedPage =
      location.pathname.includes("notice") ||
      location.pathname.includes("assignment") ||
      location.pathname.includes("submission") ||
      location.pathname.includes("focussurvey") ||
      location.pathname.includes("survey") ||
      location.pathname.includes("inquizpang") ||
      location.pathname.includes("screenlock") ||
      location.pathname.includes("classroom");

    if (!isClassroomRelatedPage) {
      if (activeClassroom && !activeManaging && !isQuizPangPage) {
        history.push(`/classroom/${activeClassroom.groupId}`);
      } else if (!activeClassroom && activeManaging && !isQuizPangPage) {
        history.push(`/managing/${activeManaging.groupId}`);
      } else if (!activeClassroom && !activeManaging && !isQuizPangPage) {
        return;
      }
      // 퀴즈페이지나 수업 관련 페이지에 있으면 현재 페이지에 머물도록 다른 라우팅은 하지 않음
    }
  }, [activeClassroomState, activeManaging, location.pathname]);

  useEffect(() => {
    managingSchedule?.map((managing) => {
      dispatch(getGroupProgramconfigsPromise(managing.managingId));
    });
  }, [activeManaging]);

  //trigger managing Start
  useInterval(() => {
    const managingForUpdate = managingSchedule
      .filter((schedule) => schedule.state === "FINISHED")
      .filter(
        (schedule) =>
          moment(schedule.startDate) - moment() < 0 &&
          moment(schedule.endDate) - moment() > 0
      );
    if (!managingForUpdate || managingForUpdate.length === 0) {
      return;
    }
    managingForUpdate.forEach((managing) => {
      const updateData = {
        managingId: managing.managingId,
        title: managing.title,
        rRule: managing.rRule,
        startDate: managing.startDate,
        endDate: managing.endDate,
        state: "IN_MANAGING",
      };
      dispatch(updateManagingPromise(updateData))
        .then((res) => {
          dispatch(
            setSocketData({
              method: "POST",
              uri: "/classroom/sendImage",
              groupId: managing.groupId,
              clientId: user?.clientId,
              type: "MANAGING_START",
              data: managing.managingId,
            })
          );
        })
        .then(() => dispatch(getActiveManagingPromise(user?.clientId)));
    });
  }, 3000);

  //trigger managing End
  useInterval(() => {
    const managingForUpdate = managingSchedule
      .filter((schedule) => schedule.state === "IN_MANAGING")
      .filter(
        (schedule) =>
          (moment(schedule.endDate) - moment() < 0 &&
            moment(schedule.endDate) - moment() > -3000) ||
          moment(schedule.startDate) - moment() > 0
      );

    if (!managingForUpdate || managingForUpdate.length === 0) {
      return;
    }
    managingForUpdate.forEach((managing) => {
      let newStartDate = managing.startDate;
      let newEndDate = managing.endDate;

      if (managing.rRule === "FREQ=DAILY") {
        newStartDate = moment(managing.startDate)
          .add(24, "hours")
          .toISOString();
        newEndDate = moment(managing.endDate)
          .add(24, "hours")
          .toISOString();
      }
      if (managing.rRule === "FREQ=WEEKLY") {
        newStartDate = moment(managing.startDate)
          .add(7, "days")
          .toISOString();
        newEndDate = moment(managing.endDate)
          .add(7, "days")
          .toISOString();
      }
      const updateData = {
        managingId: managing.managingId,
        title: managing.title,
        rRule: managing.rRule,
        startDate: newStartDate,
        endDate: newEndDate,
        state: "FINISHED",
      };
      const fillData = {
        title: managing.title,
        rRule: "rRule",
        startDate: managing.startDate,
        endDate: managing.endDate,
        groupId: managing.groupId,
      };
      dispatch(updateManagingPromise(updateData))
        .then(() => {
          dispatch(getActiveManagingPromise(user.clientId));
          if (managing.rRule !== "rRule") {
            dispatch(createManagingPromise(fillData));
          }
        })
        .then(() => {
          dispatch(
            setSocketData({
              method: "POST",
              uri: "/classroom/sendImage",
              groupId: managing.groupId,
              clientId: user?.clientId,
              type: "MANAGING_END",
              data: "",
            })
          );
        });
    });
  }, 3000);

  useInterval(() => {
    const managingForUpdateTwo = managingSchedule
      .filter((schedule) => schedule.state === "IN_MANAGING")
      .filter(
        (schedule) =>
          moment(schedule.startDate) - moment() < 0 &&
          moment(schedule.endDate) - moment() < 0
      );

    if (!managingForUpdateTwo || managingForUpdateTwo.length === 0) {
      return;
    }
    managingForUpdateTwo.forEach((managing) => {
      let newStartDate = managing.startDate;
      let newEndDate = managing.endDate;

      if (managing.rRule === "FREQ=DAILY") {
        newStartDate = moment(managing.startDate)
          .add(24, "hours")
          .toISOString();
        newEndDate = moment(managing.endDate)
          .add(24, "hours")
          .toISOString();
      }
      if (managing.rRule === "FREQ=WEEKLY") {
        newStartDate = moment(managing.startDate)
          .add(7, "days")
          .toISOString();
        newEndDate = moment(managing.endDate)
          .add(7, "days")
          .toISOString();
      }
      const updateData = {
        managingId: managing.managingId,
        title: managing.title,
        rRule: managing.rRule,
        startDate: newStartDate,
        endDate: newEndDate,
        state: "FINISHED",
      };
      const fillData = {
        title: managing.title,
        rRule: "rRule",
        startDate: managing.startDate,
        endDate: managing.endDate,
        groupId: managing.groupId,
      };
      dispatch(updateManagingPromise(updateData))
        .then(() => {
          dispatch(getActiveManagingPromise(user.clientId));
          // dispatch(createManagingPromise(fillData));
        })
        .then(() => {
          dispatch(
            setSocketData({
              method: "POST",
              uri: "/classroom/sendImage",
              groupId: managing.groupId,
              clientId: user?.clientId,
              type: "MANAGING_UPDATE",
              data: "",
            })
          );
        });
    });
  }, 3000);

  return <div />;
};

export default ClassManager;
