import CollapseCard from "@components/Card/CollapseCard";
import FileLink from "@components/Layout/FileLink";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const FileLinkList = ({ filelinks }) => {
  const { t } = useTranslation();
  return (
    <CollapseCard
      // title="받은 링크 목록"
      title={t("selfcare.text.link")}
      initialOpen={true}
    >
      {filelinks && <FileLink filelinks={filelinks} />}
    </CollapseCard>
  );
};

export default memo(FileLinkList, (prev, next) => {
  return JSON.stringify(prev.filelinks) === JSON.stringify(next.filelinks);
});
