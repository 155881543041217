import { authProtectedRoutes } from '@routes';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { store } from '@store';
// import { rrfProps } from "@store";
import { Switch, useHistory } from 'react-router-dom';
import AppRoute from '@routes/route';
import { PersistGate } from 'redux-persist/integration/react';
import { createTheme, THEME_ID, ThemeProvider } from '@mui/material/styles';
import MasterManager from './managers';
import ReactGA from 'react-ga';
import './common.scss';
import '@tldraw/tldraw/tldraw.css';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';


const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#0BA1AE',
    },
  },
});

const theme = extendTheme();

const App = (props) => {
  const persistor = persistStore(store, {});
  const history = useHistory();

  useEffect(() => {
    ReactGA.initialize('G-27BKQ9XN89');
    history.listen((location) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });
  }, []);

  return (
    <Provider store={store}>
      <ChakraProvider theme={{ ...theme, [THEME_ID]: muiTheme }} resetCSS>
        <PersistGate persistor={persistor}>
          <Switch>
            {authProtectedRoutes.map((route, idx) => {
              return (
                <AppRoute
                  path={route.path}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              );
            })}
          </Switch>
          <MasterManager />
        </PersistGate>
      </ChakraProvider>
    </Provider>
  );
};

export default App;
