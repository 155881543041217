import { all, fork } from "redux-saga/effects";

import userSaga from "./user/saga";
import schoolSaga from "./school/saga";
import accountSaga from "./accounts/saga";
import clientSaga from "./clients/saga";
import groupSaga from "./groups/saga";
import notificationSaga from "./notification/saga";
import programSaga from "./programs/saga";
import programconfigSaga from "./programconfigs/saga";
import classroomSaga from "./classrooms/saga";
import managingSaga from "./managing/saga";
import timetableSaga from "./timetable/saga";
import filelinkSaga from "./filelinks/saga";
import kakaocertSaga from "./kakaocert/saga";
import deviceSaga from "./device/saga";
import sessionSaga from "./session/saga";
import outlineSaga from "./outline/saga";
import questionSaga from "./question/saga";
import subquestionSaga from "./subquestion/saga";
import answerSaga from "./answer/saga";
import membershipSaga from "./membership/saga";
import managedSaga from "./managed/saga";
import participationSaga from "./participation/saga";
import groupActivitySaga from "./groupActivity/saga";
import reportSaga from "./report/saga";
import reportEventsSaga from "./reportEvents/saga";
import commentsLayerSaga from "./commentsLayer/saga";
import quizSaga from "./quiz/saga";
import quizChoiceSaga from "./quizChoice/saga";
import quizenrollmentSaga from "./quizenrollment/saga";
import quizresponseSaga from "./quizresponse/saga";
import quizsetSaga from "./quizset/saga";
import quizsetSessionSaga from "./quizsetSession/saga";
import dataArchiveSaga from "./dataArchive/saga";
import reportLogSaga from "./reportLog/saga";

export default function* rootSaga() {
  yield all([
    fork(userSaga),
    fork(clientSaga),
    fork(membershipSaga),
    fork(groupSaga),
    fork(notificationSaga),
    fork(programSaga),
    fork(programconfigSaga),
    fork(classroomSaga),
    fork(schoolSaga),
    fork(timetableSaga),
    fork(filelinkSaga),
    fork(kakaocertSaga),
    fork(accountSaga),
    fork(deviceSaga),
    fork(sessionSaga),
    fork(outlineSaga),
    fork(questionSaga),
    fork(subquestionSaga),
    fork(answerSaga),
    fork(managingSaga),
    fork(managedSaga),
    fork(participationSaga),
    fork(groupActivitySaga),
    fork(reportSaga),
    fork(reportEventsSaga),
    fork(commentsLayerSaga),
    fork(quizSaga),
    fork(quizChoiceSaga),
    fork(quizenrollmentSaga),
    fork(quizresponseSaga),
    fork(quizsetSaga),
    fork(quizsetSessionSaga),
    fork(dataArchiveSaga),
    fork(reportLogSaga),
  ]);
}
