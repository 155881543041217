import { call, put, takeLeading } from 'redux-saga/effects';
import {
  PURGE_ALL,
  SIGN_OUT,
  RESTORE_USER,
  GET_MY_CLIENT_INFO,
} from './actionTypes';
import {
  teacherSignUpPromise,
  signInStudentPromise,
  signInStudentSuccess,
  signInStudentFail,
  signOut,
  changePasswordPromise,
  changePasswordError,
  purgeClassroom,
  purgeClient,
  purgeGroup,
  purgeNotification,
  purgeProgram,
  purgeUser,
  purgeProgramconfig,
  purgeFilelink,
  purgeDevice,
  purgeSchool,
  purgeSession,
  purgeOutline,
  purgeQuestion,
  purgeSubquestion,
  purgeChoice,
  purgeAnswer,
  purgeMembership,
  purgeControl,
  purgeTimetable,
  purgeClientTemp,
  purgeGroupTemp,
  purgeKakaocert,
  registerStudentSuccess,
  registerStudentFail,
  registerStudentPromise,
  registerNoty,
  getUserPromise,
  getUserSuccess,
  getUserFail,
} from '@store/actions';
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';

function* purgeAllHandler() {
  try {
    console.log('Purging all redux...');
    yield put(signOut());
    yield put(purgeUser());
    yield put(purgeClient());
    yield put(purgeNotification());
    yield put(purgeProgram());
    yield put(purgeClassroom());
    yield put(purgeGroup());
    yield put(purgeProgramconfig());
    yield put(purgeFilelink());
    yield put(purgeControl());
    yield put(purgeTimetable());
    yield put(purgeClientTemp());
    yield put(purgeGroupTemp());
    yield put(purgeKakaocert());
    yield put(purgeSchool());
    yield put(purgeDevice());
    yield put(purgeSession());
    yield put(purgeOutline());
    yield put(purgeQuestion());
    yield put(purgeSubquestion());
    yield put(purgeChoice());
    yield put(purgeAnswer());
    yield put(purgeMembership());
  } catch (error) {
    console.error(error);
  }
}

function* getUserPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getUser, action.payload);
      const userData = api.unpackUser(
        response.data.account,
        response.data.client
      );
      yield put(getUserSuccess(userData));
      return yield userData;
    } catch (error) {
      yield put(getUserFail(error));
    }
  });
}

function* registerStudentPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const newClient = action.payload;
      const response = yield call(api.createStudentAdmin, newClient);
      const userData = api.unpackUser(
        response.data.account,
        response.data.client
      );
      // console.log('user saga', response.data)
      // yield call(api.storeStorageData, "@user", userData);
      yield call(api.setToken, response.data.jwt.token);
      yield put(registerNoty(true));
      yield put(registerStudentSuccess(userData));
      return yield userData;
    } catch (error) {
      yield put(registerStudentFail(error));
    }
  });
}

function* signInStudentPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const signInData = action.payload;
      const response = yield call(api.signInStudent, signInData);
      const userData = api.unpackUser(
        response.data.account,
        response.data.client
      );

      // yield call(api.setToken, clientInfo.token);
      yield call(api.setToken, response.data.jwt.token);
      yield call(api.storeStorageData, '@user', userData);
      yield put(signInStudentSuccess(userData));
      return yield userData;
    } catch (error) {
      yield put(signInStudentFail(error));
    }
  });
}

function* signOutHandler() {
  try {
    // todo: clear redux
    yield call(api.resetToken);
    // yield put(resetVillage());
    // yield put(clearVillage());
  } catch (error) {
    console.error(error);
  }
}

function* changePwPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.findPasswordClient, action.payload);
      return response.data;
    } catch (error) {
      yield put(changePasswordError(error));
    }
  });
}

function* restoreUserHandler({ profile: profileData }) {
  try {
    console.log('Restoring from session...');
    // todo: this packs then immediately unpacks profileData.
    // todo: should fix this
    yield call(api.setToken, sessionStorage.getItem('token'));
  } catch (error) {
    console.error(error);
  }
}

function* userSaga() {
  yield takeLeading(registerStudentPromise, registerStudentPromiseHandler);
  yield takeLeading(PURGE_ALL, purgeAllHandler);
  yield takeLeading(signInStudentPromise, signInStudentPromiseHandler);
  yield takeLeading(changePasswordPromise, changePwPromiseHandler);
  yield takeLeading(getUserPromise, getUserPromiseHandler);
  yield takeLeading(SIGN_OUT, signOutHandler);
  yield takeLeading(RESTORE_USER, restoreUserHandler);
}

export default userSaga;
