import React from 'react';
import AuthManager from '@managers/AuthManager';
import BlockingManager from '@managers/BlockingManager';
import ClassManager from '@managers/ClassManager';
import GroupManager from '@managers/GroupManager';
import ScreenManager from '@managers/ScreenManager';
import SiteManager from '@managers/SiteManager';
import ConnectionManager from '@managers/ConnectionManager';
import RefreshManager from './RefreshManager';
import AlertManager from './AlertManager';
import EventLogManager from './EventLogManager';

const MasterManager = () => {
  return (
    <AuthManager>
      <ConnectionManager />
      <RefreshManager />
      <GroupManager />
      <ClassManager />
      <AlertManager />
      <BlockingManager />
      <ScreenManager />
      <SiteManager />
      <EventLogManager />
    </AuthManager>
  );
};

export default MasterManager;
