export const SurveyConstans = {
  LONG: 'LONG',
  SHORT: 'SHORT',
  RADIO: 'RADIO',
  OX: 'OX',
  HEADLINE: 'HEADLINE',
  TIME: 'TIME',
  STYLUS: 'STYLUS',
  MULTIPLE: 'MULTIPLE',
  DAY: 'DAY',
  SECTION: 'SECTION',
};
