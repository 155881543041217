import {
  Box,
  Button,
  Container,
  Progress,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState, useRef } from "react";
import SurveyView from "./SurveyView";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import {
  setUploadTrigger,
  purgeUploadTrigger,
  purgeUploadResponse,
  purgeHasAnswer,
  purgeSurveyResponseOk,
  setReportLog,
} from "@store/actions";
import ArchiveModule from "@pages/QuizPang/data-archive";
import QuizLogger from "@pages/QuizPang/quiz-logger";
import { getUserAgent } from "@pages/QuizPang/utils";
import { SurveyConstans } from "./surveyConstans";
import { ButtonConstants } from "constants/buttonConstants";

const sessionDeviceType = getUserAgent();

const SurveySession = ({
  groupId,
  quizsetId,
  quizsetSessionId,
  quizsetData,
  quizCount,
  quizsetType,
  quizTitle,
  quizzes,
  type,
  currentIndex,
  setCurrentIndex,
  surveys,
}) => {
  const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
  const pagePercent = Math.floor(100 / quizCount);
  const [currentPagePercent, setCurrentPagePercent] = useState(pagePercent);
  const dispatch = useDispatch();
  const history = useHistory();
  const surveyResponseOk = useSelector(state => state.control.surveyResponseOk);
  const uploadTrigger = useSelector(state => state.control.uploadTrigger);
  const user = useSelector(state => state.user);
  const hasAnswer = useSelector(state => state.control.hasAnswer);
  const containerRef = useRef(null);
  const quiz = quizzes[currentQuizIndex];
  // console.log('quizzz', quiz);

  const [gpsData, setGpsData] = useState(null);
  const [accelgyroData, setAceelgyroData] = useState(null);
  const [sessionTouchData, setSessionTouchData] = useState([]);
  const [sessionKeyboardData, setSessionKeyboardData] = useState([]);
  const [sessionStylusData, setSessionStylusData] = useState([]);
  const [isStartRecording, setIsStartRecording] = useState(true);
  const [voiceData, setVoiceData] = useState(null);
  const [eyetrackingData, setEyeTrackingData] = useState(null);
  const [quizEnded, setQuizEnded] = useState(false);
  const quizSessionType = "QUIZ";
  const isLastQuiz = currentQuizIndex === quizCount - 1;

  const uploadResponse = useSelector(state => state.control.uploadResponse);
  const uploadHandler = () => {
    setQuizEnded(true);
    dispatch(setUploadTrigger());
  };

  const surveyNextHandler = () => {
    if (currentIndex < surveys.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      history.push(`/focussurvey/${groupId}`);
    }

    // 만약 마지막 index라면 홈으로 이동
  };

  const uploadAndNextHandler = async () => {
    await uploadHandler();
    await surveyNextHandler();
  };

  // useEffect(() => {
  //   if (uploadTrigger && !uploadResponse && !isLastQuiz) {
  //     console.log('ccc index');
  //     const id = setTimeout(() => {
  //       console.log('ccc index timeout');
  //       setCurrentQuizIndex(currentQuizIndex + 1);
  //       setCurrentPagePercent(currentQuizIndex * pagePercent + pagePercent);
  //       return clearTimeout(id);
  //     }, 1000);
  //   }
  // }, [uploadResponse, uploadTrigger]);

  useEffect(() => {
    if (surveyResponseOk && !isLastQuiz) {
      setCurrentQuizIndex(currentQuizIndex + 1);
      setCurrentPagePercent(currentQuizIndex * pagePercent + pagePercent);
      dispatch(purgeSurveyResponseOk());
    }
  }, [surveyResponseOk]);

  return (
    <Container centerContent>
      <QuizLogger
        name="SurveySessionLogger"
        quizType={quiz.quizType}
        quizIndex={currentQuizIndex}
        quizSessionType={quizSessionType}
        onEyeTrackingData={setEyeTrackingData}
        onGpsData={setGpsData}
        onAccelgyroData={setAceelgyroData}
        myRef={containerRef}
        touchData={sessionTouchData}
        setTouchData={setSessionTouchData}
        deviceType={sessionDeviceType}
        isStartRecording={isStartRecording}
        onVoiceData={setVoiceData}
        clientId={user.clientId}
        quizId={quiz.quizId}
        quizEnded={quizEnded}
      >
        <Container maxW={"container.xl"} height="100vh">
          <Stack direction={"column"} spacing={4} mb={4}>
            <SurveyView
              type={type}
              quiz={quiz}
              quizsetSessionId={quizsetSessionId}
              isLastQuiz={isLastQuiz}
              groupId={groupId}
              setQuizEnded={setQuizEnded}
              quizEnded={quizEnded}
              keyboardData={sessionKeyboardData}
              setKeyboardData={setSessionKeyboardData}
            />
            <Box borderRadius={"lg"} border="1px" borderColor="gray.200" p={4}>
              <Stack direction={"column"} spacing={4}>
                <Stack
                  direction={"row"}
                  spacing={4}
                  justifyContent={"space-between"}
                >
                  <Text fontSize="sm">{quizTitle}</Text>

                  {currentQuizIndex === quizCount - 1 ? (
                    type === "PACKAGE" ? (
                      <Button
                        isDisabled={!hasAnswer}
                        colorScheme="blue"
                        onClick={() => {
                          uploadAndNextHandler();
                          dispatch(
                            setReportLog(ButtonConstants.SURVEY.NEXT_QUESTION),
                          );
                        }}
                      >
                        다음 설문으로
                      </Button>
                    ) : (
                      <Button
                        isDisabled={!hasAnswer}
                        colorScheme="blue"
                        onClick={() => {
                          uploadHandler();
                          dispatch(
                            setReportLog(ButtonConstants.SURVEY.END_SURVEY),
                          );
                        }}
                      >
                        설문 종료
                      </Button>
                    )
                  ) : (
                    <Button
                      isDisabled={!hasAnswer}
                      colorScheme="blue"
                      onClick={() => {
                        uploadHandler();
                        dispatch(
                          setReportLog(ButtonConstants.SURVEY.NEXT_QUESTION),
                        );
                      }}
                    >
                      다음 설문으로
                    </Button>
                  )}
                </Stack>
                <Progress
                  value={
                    currentQuizIndex === quizCount - 1
                      ? 100
                      : currentPagePercent + pagePercent
                  }
                />
              </Stack>
            </Box>
            <div style={{ height: 20 }} />
            <ArchiveModule
              clientId={user.clientId}
              quizId={quiz.quizId}
              quizEnded={quizEnded}
              quizTimelimit={quiz.timelimit}
              gpsData={gpsData}
              accelgyroData={accelgyroData}
              touchData={sessionTouchData}
              keyboardData={sessionKeyboardData}
              stylusData={sessionStylusData}
              voiceData={voiceData}
              eyetrackingData={eyetrackingData}
            />
          </Stack>
        </Container>
      </QuizLogger>
    </Container>
  );
};

export default SurveySession;
