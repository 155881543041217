import { StyledCardBlue } from '@pages/Survey/common';
import React from 'react';

export const SurveySection = ({ title }) => {
  return (
    <StyledCardBlue>
      <span dangerouslySetInnerHTML={{ __html: title }} />
    </StyledCardBlue>
  );
};
