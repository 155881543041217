import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const QuizOX = ({ choiceDataItem, answerData, totalParticipants }) => {
  const { t } = useTranslation();
  const { description, isAnswer, choiceId, chooserCount } = choiceDataItem;

  const isYes = description === "O";
  const isWrongAnswer = description === "Wrong Answer";
  const answerMatch = answerData?.some(
    (answer) => answer.choiceId === choiceId
  );
  const studentAnswer = answerMatch && !isAnswer ? "orange" : "";
  const correctAnswerMatches =
    answerMatch && isAnswer
      ? "royalblue"
      : (isAnswer && "teal.500") || "gray.200";
  const percentage = chooserCount / totalParticipants || 0;

  if (isWrongAnswer) return null;

  return (
    <Box
      m="20px"
      p="20px 10px"
      bg="white"
      border="2px"
      borderRadius="md"
      borderColor={studentAnswer || correctAnswerMatches}
    >
      <Box
        color={isYes ? "royalblue" : "red.300"}
        fontSize="28px"
        fontWeight="600"
        p="0 50px"
      >
        <Text>{description}</Text>
      </Box>
      <Text textAlign="center" fontSize="12px" color="gray.500">
        {t("person", { count: chooserCount })}
        {/* {chooserCount}명 */}
        <Box as="span">({Math.round(percentage * 100)}%)</Box>
      </Text>
    </Box>
  );
};

export default QuizOX;
