import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import io from 'socket.io-client';
import { useSelector } from 'react-redux';
import user from '@store/user/reducer';

const pc_config = {
  iceServers: [
    {
      urls: 'stun:stun.l.google.com:19302',
    },
    {
      urls: 'turn:turn.focuspang.ai',
      username: 'rubis',
      credential: '4542rubis',
    },
  ],
  iceTransportPolicy: 'all',
};

const SOCKET_SERVER_URL = 'https://relay.focuspang.ai';

const Container = styled.div`
  position: relative;
  display: inline-block;
  width: 1024px;
  height: 768px;
  margin: 5px;
`;

const VideoContainer = styled.video`
  width: 1024px;
  height: 768px;
  background-color: black;
`;

const UserLabel = styled.p`
  display: inline-block;
  position: absolute;
  top: 230px;
  left: 0px;
`;
const Video = ({ id, stream, muted }) => {
  const ref = useRef(null);
  const [isMuted, setIsMuted] = useState(false);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (ref.current) ref.current.srcObject = stream;
    if (muted) setIsMuted(muted);
  }, [stream, muted]);

  return (
    <Container>
      <VideoContainer ref={ref} muted={isMuted} autoPlay />
      <UserLabel>{id}</UserLabel>
    </Container>
  );
};

const Dev = () => {
  const socketRef = useRef(null);
  const pcsRef = useRef({});
  const localVideoRef = useRef(null);
  const localStreamRef = useRef();
  const [users, setUsers] = useState([]);
  const userRef = useRef(users);

  const getLocalStream = useCallback(async () => {
    try {
      // const localStream = await navigator.mediaDevices.getUserMedia({
      //   audio: false,
      //   video: {
      //     width: 320,
      //     height: 240,
      //   },
      // });
      // const localStream = await navigator.mediaDevices.getDisplayMedia({
      //   audio: false,
      //   video: {
      //     width: 240,
      //     height: 240,
      //   },
      // });

      // localStream
      //   .getVideoTracks()[0]
      //   .applyConstraints({ frameRate: { max: 5 } });

      const localStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });

      const videoTrack = localStream.getVideoTracks()[0];

      // 16:9 비율을 유지하면서 너비를 240으로 설정
      const desiredWidth = 720;
      const desiredHeight = (720 * 9) / 16;

      const constraints = {
        width: { ideal: desiredWidth },
        height: { ideal: desiredHeight },
      };

      videoTrack.applyConstraints(constraints);

      localStreamRef.current = localStream;
      if (localVideoRef.current) localVideoRef.current.srcObject = localStream;
      if (!socketRef.current) return;

      socketRef.current.emit('join_room', {
        room: '1234',
        clientId: user.clientId,
      });
    } catch (e) {
      console.log(`getUserMedia error: ${e}`);
    }
  }, []);

  const createPeerConnection = useCallback((socketID, email, role) => {
    try {
      const pc = new RTCPeerConnection(pc_config);

      pc.onicecandidate = (e) => {
        if (!(socketRef.current && e.candidate)) return;
        console.log('onicecandidate', e);
        socketRef.current.emit('candidate', {
          candidate: e.candidate,
          candidateSendID: socketRef.current.id,
          candidateReceiveID: socketID,
        });
      };

      pc.oniceconnectionstatechange = (e) => {
        console.log(e);
      };

      pc.ontrack = (e) => {
        console.log(
          'ontrack success',
          users,
          userRef.current,
          socketID,
          email,
          e.streams[0]
        );
        setUsers((oldUsers) => {
          return oldUsers
            .filter((user) => user?.id !== socketID)
            .concat({
              id: socketID,
              email,
              role,
              stream: e.streams[0],
            });
        });

        console.log('setusers after', users);
      };

      if (localStreamRef.current) {
        console.log('localstream add');
        localStreamRef.current.getTracks().forEach((track) => {
          if (!localStreamRef.current) return;
          pc.addTrack(track, localStreamRef.current);
        });
      } else {
        console.log('no local stream');
      }

      return pc;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }, []);

  useEffect(() => {
    socketRef.current = io.connect(SOCKET_SERVER_URL, {
      cors: { origin: '*' },
    });
    getLocalStream();

    socketRef.current.on('all_users', (allUsers) => {
      allUsers.forEach(async (user) => {
        if (user.email !== 'teacher@naver.com') {
          // 선생님과 연결되지 않는 학생만 처리합니다.
          return;
        }
        if (!localStreamRef.current) return;
        const pc = createPeerConnection(user.id, user.email);
        if (!(pc && socketRef.current)) return;
        pcsRef.current = { ...pcsRef.current, [user.id]: pc };
        try {
          const localSdp = await pc.createOffer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: true,
          });
          console.log('create offer success');
          await pc.setLocalDescription(new RTCSessionDescription(localSdp));
          socketRef.current.emit('offer', {
            sdp: localSdp,
            offerSendID: socketRef.current.id,
            offerSendEmail: `${user.id}@sample.com`,
            offerReceiveID: user.id,
          });
        } catch (e) {
          console.error(e);
        }
      });
    });

    socketRef.current.on('getOffer', async (data) => {
      const { sdp, offerSendID, offerSendEmail } = data;
      console.log('get offer');
      if (!localStreamRef.current) return;
      const pc = createPeerConnection(offerSendID, offerSendEmail);
      if (!(pc && socketRef.current)) return;
      pcsRef.current = { ...pcsRef.current, [offerSendID]: pc };
      try {
        await pc.setRemoteDescription(new RTCSessionDescription(sdp));
        console.log('answer set remote description success');
        const localSdp = await pc.createAnswer({
          offerToReceiveVideo: true,
          offerToReceiveAudio: true,
        });
        await pc.setLocalDescription(new RTCSessionDescription(localSdp));
        socketRef.current.emit('answer', {
          sdp: localSdp,
          answerSendID: socketRef.current.id,
          answerReceiveID: offerSendID,
        });
      } catch (e) {
        console.error(e);
      }
    });

    socketRef.current.on('getAnswer', (data) => {
      const { sdp, answerSendID } = data;
      console.log('get answer');
      const pc = pcsRef.current[answerSendID];
      if (!pc) return;
      pc.setRemoteDescription(new RTCSessionDescription(sdp));
    });

    socketRef.current.on('getCandidate', async (data) => {
      console.log('get candidate');
      const pc = pcsRef.current[data.candidateSendID];
      if (!pc) return;
      await pc.addIceCandidate(new RTCIceCandidate(data.candidate));
      console.log('candidate add success');
    });

    socketRef.current.on('user_exit', (data) => {
      if (!pcsRef.current[data.id]) return;
      pcsRef.current[data.id].close();
      delete pcsRef.current[data.id];
      setUsers((oldUsers) => oldUsers.filter((user) => user.id !== data.id));
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
      console.log('users array', users);
      users.forEach((user) => {
        if (!pcsRef.current[user.id]) return;
        pcsRef.current[user.id].close();
        delete pcsRef.current[user.id];
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPeerConnection, getLocalStream]);

  return (
    <div>
      <video
        style={{
          width: 240,
          height: 240,
          margin: 5,
          backgroundColor: 'black',
        }}
        muted
        ref={localVideoRef}
        autoPlay
      />
      {users.map((user, index) => {
        console.log('[In Video Array], ', user);
        return <Video key={index} id={user.id} stream={user.stream} />;
      })}
    </div>
  );
};

export default Dev;
