import styles from "./FileLink.module.scss";
import CardFileLink from "../Card/CardFileLink";
import { HStack, Stack } from "@chakra-ui/react";

const FileLink = ({ filelinks }) => {
  return (
    <div
      name="fileLinkContainer"
      className={styles["file-link-container-column"]}
    >
      {filelinks?.reverse().map((filelink, index) => (
        <CardFileLink key={index} filelink={filelink} />
      ))}
    </div>
  );
};

export default FileLink;
