import {
  selectAllMyGroupsCachever,
  selectManagingGroupCacheVer,
} from "@app/store/selectors";
import {
  getMembershipPromise,
  getGroupProgramconfigsPromise,
  getTimetablesByGroupPromise,
  getMembershipsByGroupPromise,
  getGroupFilelinkPromise,
  setSelectedClass,
} from "@store/actions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";
import {
  getActiveManagingByGroupPromise,
  getManagingsByGroupPromise,
} from "@app/store/actions";

const GroupManager = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const myGroups = useSelector((state) =>
    selectAllMyGroupsCachever(state, user)
  );
  const managingGroup = useSelector((state) =>
    selectManagingGroupCacheVer(state)
  );

  useEffect(() => {
    async function fetchUser() {
      await dispatch(getMembershipPromise(user.clientId));
    }
    fetchUser();
  }, []);

  useEffect(() => {
    myGroups?.map((group) => {
      if (group !== undefined || group !== null || group !== "") {
        dispatch(getGroupFilelinkPromise(group.groupId));
      }
    });
  }, [myGroups]);

  useEffect(() => {
    managingGroup?.map((group) => {
      if (group !== undefined || group !== null || group !== "") {
        dispatch(getGroupFilelinkPromise(group.groupId));
        dispatch(getManagingsByGroupPromise(group.groupId));
        dispatch(getActiveManagingByGroupPromise(group.groupId));
      }
    });
  }, [managingGroup]);

  useDeepCompareEffect(() => {
    myGroups?.map((group) => {
      if (group !== undefined || group !== null || group !== "") {
        dispatch(getMembershipsByGroupPromise(group.groupId));
        dispatch(getGroupProgramconfigsPromise(group.groupId));
        dispatch(getTimetablesByGroupPromise(group.groupId));
        // getQuizsetSessionActive(group.groupId)
        //   .then(() => {
        //     dispatch(setSelectedClass(group.groupId));
        //   })
        //   .catch(() => {});
      }
    });
  }, [myGroups]);

  return <div />;
};

export default GroupManager;
