import {
  createMembershipPromise,
  createParticipationPromise,
  getGroupByInviteCodePromise,
  setSocketData,
  resetSocket,
  getActiveClassroomByGroupPromise,
  setReportLog,
} from "@store/actions";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styles from "./InviteCodeJoinUpForm.module.scss";
import { socketKind, socketMethod, socketUrl } from "constants/socketConstants";
import { ButtonConstants } from "constants/buttonConstants";
import { Button, HStack, Input, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
// import {
//   decipherInviteCodePromise,
//   studentJoinUpPromise,
// } from '@store/actions';
// 로그인후 수업추가할때 사용
const InviteCodeJoinUpForm = ({ handleChange, values, setShowInvite }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const user = useSelector(
    (/** @type {import('@store').State} */ state) => state.user
  );
  const history = useHistory();
  console.log("[InviteCodeJoinUpForm]", user);

  const joinToGroup = (e) => {
    const code = values.code.replace(/ /gi, "");
    e.preventDefault();
    setLoading(true);
    dispatch(getGroupByInviteCodePromise(code))
      .then((group) => {
        dispatch(
          createMembershipPromise({
            groupId: group.groupId,
            clientId: user.clientId,
            role: "STUDENT",
          })
        ).then(() => {
          console.log(
            "[InviteCodeJoinUpForm]: 멤버쉽이 성공적으로 생성되었습니다."
          );
          dispatch(getActiveClassroomByGroupPromise(group.groupId))
            .then((
              /** @type {import('@store/classrooms/reducer').ClassroomSession} */ activeClassroomSession
            ) => {
              console.log(
                "[InviteCodeJoinUpForm]: 현재 수업 중인 수업이 존재합니다.",
                activeClassroomSession
              );
              if (
                activeClassroomSession !== null ||
                activeClassroomSession !== undefined
              ) {
                dispatch(
                  createParticipationPromise({
                    classroomId: activeClassroomSession?.classroomId,
                    clientId: user.clientId,
                    attendedAt: new Date().toISOString(),
                  })
                ).then((e) => {
                  dispatch(
                    setSocketData({
                      method: socketMethod.POST,
                      uri: socketUrl.classroomSendImage,
                      groupId: group?.groupId,
                      clientId: user?.clientId,
                      type: socketKind.classroom.JOIN_GROUP,
                      data: "",
                    })
                  );
                  setLoading(false);
                  //화면 리프레쉬
                  window.location.reload();
                });
              } else {
                history.go(0);
              }
            })
            .then(() => {
              dispatch(
                setSocketData({
                  method: "POST",
                  uri: "/classroom/sendImage",
                  groupId: group?.groupId,
                  clientId: user?.clientId,
                  type: "JOIN_GROUP",
                  data: "",
                })
              );
              setLoading(false);
              //화면 리프레쉬
              window.location.reload();
            });
        });
      })
      .catch(() => {
        setLoading(false);
        // alert("존재하지 않는 초대 코드입니다.");
        alert(t("timetable.addcourse.modal.error"));
      });
  };

  return (
    <div>
      <div className={styles["container"]}>
        <HStack spacing={2} w={"full"}>
          <Input
            type="text"
            name="code"
            // placeholder="초대코드"
            placeholder={t("selfcare.text.placeholder")}
            value={values.code}
            onChange={handleChange}
          />

          <Button
            onClick={() => {
              setShowInvite(false);
            }}
          >
            {/* 취소 */}
            {t("w.cancel")}
          </Button>
          {loading ? (
            <Button isLoading>
              {/* 추가중 */}
              {t("timetable.addcourse.modal.add")}
            </Button>
          ) : (
            <Button
              onClick={(e) => {
                joinToGroup(e);
              }}
              type="submit"
              name="submit"
            >
              {/* 수업 추가 */}
              {t("timetable.button.title")}
            </Button>
          )}
        </HStack>
      </div>
    </div>
  );
};

export default InviteCodeJoinUpForm;
