import { selectAllPrograms, selectGroupProgramconfigs } from '@store/selectors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const SiteManager = () => {
  const history = useHistory();

  let isIOSChrome = window.navigator.userAgent.match('CriOS');
  let isChromium = window.chrome;
  let vendorName = window.navigator.vendor;
  let isOpera = typeof window.opr !== 'undefined';
  let isIEedge = window.navigator.userAgent.indexOf('Edg') > -1;

  // for chrome extension
  // for dkkim kigcjnidkkfogajpphkhipndbbcdbfem
  // for
  // for shpark gjomgcgphkeanlpdcdjklcbkgkhikbkp
  // for supercoder  pmgkifpjobmkginiaoeemgeffgoohapo devMode
  const chromeExtensionId = process.env.REACT_APP_CHROME_EXTENSION_ID;
  const user = useSelector((state) => state.user);
  const activeManaging = useSelector((state) => state.activeManaging);
  const allPrograms = useSelector((state) => selectAllPrograms(state));
  const programconfigsManaging = useSelector((state) =>
    selectGroupProgramconfigs(state, activeManaging?.managingId)
  );

  const getOS = () => {
    let userAgent = window.navigator.userAgent,
      platform =
        window.navigator?.userAgentData?.platform ?? window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'WINDOWS';
    } else if (/Android/.test(userAgent)) {
      os = 'ANDROID';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  };

  return <div />;
};

export default SiteManager;
