import { makeFreshAllIds } from '../helpers';
import user from '../user/reducer';
import {
  PURGE_CLIENT,
  PURGE_CLIENT_TEMP,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAIL,
  FIND_CLIENT_SUCCESS,
  FIND_CLIENT_FAIL,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  GET_CLIENT_TEMP_SUCCESS,
  GET_CLIENT_TEMP_FAIL,
  // SIGN_UP_STUDENTS_SUCCESS,
  // SIGN_UP_STUDENTS_FAIL,
  // SIGN_UP_STUDENT_SUCCESS,
  // SIGN_UP_STUDENT_FAIL,

  // SET_PRIMARY_GROUP_SUCCESS,
  SET_PRIMARY_GROUP_FAIL,
} from './actionTypes';

/**
 * @typedef {Object} ClientsState
 * @property {{[key: string]: focuspang.Client}} byId
 * @property {string[]} allIds
 * @property {{[key: string]: focuspang.Client}} byIdTemp
 * @property {string[]} allIdsTemp
 */

const initialState = {
  byId: {},
  allIds: [],
  byIdTemp: {},
  allIdsTemp: [],
};

const clients = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_CLIENT:
      console.log('[Client Reducer] purge client');
      return initialState;

    case PURGE_CLIENT_TEMP:
      return { ...state, byIdTemp: {}, allIdsTemp: [] };
    case GET_CLIENT_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.client.clientId]: action.client },
        allIds: makeFreshAllIds(state.allIds, [action.client.clientId]),
      };

    case GET_CLIENT_TEMP_SUCCESS:
      return {
        ...state,
        byIdTemp: { ...state.byIdTemp, ...action.byIdTemp },
        allIdsTemp: makeFreshAllIds(state.allIdsTemp, action.allIdsTemp),
      };

    case FIND_CLIENT_SUCCESS:
      return {
        ...state,
        byIdTemp: action.byIdTemp,
        allIdsTemp: action.allIdsTemp,
      };

    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
        allIds: makeFreshAllIds(state.allIds, action.allIds),
      };

    // case SET_PRIMARY_GROUP_SUCCESS:
    //   return state;

    // todo: handle errors
    case GET_CLIENT_FAIL:
    case GET_CLIENT_TEMP_FAIL:
    case FIND_CLIENT_FAIL:
    case GET_CLIENTS_FAIL:
    case SET_PRIMARY_GROUP_FAIL:
      // console.error('[Client Reducer] ', action.payload);
      return state;
    default:
      break;
  }
  return state;
};

export default clients;
