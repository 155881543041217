import {
  PURGE_CONTROL,
  SET_DIRTY,
  SET_SELECTED_PAGE,
  SET_SELECTED_CLASS,
  SET_APPROVAL_COMPLETED,
  SET_SIDEBAR_STATE,
  SET_DEV_MODE,
  SET_CLASSROOM_STATE,
  SET_FILELINK_STATE,
  SET_SOCKET,
  SET_SOCKET_DATA,
  SET_SCREEN_DATA,
  SET_TEACHER_SCREEN,
  SET_SELECTED_STUDENT,
  RESET_SOCKET,
  RESET_SOCKET_DATA_QUEUE,
  NEED_SEND_REGISTER_NOTY,
  SET_STUDENT_IMAGES,
  SET_TEACHER_CLIENT_ID,
  CLEAR_BIG_URI,
  SET_BIG_URI,
  SET_REFRESH_STATE,
  SET_SELECTED_GROUP,
  SET_QUIZ_STATE,
  SET_NETWORK_STATE,
  CLEAR_NETWORK_STATE,
  SET_CAMERA_PERMISSION,
  SET_MICROPHONE_PERMISSION,
  SET_LOCATION_PERMISSION,
  SET_DEVICEMOTION_PERMISSION,
  SET_RECIEVED_GROUP_ID,
  SET_UPLOAD_TRIGGER,
  SET_UPLOAD_RESPONSE,
  SET_UPLOAD_ARCHIVE_RESPONSE,
  PURGE_UPLOAD_TRIGGER,
  PURGE_UPLOAD_RESPONSE,
  PURGE_UPLOAD_ARCHIVE_RESPONSE,
  SET_FOCUS_TYPE,
  PURGE_FOCUS_TYPE,
  SET_FOCUS_TIME_STATE,
  PURGE_FOCUS_TIME_STATE,
  SET_HAS_ANSWER,
  PURGE_HAS_ANSWER,
  SET_SURVEY_RESPONSE_OK,
  PURGE_SURVEY_RESPONSE_OK,
  SET_NETWORK_SPEED,
} from "./actionTypes";

///// PURGE_CONTROL
export const purgeControl = () => ({
  type: PURGE_CONTROL,
});

///// SET_DIRTY
export const setDirty = () => ({
  type: SET_DIRTY,
});

///// SET_SELECTED_PAGE
export const setSelectedPage = (selectedPage) => {
  return {
    type: SET_SELECTED_PAGE,
    selectedPage: selectedPage,
  };
};

///// SET_SELECTED_CLASS
export const setSelectedClass = (selectedClassId) => {
  return {
    type: SET_SELECTED_CLASS,
    selectedClassId: selectedClassId,
  };
};

///// SET_APPROVAL_COMPLETED
export const setApprovalCompleted = () => {
  return {
    type: SET_APPROVAL_COMPLETED,
  };
};

///// SET_SIDEBAR_STATE
export const setSidebarState = () => {
  return {
    type: SET_SIDEBAR_STATE,
  };
};

///// SET_DEV_MODE
export const setDevMode = (devMode) => {
  return {
    type: SET_DEV_MODE,
    devMode: devMode,
  };
};

//// SET_CLASSROOM_STATE
export const setClassroomState = (classState) => {
  return {
    type: SET_CLASSROOM_STATE,
    classState: classState,
  };
};

//// SET_FILELINK_STATE
export const setFilelinkState = () => {
  return {
    type: SET_FILELINK_STATE,
  };
};

//// SET_SOCKET
export const setSocket = (socket) => {
  return {
    type: SET_SOCKET,
    socket: socket,
  };
};

//// SET_SOCKET_DATA
/**
 *
 * @param {Object} data
 * @param {() => void} [onSend]
 * @returns
 */
export const setSocketData = (data, onSend) => {
  return {
    type: SET_SOCKET_DATA,
    socketData: {
      data: data,
      onSend: onSend,
    },
  };
};

//// SET_SCREEN_DATA
export const setScreenData = (screenData) => {
  return {
    type: SET_SCREEN_DATA,
    screenData: screenData,
  };
};

//// SET_TEACHER_SCREEN
export const setTeacherScreen = (teacherScreen) => {
  return {
    type: SET_TEACHER_SCREEN,
    teacherScreen: teacherScreen,
  };
};

//// SET_SELECTED_STUDENT
export const setSelectedStudent = (selectedStd) => {
  return {
    type: SET_SELECTED_STUDENT,
    selectedStd: selectedStd,
  };
};

/**
 *
 * @param {Date} time
 * @returns
 */
export const resetSocket = (time) => {
  return {
    type: RESET_SOCKET,
    socketResetTime: time,
  };
};
/**
 *
 * @returns
 */
export const resetSocketDataQueue = () => {
  return {
    type: RESET_SOCKET_DATA_QUEUE,
  };
};

// NEED_SEND_REGISTER_NOTY
export const registerNoty = (registerNoty) => {
  return {
    type: NEED_SEND_REGISTER_NOTY,
    registerNoty: registerNoty,
  };
};

export const setStudentImages = (data) => {
  return {
    type: SET_STUDENT_IMAGES,
    data,
  };
};

export const setTeacherClientId = (clientId) => {
  return {
    type: SET_TEACHER_CLIENT_ID,
    clientId: clientId,
  };
};

export const setBigURI = (bigURI) => {
  return {
    type: SET_BIG_URI,
    bigURI: bigURI,
  };
};

export const clearBigURI = () => {
  return {
    type: CLEAR_BIG_URI,
  };
};

export const setRefreshstate = (date) => {
  return {
    type: SET_REFRESH_STATE,
    date: date,
  };
};

export const setSelectedGroup = (selectedGroup) => {
  return {
    type: SET_SELECTED_GROUP,
    selectedGroup: selectedGroup,
  };
};

export const setQuizEnded = (quizState) => {
  return {
    type: SET_QUIZ_STATE,
    quizState: quizState,
  };
};

export const setNetworkState = (networkState) => {
  return {
    type: SET_NETWORK_STATE,
    networkState: networkState,
  };
};

export const clearNetworkState = () => {
  return {
    type: CLEAR_NETWORK_STATE,
    networkState: 0,
  };
};

export const setCameraPermission = () => {
  return {
    type: SET_CAMERA_PERMISSION,
  };
};

export const setMicrophonePermission = () => {
  return {
    type: SET_MICROPHONE_PERMISSION,
  };
};

export const setLocationPermission = () => {
  return {
    type: SET_LOCATION_PERMISSION,
  };
};

export const setDevicemotionPermission = () => {
  return {
    type: SET_DEVICEMOTION_PERMISSION,
  };
};

export const setReceviedGroupId = (groupId) => {
  return {
    type: SET_RECIEVED_GROUP_ID,
    groupId: groupId,
  };
};

export const setUploadTrigger = (uploadTrigger) => {
  return {
    type: SET_UPLOAD_TRIGGER,
  };
};

export const purgeUploadTrigger = () => {
  return {
    type: PURGE_UPLOAD_TRIGGER,
  };
};

export const setUploadResponse = () => {
  return {
    type: SET_UPLOAD_RESPONSE,
  };
};

export const purgeUploadResponse = () => {
  return {
    type: PURGE_UPLOAD_RESPONSE,
  };
};

export const setUploadArchiveResponse = () => {
  return {
    type: SET_UPLOAD_ARCHIVE_RESPONSE,
  };
};

export const purgeUploadArchiveResponse = () => {
  return {
    type: PURGE_UPLOAD_ARCHIVE_RESPONSE,
  };
};

export const setFocusTimeState = (focusTimeState) => {
  return {
    type: SET_FOCUS_TIME_STATE,
    focusTimeState: focusTimeState,
  };
};

export const purgeFocusTimeState = () => {
  return {
    type: PURGE_FOCUS_TIME_STATE,
  };
};

export const setFocusType = (focusType) => {
  return {
    type: SET_FOCUS_TYPE,
    focusType: focusType,
  };
};

export const purgeFocusType = () => {
  return {
    type: PURGE_FOCUS_TYPE,
  };
};

export const setHasAnswer = () => {
  return {
    type: SET_HAS_ANSWER,
  };
};

export const purgeHasAnswer = () => {
  return {
    type: PURGE_HAS_ANSWER,
  };
};
export const setSurveyResponseOk = () => {
  return {
    type: SET_SURVEY_RESPONSE_OK,
  };
};

export const purgeSurveyResponseOk = () => {
  return {
    type: PURGE_SURVEY_RESPONSE_OK,
  };
};

export const setNetworkSpeed = (networkSpeed) => {
  return {
    type: SET_NETWORK_SPEED,
    networkSpeed: networkSpeed,
  };
};
