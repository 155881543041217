import { useState, useEffect } from "react";
import { Stack, Typography, Divider } from "@mui/material";
import SidebarClassRoom from "@components/Layout/SidebarClassRoom";
import BaseCard from "@components/Card/baseCard";
import axios from "axios";
import { NoticeItem } from "./NoticeItem";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectAllUnreadNotifications } from "@store/selectors";
import { getNotification, readNotificationPromise } from "@store/actions";
import { isAfter } from "date-fns";
import { getGroupnoticeByGroupAxios } from "@api";
import { useTranslation } from "react-i18next";

export const Notice = () => {
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const user = useSelector((state) => state.user);
  const [groupnotices, setGroupnotices] = useState([]);
  // const user = useSelector((state) => state.user);
  const unreadNotifications = useSelector((state) =>
    selectAllUnreadNotifications(state)
  );

  useEffect(() => {
    const getGroupnoticeByGroup = async () => {
      try {
        const response = getGroupnoticeByGroupAxios(groupId).then((res) => {
          const notices = res.data.groupnotices
            .filter(
              (groupnotice) =>
                groupnotice.noticeType === "NOTICE" ||
                groupnotice.noticeType === "SUBMISSION"
            )
            .filter((notice) =>
              isAfter(new Date(), new Date(notice.releaseAt))
            );
          const sorted = notices.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setGroupnotices(sorted);
        });
      } catch (error) {
        setGroupnotices([]);
      }
    };
    groupId && getGroupnoticeByGroup();
  }, [groupId]);

  useEffect(() => {
    if (!unreadNotifications.length || !user?.clientId) {
      return;
    }

    setTimeout(() => {
      unreadNotifications.map((notification) => {
        dispatch(
          readNotificationPromise({
            notificationId: notification.notificationId,
            state: "READ",
          })
        );
      });
    }, 300).then(() => {
      dispatch(getNotification(user.clientId));
    });
  }, [dispatch, user?.clientId, unreadNotifications]);
  const { t } = useTranslation();
  return (
    <>
      <SidebarClassRoom inClass />
      <Stack ml="280px" p="14px">
        <BaseCard style={{ minHeight: "100vh" }}>
          <Stack px="40px" py="20px" minHeight="100%" spacing={3}>
            <Stack direction="row" alignItems="center" spacing={3}>
              <Typography variant="h6">
                {/* 수업 공지사항 */}
                {t("sidemenu.menu.notification")}
              </Typography>
            </Stack>
            <Divider />
            {groupnotices.map((groupnotice) => (
              <NoticeItem
                key={groupnotice.groupnoticeId}
                groupnotice={groupnotice}
              />
            ))}
          </Stack>
        </BaseCard>
      </Stack>
    </>
  );
};
