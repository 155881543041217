import { patchArchiveBackend, postArchiveBackend } from '@api/Backend';

/**
 * Creates an archive with the given data.
 * @param {Object} archiveData
 * @param {string} archiveData.context
 * @param {string} archiveData.contextId
 * @param {string} archiveData.domain
 * @param {string} archiveData.startedAt
 * @param {string} archiveData.endedAt
 */
export const createArchive = async (archiveData) => {
  return await postArchiveBackend('/archive', archiveData);
};

export const updateCompletedArchive = async (archiveId) => {
  return await patchArchiveBackend('/archive', archiveId);
};
