import { makeFreshAllIds } from '../helpers';
import {
  PURGE_QUIZ,
  PURGE_OTHER_SCHOOL_QUIZ,
  GET_QUIZ_LIST_BY_CLIENT_SUCCESS,
  GET_QUIZ_LIST_BY_CLIENT_FAIL,
  GET_QUIZ_LIST_BY_SCHOOL_SUCCESS,
  GET_QUIZ_LIST_BY_SCHOOL_FAIL,
  CREATE_QUIZ_SUCCESS,
  CREATE_QUIZ_FAIL,
  UPDATE_QUIZ_SUCCESS,
  UPDATE_QUIZ_FAIL,
  DELETE_QUIZ_SUCCESS,
  DELETE_QUIZ_FAIL,
} from './actionTypes';

const initialState = {
  // quiz owned by user
  byId: {},
  allIds: [],
  // quiz from other school
  otherSchoolById: {},
  otherSchoolAllIds: [],
};

const quiz = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_QUIZ:
      return initialState;
    case PURGE_OTHER_SCHOOL_QUIZ:
      return {
        ...state,
        otherSchoolById: {},
        otherSchoolAllIds: [],
      };

    case GET_QUIZ_LIST_BY_CLIENT_SUCCESS:
      return {
        ...state,
        byId: action.byId,
        allIds: action.allIds,
      };

    case GET_QUIZ_LIST_BY_SCHOOL_SUCCESS:
      return {
        ...state,
        otherSchoolById: action.otherSchoolById,
        otherSchoolAllIds: action.otherSchoolAllIds,
      };

    case CREATE_QUIZ_SUCCESS:
    case UPDATE_QUIZ_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.quiz.quizId]: action.quiz,
        },
        allIds: makeFreshAllIds(state.allIds, [action.quiz.quizId]),
      };

    case DELETE_QUIZ_SUCCESS:
      const { [action.quizId]: _, ...otherById } = state.byId;
      return {
        ...state,
        byId: otherById,
        allIds: state.allIds.filter((quizId) => quizId !== action.quizId),
      };

    case CREATE_QUIZ_FAIL:
    case UPDATE_QUIZ_FAIL:
    case DELETE_QUIZ_FAIL:
      // console.error('[Quiz Reducer] ', action.payload);
      return state;

    case GET_QUIZ_LIST_BY_CLIENT_FAIL:
    case GET_QUIZ_LIST_BY_SCHOOL_FAIL:
      // console.error('[Quiz Reducer] ', 'list fetching failed');
      return state;

    default:
      break;
  }
  return state;
};

export default quiz;
