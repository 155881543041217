import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveClassroomByGroupPromise,
  setScreenData,
  setTeacherScreen,
} from "@store/actions";

const ScreenManager = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const activeClassroom = useSelector((state) => state.classrooms.classSession);
  const selectedStudent = useSelector((state) => state.control.selectedStd);
  const networkSpeed = useSelector((state) => state.control.networkSpeed);
  const screenLocked = activeClassroom?.screenLocked;
  const { groupClientIds } = useSelector((state) => state.membership);

  const [prevScreenLocked, setPrevScreenLocked] = useState(false);
  const [randomBackOffNumber, setRandomBackOffNumber] = useState(1000);

  const chromeExtensionId = process.env.REACT_APP_CHROME_EXTENSION_ID;

  console.log("networkSpeed", networkSpeed);

  useEffect(() => {
    if (user == null || user.signedIn === false) {
      return;
    }
    if (!activeClassroom) {
      return;
    }
    dispatch(getActiveClassroomByGroupPromise(activeClassroom.groupId)).then(
      (activeClassroomData) => {
        console.log(
          "[ScreenManager]",
          "activeClassroomData",
          activeClassroomData.screenLocked
        );
        if (
          activeClassroomData.screenLocked &&
          selectedStudent !== user.clientId
        ) {
          console.log("[ScreenManager]", "Screen locked!");
          try {
            chrome.runtime.sendMessage(chromeExtensionId, {
              type: "LOCK_SCREEN",
            });
          } catch (error) {
            console.log(
              "[ScreenManager]",
              "확장프로그램으로 화면잠금 신호를 보내던 도중 문제가 발생하였습니다.",
              error
            );
          }
          try {
            window.cAPI.setIsScreenLocked(true);
          } catch (error) {
            console.log(
              "[ScreenManager]",
              "확장프로그램으로 화면잠금 신호를 보내던 도중 문제가 발생하였습니다.",
              error
            );
          }
          return;
        }
        if (!activeClassroomData.screenLocked) {
          console.log("[ScreenManager]", "Screen unlocked!");
          try {
            chrome.runtime.sendMessage(chromeExtensionId, {
              type: "UNLOCK_SCREEN",
            });
          } catch (error) {
            console.log(
              "[ScreenManager]",
              "확장프로그램으로 화면잠금 신호를 보내던 도중 문제가 발생하였습니다.",
              error
            );
          }
          try {
            window.cAPI.setIsScreenLocked(false);
          } catch (error) {}
          if (prevScreenLocked) {
            setPrevScreenLocked(false);
            console.log(
              "[ScreenManager]",
              "Screen unlocked! Navigating to App"
            );
            return;
          }
          setPrevScreenLocked(false);
          return;
        }
      }
    );
  }, [screenLocked]);

  useEffect(() => {
    if (user == null || user.signedIn === false) {
      return;
    }
    if (!activeClassroom?.groupId) {
      return;
    }
    if (!activeClassroom) {
      return;
    }
    if (activeClassroom.screenLocked) return;

    if (activeClassroom.screenShared && selectedStudent !== user.clientId) {
      console.log("[ScreenManager]", "Screen shared!");
      try {
        chrome.runtime.sendMessage(chromeExtensionId, {
          type: "FULL_SCREEN",
        });
        window.cAPI.setIsScreenShared(true);
      } catch (error) {
        console.log(error);
      }
      return;
    } else if (
      activeClassroom.screenShared &&
      selectedStudent === user.clientId
    ) {
    } else {
      dispatch(setTeacherScreen(null));
      try {
        chrome.runtime.sendMessage(chromeExtensionId, {
          type: "NORMAL_SCREEN",
        });
        window.cAPI.setIsScreenShared(false);
      } catch (error) {
        // it's OK, when it is browser app, the error is normal.
        console.log(error);
      }
    }
  }, [activeClassroom, selectedStudent]);

  const getRandomNumber = () => {
    // 네트워크 속도에 따라서 랜덤 숫자를 조정한다. 네트워크 속도가 낮을수록 랜덤 숫자 범위를 높인다.
    let min = 1000;
    let max = 10000;

    if (networkSpeed < 3) {
      min = 5000;
      max = 15000;
    } else if (networkSpeed < 5) {
      min = 2000;
      max = 10000;
    } else if (networkSpeed < 7) {
      min = 1000;
      max = 5000;
    }

    const rand = Math.floor(Math.random() * (max - min + 1)) + min;
    setRandomBackOffNumber(rand);
  };

  useEffect(() => {
    if (!activeClassroom?.groupId) {
      return;
    }

    const capture = (video, scaleFactor = 0.5) => {
      // var w = selectedStudent === user.clientId ? 1600 : 400;
      // var h = selectedStudent === user.clientId ? 900 : 220;

      // network 속도에 따라서 화질을 조정한다. 만약 자신의 화면이 선택되어있으면 화질을 높이 되 속도에 따른 화질 조정을 실행
      var w = selectedStudent === user.clientId ? 1600 : 400;
      var h = selectedStudent === user.clientId ? 900 : 220;
      if (networkSpeed < 3) {
        w = selectedStudent === user.clientId ? 800 : 200;
        h = selectedStudent === user.clientId ? 450 : 110;
      } else if (networkSpeed < 5) {
        w = selectedStudent === user.clientId ? 1200 : 300;
        h = selectedStudent === user.clientId ? 675 : 165;
      } else if (networkSpeed < 7) {
        w = selectedStudent === user.clientId ? 1600 : 400;
        h = selectedStudent === user.clientId ? 900 : 220;
      }

      var canvas = document.createElement("canvas");
      canvas.id = "canvas";
      canvas.width = w;
      canvas.height = h;
      var ctx = canvas.getContext("2d");
      ctx?.drawImage(video, 0, 0, w, h);

      return canvas;
    };
    const interval = setInterval(async () => {
      getRandomNumber();
      const videoElem = document.getElementById("video");
      const canvas = capture(videoElem);
      const img = canvas
        .toDataURL("image/webp", 0.5)
        .replace("data:image/webp;base64,", "");
      dispatch(
        setScreenData({
          method: "POST",
          uri: "/classroom/sendImage",
          type: "IMAGE_STUDENT",
          clientId: user.clientId,
          groupId: activeClassroom.groupId,
          data: img,
          targetClients: groupClientIds.filter((id) => id !== user.clientId),
        })
      );
    }, 1000 + randomBackOffNumber);

    return () => {
      clearInterval(interval);
    };
  }, [
    activeClassroom?.groupId,
    selectedStudent,
    dispatch,
    groupClientIds,
    user.clientId,
    randomBackOffNumber,
    networkSpeed,
  ]);

  return (
    <div>
      <video
        autoPlay
        id="video"
        className="hidden w-2/3 mt-5 mb-10 border-2"
        // controls="false"
        style={{
          display: "none",
        }}
      />
    </div>
  );
};

export default ScreenManager;
