import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import styled from 'styled-components';
import {
  Prompt,
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import {
  useQuizset,
  useSurveySessionStudent,
} from '@pages/QuizPang/hooks/useSurvey';
import { SurveySession } from './survey-session';
import { useSelector } from 'react-redux';
import Survey from '.';
import SurveyMain from './survey-main';

const SurveyBase = (props) => {
  const [isBlocking, setIsBlocking] = useState(true);
  const groupId = useSelector((state) => state.control.receivedGroupId);
  const user = useSelector((state) => state.user);
  const [index, setIndex] = useState(0);

  const surveyData = useSurveySessionStudent(groupId, user?.clientId);

  if (surveyData.isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (surveyData.isError) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="justify-center items-center flex flex-col space-y-4 ">
          <div className="text-2xl text-gray-900">
            설문을 불러오는 도중 문제가 발생하였습니다. 새로고침을 해주세요.
          </div>
          <button
            className="bg-primary text-white rounded-md px-4 py-2"
            onClick={() => surveyData.refetch()}
          >
            설문 다시 불러오기
          </button>
        </div>
      </div>
    );
  }

  const surveySessionIds = surveyData.surveySession?.map(
    (item) => item?.quizset.quizsetId
  );

  const quizsetSessionIds = surveyData.surveySession?.map(
    (item) => item?.quizsetSession.quizsetSessionId
  );

  const quizsetSessionIdsLength = quizsetSessionIds?.length;

  return (
    <Container maxWidth="lg">
      <Prompt when={isBlocking} message="설문을 종료하시겠습니까?" />
      <SurveyMain
        groupId={groupId}
        quizsetId={surveySessionIds[index]}
        quizsetSessionId={quizsetSessionIds[index]}
        index={index}
        setIndex={setIndex}
        quizsetSessionIdsLength={quizsetSessionIdsLength}
      />
      {/* <SurveySession
        groupId={groupId}
        quizsetId={quizsetId}
        quizsetSessionId={quizsetSessionId}
        quizsetData={quizsetData}
      /> */}
      {/* <SurveySessionScroll
        groupId={groupId}
        quizsetId={quizsetId}
        quizsetSessionId={quizsetSessionId}
        quizsetData={quizsetData}
      /> */}
    </Container>
  );
};

export default SurveyBase;

const StyledBox = styled.div`
  position: relative;
  width: 100%;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding-bottom: 20px;
`;

const SlideButton = styled.button`
  content: '';
  display: flex;
  z-index: 1;
  border-width: 0 2px 2px 0;
  margin-left: -2px;
  color: var(--primary-darken-1);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  svg {
    width: 100px;
    height: 100px;
  }
`;
