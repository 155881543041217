import styled from 'styled-components';

export const Container = styled.div`
  width: 1580px;
  height: 100%;
  margin: 0 auto;
  padding: 50px 0 0 280px;
`
export const Header = styled.div`
  border-bottom: 1px solid #E4E4E4;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const HeaderTitle = styled.div`
  color: #212120;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25px;
`
export const HeaderMenuContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0 20px;
`
export const HeaderMenuTitle = styled.div`
  color: #212120;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
`
export const HeaderMenu = styled.div`
  position: relative;
`
export const Class = styled.button`
  width: 140px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #E4E4E4;
  padding: 0 18px;
  background: #FFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Body = styled.div`
  padding-top: 50px;
`
export const Contents = styled.div``
export const ContentsHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
export const ExcelBtn = styled.button`
  width: 140px;
  height: 40px;
  border-radius: 5px;
  background: #0E7039;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 10px;
  color: var(--White, #FFF);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.25px;
`
export const Btn = styled.button`
  width: 140px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #E4E4E4;
  background: ${props => props.dark ? '#212120' : '#FFF'};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 15px;
  color: ${props => props.dark ? '#FFF' : '#212120'};
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.25px;
`
export const DateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 25px;
  position: relative;
`
export const ContentsBody = styled.div``
export const BackBtn = styled.button`
  width: 140px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #E4E4E4;
  background: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 15px;
  color: #212120;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.25px;
`