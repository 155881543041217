import { getQuizresponseByQuizsetSessionId } from "@api"
import { useQuery } from "@tanstack/react-query"

/**  */
const useQuizResponseByQuizsetSessionId = (quizsetSessionId) => {
	const { data:answer} = useQuery({
		queryKey:['quizresponse', quizsetSessionId],
		queryFn: () => getQuizresponseByQuizsetSessionId(quizsetSessionId),
		enabled: !!quizsetSessionId,
		select:data => data.data.quizresponses,
		refetchOnMount: true,
	}
	)
	return { answer }
}

export default useQuizResponseByQuizsetSessionId