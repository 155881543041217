import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { setReportLog } from "@store/actions";
import { ButtonConstants } from "constants/buttonConstants";
import { useTranslation } from "react-i18next";

const StyledModal = styled(Modal)`
  .modal-content {
    margin: auto;
    border-radius: 8px;
  }

  .modal-dialog {
    max-width: ${(props) => props.width}px;
  }

  .modal-header {
    padding: 16px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.15px;
  }
`;

const Container = styled.div`
  padding: 16px 24px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 5px 10px 5px;
`;

const BaseModal = (props) => {
  const { t } = useTranslation();

  const {
    visible,
    onHide = () => {},
    title = "",
    onCancel = () => {},
    // cancelText = "취소",
    cancelText = t("w.cancel"),
    // completeText = "저장",
    completeText = t("w.save"),
    onComplete = () => {},
    width = 354,
    hideFooter = false,
  } = props;
  const dispatch = useDispatch();
  return (
    <StyledModal show={visible} onHide={onHide} centered width={width}>
      <Modal.Header>{title}</Modal.Header>
      <Container>{props.children}</Container>
      {!hideFooter && (
        <Footer>
          <Button
            style={{
              fontWeight: 700,
              fontSize: 14,
            }}
            onClick={() => {
              onCancel();
              dispatch(setReportLog(ButtonConstants.BASEMODAL.CANCEL));
            }}
          >
            {cancelText}
          </Button>
          <Button
            style={{
              fontWeight: 700,
              fontSize: 14,
            }}
            onClick={() => {
              onComplete();
              dispatch(setReportLog(ButtonConstants.BASEMODAL.COMPLETE));
            }}
          >
            {completeText}
          </Button>
        </Footer>
      )}
    </StyledModal>
  );
};

export default BaseModal;
