import { makeFreshAllIds } from '../helpers';
import {
  PURGE_SESSION,
  GET_SESSION_SUCCESS,
  GET_SESSION_FAIL,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_FAIL,
  CREATE_SESSION_WARN,
  UPDATE_SESSION_SUCCESS,
  UPDATE_SESSION_FAIL,
  DELETE_SESSION_SUCCESS,
  DELETE_SESSION_FAIL,
  GET_SUPERVISOR_SUCCESS,
  GET_SUPERVISOR_FAIL,
} from './actionTypes';

const initialState = {
  byId: {},
  allIds: [],
};

const session = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_SESSION:
      return initialState;

    case GET_SESSION_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, ...action.sessionById },
        allIds: makeFreshAllIds(state.allIds, action.sessionAllIds),
      };

    case CREATE_SESSION_SUCCESS:
      let { data: _data, ...newSessionData } = action.session;
      return {
        ...state,
        byId: { ...state.byId, [newSessionData.sessionId]: newSessionData },
        allIds: makeFreshAllIds(state.allIds, [newSessionData.sessionId]),
      };

    case UPDATE_SESSION_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.session.sessionId]: action.session },
        allIds: makeFreshAllIds(state.allIds, [action.session.sessionId]),
      };

    case DELETE_SESSION_SUCCESS:
      var newAllIds = state.allIds.filter(
        (sessionId) => sessionId !== action.sessionId,
      );
      return { ...state, allIds: newAllIds };

    case GET_SUPERVISOR_SUCCESS:
      return state;

    // todo: handle errors
    case CREATE_SESSION_WARN:
      console.warn('[Session Reducer]', action.payload);
      return state;

    case GET_SESSION_FAIL:
    case CREATE_SESSION_FAIL:
    case UPDATE_SESSION_FAIL:
    case GET_SUPERVISOR_FAIL:
    case DELETE_SESSION_FAIL:
      // console.error('[Session Reducer]', action.payload);
      return state;
    default:
      break;
  }
  return state;
};

export default session;
