import React from "react";
import { useQuizsetSurvey } from "@pages/QuizPang/hooks/useSurvey";
import { useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Button, Container, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import SurveyCard from "@pages/FocusSurvey/SurveyCard";
import { useTranslation } from "react-i18next";

const SurveyCardWrapper = () => {
  const { groupId } = useParams();
  const user = useSelector((state) => state.user);
  const quizsetSurvey = useQuizsetSurvey(groupId, user.clientId);
  const history = useHistory();
  const { t } = useTranslation();
  if (quizsetSurvey.isLoading) {
    return (
      <Container maxW={"container.xl"}>
        <VStack
          dir="column"
          spacing={4}
          mb={8}
          alignItems={"center"}
          justifyContent={"center"}
          minHeight={"300px"}
          maxHeight={"300px"}
        >
          <Spinner />

          <Text fontSize="2xl" fontWeight="bold">
            {/* 선생님께서 배포하신 설문지를 가져오고 있어요... */}
            {t("quizpang.survey.norecord.text.reloading")}
          </Text>
        </VStack>
      </Container>
    );
  }

  if (quizsetSurvey.isError) {
    return (
      <Container maxW={"container.xl"}>
        <VStack
          dir="column"
          spacing={4}
          mb={8}
          alignItems={"center"}
          justifyContent={"center"}
          minHeight={"300px"}
          maxHeight={"300px"}
        >
          <Text fontSize="xl" fontWeight="bold">
            {/* 아직 선생님께서 배포하신 설문지가 없는 것 같아요. */}
            {t("quizpang.survey.norecord.text.notsurvey")}
          </Text>
          <Button
            colorScheme="blue"
            onClick={() => {
              history.goBack();
            }}
            w={40}
          >
            {/* 돌아가기 */}
            {t("quizpang.survey.button.back")}
          </Button>
        </VStack>
      </Container>
    );
  }

  const quizsetSurveyData = quizsetSurvey.quizsetSurvey.data;

  return (
    <SimpleGrid
      spacing={8}
      // columns={{
      //   sm: 1,
      //   md: 2,
      //   lg: 3,
      //   xl: 4,
      // }}
      templateColumns="repeat(auto-fill, minmax(240px, 1fr))"
    >
      {quizsetSurveyData.map((survey) => {
        return (
          <SurveyCard key={survey.quizset.quizsetId} surveyData={survey} />
        );
      })}
    </SimpleGrid>
  );
};

export default SurveyCardWrapper;
