import React, { useCallback, useEffect, useState } from "react";
import Sidebar from "@components/Layout/Sidebar";
import {
  ArrowBackIconContainer,
  BottomContentLayer,
  Divider,
  HeaderSection,
  HeaderSectionHeading,
  HeaderSectionRow,
  HeaderSectionRow0,
  HeaderSectionRow16,
  HorizontalSpace16,
  MainContentLayer,
  SideBarRest,
  StudentLayer,
  VerticalSpace24,
  VerticalSpace4,
} from "@app/pages/QuizPang/common";
import { useHistory, useParams } from "react-router-dom";
import { QuizsetTimeInfo } from "@app/pages/QuizPang/quizset-card";
import { Button, Stack, Typography } from "@mui/material";
import styled from "styled-components";
import { SurveyCard } from "./survey-card";
import { Row } from "antd";
import { getBackend, getQuiz, postBackend } from "@api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const QuizsetSurveyReady = () => {
  const { groupId, quizsetId, quizsetSessionId } = useParams();

  const [updateModal, setUpdateModal] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isQuizCancel, setIsQuizCancel] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [quizset, setQuizset] = useState(null);
  const [answers, setAnswers] = useState(null)

  const getQuizset = async () => {
    try {
      const {data} = await getBackend(`quizset?quizsetId=${quizsetId}`);
      setQuizset(data);
      console.log(data)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getQuizset();
  }, []);
  const startBtnClick = () => {
  };

  // useEffect(() => {
  //   if (!isQuizCancel || !isQuizsetSessionId) return;
  //   patchQuizsetSession({
  //     quizsetSessionId: isQuizsetSessionId,
  //     state: 'FINISHED',
  //   });
  //   quizEnd();
  //   finishQuiz();
  // }, [isQuizCancel]);

  // useEffect(() => {
  //   if(quizsetSession?.state === 'READY') {
  //     setIsReady(true);
  //   }
  // }, [quizsetSession])

  const history = useHistory();
  const historyBack = () => {
    history.goBack();
  };
  if (!quizset) {
    return (
      <StyledPageLayout>
        <Sidebar inClassroom />
        <SideBarRest />
      </StyledPageLayout>
    );
  }
  // console.log(quizset);

  return (
    <>
      <StyledPageLayout>
        <Sidebar inClassroom />
        <SideBarRest style={{ background: "transparent" }}>
          <MainContentLayer>
            <HeaderSectionRow16>
            <ArrowBackIconContainer onClick={historyBack}>
                <ArrowBackIcon />
              </ArrowBackIconContainer>
              <HeaderSectionHeading style={{ marginRight: 0 }}>
                {quizset?.title}
              </HeaderSectionHeading>
              <HorizontalSpace16 />
            </HeaderSectionRow16>
            <p style={{ marginLeft: "65px", marginTop: "-30px" }}>
              {quizset.subject}
            </p>
            <HeaderSectionRow16>
              {/* <Typography variant="h6">퀴즈팡 설정</Typography> */}
              <HorizontalSpace16 />
              
            </HeaderSectionRow16>
            <Divider />
            <BottomContentLayer>
              <HeaderSectionRow0>
                <Typography variant="h6">
                  {JSON.parse(quizset.quizzes).length - 1}개의 문제
                </Typography>
                <HorizontalSpace16 />
                <HorizontalSpace16 />
                <Button onClick={startBtnClick} variant="contained">
                  제출 후 뒤로가기
                </Button>
              </HeaderSectionRow0>

              <VerticalSpace24 />
              <Divider />
              <VerticalSpace24 />
              {JSON.parse(quizset.quizzes).map((x) =>
                x.quizType === "HEADLINE" ? (
                  <Container>{x.title}</Container>
                ) : (
                  <SurveyCard key={x.quizId} data={x} quizsetSessionId={quizsetSessionId} setAnswers={setAnswers}/>
                )
              )}
            </BottomContentLayer>
          </MainContentLayer>
        </SideBarRest>
      
      </StyledPageLayout>
      
    </>
  );
};

const StyledPageLayout = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`;
const Container = styled(Row)`
  flex: 1;
  border: 1px solid ${(p) => (p.isActive ? "#0894A0" : "#e0e0e0")};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
  background-color: ${(p) => (p.isActive ? "#E4F6F8" : "")};
  transition: all 0.1s ease-out;
`;