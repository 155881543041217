import React, { memo } from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveClassroom } from "@store/selectors";
// import { deleteFileLink } from "@store/actions";
import { selectGroupFileLinks } from "@store/selectors";
import styles from "./CardFileLink.module.scss";
import imgNotiIcon from "@images/home/img_notice.png";
import { useHistory } from "react-router-dom";
import moment from "moment";
import BaseCard from "./baseCard";
import { Box, Image } from "@chakra-ui/react";
import { setReportLog } from "@store/actions";
import { ButtonConstants } from "constants/buttonConstants";

const getFaviconImageUrl = (link) => {
  return `https://www.google.com/s2/favicons?sz=64&domain_url=${link}`;
};

const CardFileLink = ({ filelink }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const isImageLink =
    filelink.link.substr(filelink.link.length - 3) === "png" ||
    filelink.link.substr(filelink.link.length - 3) === "jpg" ||
    filelink.link.substr(filelink.link.length - 4) === "jpeg" ||
    filelink.link.substr(filelink.link.length - 3) === "gif";
  const dispatch = useDispatch();
  useEffect(() => {
    if (isImageLink) {
      const timer = setTimeout(() => {
        setImageLoaded(true);
      }, 1400); // 1.2초 뒤에 이미지 로드

      return () => clearTimeout(timer);
    }
  }, [filelink.link]);

  const onClick = () => {
    window.open(filelink.link, "_blank");
  };

  // console.log('filelink', filelink);

  return (
    <>
      <div
        name="CardFileLink"
        className={styles["link-list"]}
        onClick={() => {
          onClick();
          dispatch(setReportLog(ButtonConstants.CLASSROOM.FILELINK));
        }}
        style={{
          marginLeft: "8px",
        }}
      >
        <div className={styles["date"]}>
          {moment(filelink.createdAt).format("YYYY.MM.DD")}
        </div>
        <BaseCard
          radius={4}
          className={styles["card-wrapper"]}
          shadow="0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)"
        >
          <div className={styles["icon-wrap"]}>
            {isImageLink ? (
              imageLoaded ? (
                <Image
                  className={styles["icon"]}
                  src={filelink.link}
                  width={64}
                  height={64}
                />
              ) : (
                <Box>
                  <Image
                    className={styles["icon"]}
                    src={imgNotiIcon}
                    width={64}
                    height={64}
                  />
                  <div className={styles["loading"]}>이미지 로딩중</div>
                </Box>
              )
            ) : (
              <Image
                className={styles["icon"]}
                src={getFaviconImageUrl(filelink.link)}
              />
            )}
            <div>
              <div className={styles["title"]}>
                {filelink.title ?? "제목없음"}
              </div>
              <div className={styles["link"]}>{filelink.link}</div>
            </div>
          </div>
        </BaseCard>
      </div>
    </>
  );
};

export default memo(CardFileLink);
