import {
  getQuizset,
  getQuizsetServeys,
  getQuizsetSessionSurvey,
} from '@api/quizpang/quizpangApi';
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';

export const useQuizsetSurvey = (groupId, clientId) => {
  const { data: quizsetSurvey, isLoading, isError, refetch } = useQuery({
    queryKey: ['quizsetSurvey', groupId, clientId],
    queryFn: () => getQuizsetServeys(groupId, clientId),
    enabled: !!groupId && !!clientId,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  return {
    quizsetSurvey,
    isLoading,
    isError,
    refetch,
  };
};

export const useQuizset = (quizsetId) => {
  const { data: quizset, isLoading, isError, refetch } = useQuery({
    queryKey: ['quizset', quizsetId],
    queryFn: () => getQuizset(quizsetId),
    enabled: !!quizsetId,
  });

  return {
    quizset,
    isLoading,
    isError,
    refetch,
  };
};

export const useSurveySessionStudent = (groupId, clientId) => {
  const {
    data: surveySession,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['surveySession'],
    queryFn: () => getQuizsetSessionSurvey(groupId, clientId),
    select: ({ data }) => data,
    enabled: !!groupId,
    keepPreviousData: false,
  });

  return {
    surveySession,
    isLoading,
    isSuccess,
    isError,
    refetch,
  };
};
