import { makeFreshAllIds } from '../helpers';
import {
  PURGE_GROUP,
  PURGE_GROUP_TEMP,
  GET_GROUP_SUCCESS,
  GET_GROUP_FAIL,
  GET_GROUP_BY_INVITE_SUCCESS,
  GET_GROUP_BY_INVITE_FAIL,
  GET_GROUP_STUDENTS_SUCCESS,
  GET_GROUP_STUDENTS_FAIL,
  GET_GROUP_TEMP_SUCCESS,
  GET_GROUP_TEMP_FAIL,
  GET_MY_GROUPS_SUCCESS,
  GET_MY_GROUPS_FAIL,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAIL,
  FIND_GROUP_SUCCESS,
  FIND_GROUP_FAIL,
  GET_GROUP_CLIENTS_SUCCESS,
  GET_GROUP_CLIENTS_FAIL,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAIL,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAIL,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAIL,
  INVITE_STUDENTS_SUCCESS,
  INVITE_STUDENTS_FAIL,
  KICK_STUDENTS_SUCCESS,
  KICK_STUDENTS_FAIL,
  REQUEST_GROUP_SUCCESS,
  REQUEST_GROUP_FAIL,
  ACCEPT_GROUP_REQUEST_SUCCESS,
  ACCEPT_GROUP_REQUEST_FAIL,
  DECLINE_GROUP_REQUEST_SUCCESS,
  DECLINE_GROUP_REQUEST_FAIL,
  ACCEPT_GROUP_INVITE_SUCCESS,
  ACCEPT_GROUP_INVITE_FAIL,
  DECLINE_GROUP_INVITE_SUCCESS,
  DECLINE_GROUP_INVITE_FAIL,
  ACTIVATE_GROUP_SUCCESS,
  ACTIVATE_GROUP_FAIL,
  DEACTIVATE_GROUP_SUCCESS,
  DEACTIVATE_GROUP_FAIL,
  SIGN_UP_STUDENTS_SUCCESS,
  SIGN_UP_STUDENTS_FAIL,
  SIGN_UP_STUDENT_SUCCESS,
  SIGN_UP_STUDENT_FAIL,
} from './actionTypes';

/**
 * @typedef {Object} GroupsState
 * @property {{[key: string]: focuspang.Group}} byId
 * @property {string[]} allIds
 * @property {{[key: string]: focuspang.Group}} byIdTemp
 * @property {string[]} allIdsTemp
 * @property {boolean} fetchedAllGroups
 * @property {string} selectedGroupId
 * @property {focuspang.Group} decipherGroup
 */

const initialState = {
  byId: {},
  allIds: [],
  byIdTemp: {},
  allIdsTemp: [],
  // todo: temp
  fetchedAllGroups: false,
  selectedGroupId: null,
  decipherGroup: {},
};

const groups = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_GROUP:
      console.log('[Group Reducer]', 'purge group');
      return initialState;

    case PURGE_GROUP_TEMP:
      return { ...state, byIdTemp: {}, allIdsTemp: [] };

    case GET_GROUP_SUCCESS:
    case CREATE_GROUP_SUCCESS:
    case UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.group.groupId]: action.group },
        allIds: makeFreshAllIds(state.allIds, [action.group.groupId]),
      };
    case GET_GROUP_BY_INVITE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.decipherGroup.groupId]: action.decipherGroup,
        },
        allIds: makeFreshAllIds(state.allIds, [action.decipherGroup.groupId]),
        decipherGroup: action.decipherGroup,
      };
    case SIGN_UP_STUDENTS_SUCCESS:
    case SIGN_UP_STUDENT_SUCCESS:
    case KICK_STUDENTS_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.group.id]: action.group },
        allIds: makeFreshAllIds(state.allIds, [action.group.id]),
      };

    case GET_GROUP_TEMP_SUCCESS:
      return {
        ...state,
        byIdTemp: { ...state.byIdTemp, [action.group.id]: action.group },
        allIdsTemp: makeFreshAllIds(state.allIdsTemp, [action.group.id]),
      };

    case FIND_GROUP_SUCCESS:
      return {
        ...state,
        byIdTemp: action.byIdTemp,
        allIdsTemp: action.allIdsTemp,
      };

    case GET_MY_GROUPS_SUCCESS:
    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        fetchedAllGroups: true,
        byId: { ...state.byId, ...action.byId },
        allIds: makeFreshAllIds(state.allIds, action.allIds),
      };

    case GET_GROUP_CLIENTS_SUCCESS:
    case GET_GROUP_STUDENTS_SUCCESS:
    case REQUEST_GROUP_SUCCESS:
    case INVITE_STUDENTS_SUCCESS:

    case ACCEPT_GROUP_REQUEST_SUCCESS:
    case DECLINE_GROUP_REQUEST_SUCCESS:
    case ACCEPT_GROUP_INVITE_SUCCESS:
    case DECLINE_GROUP_INVITE_SUCCESS:

    case ACTIVATE_GROUP_SUCCESS:
    case DEACTIVATE_GROUP_SUCCESS:
      return state;

    // shallow delete. (note: already deleted @backend)
    // {deleted: true} is set for the deleted village object.
    // village id filtered out from allIds, but not from byId.
    case DELETE_GROUP_SUCCESS:
      delete state.byId[action.groupId];
      return {
        ...state,
        byId: { ...state.byId, [action.groupId]: action.groupId },
        allIds: state.allIds.filter((groupId) => groupId !== action.groupId),
      };

    // todo: implement error handling
    case GET_GROUP_FAIL:
    case GET_GROUP_BY_INVITE_FAIL:
    case GET_GROUP_TEMP_FAIL:
    case GET_GROUPS_FAIL:
    case GET_GROUP_CLIENTS_FAIL:
    case FIND_GROUP_FAIL:
    case CREATE_GROUP_FAIL:
    case UPDATE_GROUP_FAIL:
    case DELETE_GROUP_FAIL:
    case INVITE_STUDENTS_FAIL:
    case GET_GROUP_STUDENTS_FAIL:
    case KICK_STUDENTS_FAIL:
    case REQUEST_GROUP_FAIL:
    case ACCEPT_GROUP_REQUEST_FAIL:
    case DECLINE_GROUP_REQUEST_FAIL:
    case ACCEPT_GROUP_INVITE_FAIL:
    case DECLINE_GROUP_INVITE_FAIL:
    case ACTIVATE_GROUP_FAIL:
    case DEACTIVATE_GROUP_FAIL:
    case SIGN_UP_STUDENTS_FAIL:
    case SIGN_UP_STUDENT_FAIL:
      // console.error('[Group Reducer] ', action.payload);
      return state;

    default:
      break;
  }
  return state;
};

export default groups;
