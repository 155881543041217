import React from "react";
import styled from "styled-components";
import { Input } from "@mui/material";
import useKeyboardLogger from "hooks/useKeyboardLogger";

export const QuizInput = ({
  setShortResult,
  quizTimelimit,

  setKeyboardData,
  deviceType,
  input,
  setInput,
}) => {
  const handleKeyData = (data) => {
    console.log(data, "quiz-input");
    setKeyboardData((prevData) => [...data]);
  };

  useKeyboardLogger(handleKeyData, input, quizTimelimit);

  const handleInputChange = (text) => {
    const inputValue = text.target.value;
    setInput(inputValue);
    setShortResult(inputValue);
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Input
          placeholder='정답 입력'
          value={input}
          onChange={handleInputChange}
          style={{
            width: 480,
            backgroundColor: "#61616114",
            borderRadius: 4,
            padding: 8,
          }}
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: 1px;
  box-sizing: border-box;
`;

export default QuizInput;
