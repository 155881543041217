import { makeFreshAllIds } from '../helpers';
import {
  PURGE_QUIZENROLLMENT,
  GET_QUIZENROLLMENT_SUCCESS,
  GET_QUIZENROLLMENT_FAIL,
  CREATE_QUIZENROLLMENT_SUCCESS,
  CREATE_QUIZENROLLMENT_FAIL,
  DELETE_QUIZENROLLMENT_SUCCESS,
  DELETE_QUIZENROLLMENT_FAIL,
} from './actionTypes';

const initialState = {
  byId: {},
  allIds: [],
  quizenrollment: {},
};

const quizenrollment = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_QUIZENROLLMENT:
      return initialState;

    case GET_QUIZENROLLMENT_SUCCESS:
      return {
        ...state,
        quizenrollment: action.quizenrollmentData,
      };

    case CREATE_QUIZENROLLMENT_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.quizenrollment.quizenrollmentId]: action.quizenrollment,
        },
        allIds: makeFreshAllIds(state.allIds, [
          action.quizenrollment.quizenrollmentId,
        ]),
      };

    case DELETE_QUIZENROLLMENT_SUCCESS:
      return state;

    case GET_QUIZENROLLMENT_FAIL:
    case CREATE_QUIZENROLLMENT_FAIL:
    case DELETE_QUIZENROLLMENT_FAIL:
      // console.error('[quizenrollment Reducer] ', action.payload);
      return state;

    default:
      break;
  }
  return state;
};

export default quizenrollment;
