import { HStack, Circle, Text, VisuallyHiddenInput } from "@chakra-ui/react";

const TrueOrFalseCard = ({
  index,
  value,
  selectedChoiceId,
  setChoiceId,
  children,
}) => {
  const isChecked = selectedChoiceId === value;
  return (
    <HStack
      cursor={"pointer"}
      onClick={() => {
        setChoiceId(value);
      }}
      bgColor={isChecked ? "blue.400" : "gray.200"}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={4}
      minWidth={150}
      minHeight={90}
      borderRadius={"xl"}
      color={isChecked ? "white" : "black"}
    >
      <VisuallyHiddenInput
        name="radio"
        type="radio"
        checked={isChecked}
        onChange={(e) => {
          setChoiceId(e.target.value);
        }}
      />
      {/* <Circle
        size={'2rem'}
        bg={isChecked ? 'blue.400' : 'white'}
        textColor={isChecked ? 'white' : 'black'}
      >
        {index + 1}
      </Circle> */}

      <Text fontSize={"xl"}>{children}</Text>
    </HStack>
  );
};

export default TrueOrFalseCard;
