import { Box, Circle, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const QuizMultiple = ({ choiceDataItem, answerData, totalParticipants }) => {
  const {
    index,
    description,
    isAnswer,
    choiceId,
    chooserCount,
  } = choiceDataItem;
  const { t } = useTranslation();

  const answerMatch = answerData?.some(
    (answer) => answer.choiceId === choiceId
  );
  const studentAnswer = answerMatch && !isAnswer ? "orange" : "";
  const correctAnswerMatches =
    answerMatch && isAnswer
      ? "royalblue"
      : (isAnswer && "teal.500") || "gray.200";
  const isWrongAnswer = description === "Wrong Answer";
  if (isWrongAnswer) return null;

  const percentage = chooserCount / totalParticipants || 0;
  return (
    <Box
      borderRadius="md"
      bg="white"
      display="flex"
      gap="20px"
      alignItems="center"
      border={answerMatch || isAnswer ? "2px" : "1px"}
      borderColor={studentAnswer || correctAnswerMatches}
      m="20px"
      p="20px 10px"
    >
      <Circle bg="royalblue" w="20px" h="20px" color="white">
        {index}
      </Circle>
      <Text fontWeight="600">{description}</Text>
      <Text fontSize="12px" color="gray.500">
        {t("person", { count: chooserCount })}
        {/* {chooserCount}명 */}
        <Box as="span">({Math.round(percentage * 100)}%)</Box>
      </Text>
    </Box>
  );
};

export default QuizMultiple;
