export const PURGE_CONTROL = "PURGE_CONTROL";
export const SET_DIRTY = "SET_DIRTY";

export const SET_SELECTED_PAGE = "SET_SELECTED_PAGE";
export const SET_SELECTED_CLASS = "SET_SELECTED_CLASS";

export const SET_APPROVAL_COMPLETED = "SET_APPROVAL_COMPLETED";

export const SET_SIDEBAR_STATE = "SET_SIDEBAR_STATE";

export const SET_DEV_MODE = "SET_DEV_MODE";

export const SET_CLASSROOM_STATE = "SET_CLASSROOM_STATE";

export const SET_FILELINK_STATE = "SET_FILELINK_STATE";

export const SET_SOCKET = "SET_SOCKET";
export const SET_SOCKET_DATA = "SET_SOCKET_DATA";

export const SET_SCREEN_DATA = "SET_SCREEN_DATA";

export const SET_TEACHER_SCREEN = "SET_TEACHER_SCREEN";

export const SET_SELECTED_STUDENT = "SET_SELECTED_STUDENT";

export const RESET_SOCKET = "RESET_SOCKET";
export const RESET_SOCKET_DATA_QUEUE = "RESET_SOCKET_DATA_QUEUE";

export const NEED_SEND_REGISTER_NOTY = "NEED_SEND_REGISTER_NOTY";
export const SET_STUDENT_IMAGES = "SET_STUDENT_IMAGES";

export const SET_TEACHER_CLIENT_ID = "SET_TEACHER_CLIENT_ID";

export const SET_BIG_URI = "SET_BIG_URI";

export const CLEAR_BIG_URI = "CLEAR_BIG_URI";

export const SET_REFRESH_STATE = "SET_REFRESH_STATE";

export const SET_SELECTED_GROUP = "SET_SELECTED_GROUP";

export const SET_QUIZ_STATE = "SET_QUIZ_STATE";

export const SET_NETWORK_STATE = "SET_NETWORK_STATE";

export const CLEAR_NETWORK_STATE = "CLEAR_NETWORK_STATE";

export const SET_CAMERA_PERMISSION = "SET_CAMERA_PERMISSION";

export const SET_MICROPHONE_PERMISSION = "SET_MICROPHONE_PERMISSION";

export const SET_LOCATION_PERMISSION = "SET_LOCATION_PERMISSION";

export const SET_DEVICEMOTION_PERMISSION = "SET_DEVICEMOTION_PERMISSION";

export const SET_RECIEVED_GROUP_ID = "SET_RECIEVED_GROUP_ID";

export const SET_UPLOAD_TRIGGER = "SET_UPLOAD_TRIGGER";

export const PURGE_UPLOAD_TRIGGER = "PURGE_UPLOAD_TRIGGER";

export const SET_UPLOAD_RESPONSE = "SET_UPLOAD_RESPONSE";

export const PURGE_UPLOAD_RESPONSE = "PURGE_UPLOAD_RESPONSE";

export const SET_UPLOAD_ARCHIVE_RESPONSE = "SET_UPLOAD_ARCHIVE_RESPONSE";

export const PURGE_UPLOAD_ARCHIVE_RESPONSE = "PURGE_UPLOAD_ARCHIVE_RESPONSE";

export const SET_FOCUS_TYPE = "SET_FOCUS_TYPE";

export const PURGE_FOCUS_TYPE = "PURGE_FOCUS_TYPE";

export const SET_FOCUS_TIME_STATE = "SET_FOCUS_TIME_STATE";

export const PURGE_FOCUS_TIME_STATE = "PURGE_FOCUS_TIME_STATE";

export const SET_HAS_ANSWER = "SET_HAS_ANSWER";

export const PURGE_HAS_ANSWER = "PURGE_HAS_ANSWER";

export const SET_SURVEY_RESPONSE_OK = "SET_SURVEY_RESPONSE_OK";

export const PURGE_SURVEY_RESPONSE_OK = "PURGE_SURVEY_RESPONSE_OK";

export const SET_NETWORK_SPEED = "SET_NETWORK_SPEED";
