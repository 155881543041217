import { getBackend, patchBackend, postBackend } from "@api/Backend";

export const getQuizsetServeys = async (groupId, clientId) => {
  return await getBackend(
    `quizsetSession/survey?groupId=${groupId}&clientId=${clientId}`
  );
};

export const getQuizset = async (quizsetId) => {
  return await getBackend(`quizset?quizsetId=${quizsetId}`);
};

export const postQuizResponse = async (quizResponse) => {
  return await postBackend(`quizResponse`, quizResponse);
};

export const postQuisResponseList = async (quizResponseList) => {
  return await postBackend(`quizresponse?list=true`, quizResponseList);
};

// 설문 조회
export const getQuizsetSessionSurvey = async (groupId, clientId) => {
  return await getBackend(
    `/quizsetSession/survey?groupId=${groupId}&clientId=${clientId}`
  );
};

export const getQuizsetSessionActiveByCliendId = async (clientId) => {
  return await getBackend(`quizsetSession?clientId=${clientId}&active=true`);
};

// 퀴즈셋 생성 for a-b test
export const postQuizsetForTest = async (quizsetData) => {
  return await postBackend(`quizset`, quizsetData);
};

// 퀴즈셋 patch for a-b test
export const patchQuizsetForTest = async (quizsetData) => {
  return await patchBackend(`ab-labs/quiz`, quizsetData);
};

// 퀴즈셋 중간 결과 조회 for a-b test
export const getQuizsetSessionForTest = async (quizsetSessionId, quizOrder) => {
  return await getBackend(`
  quizsetSession/result?quizsetSessionId=${quizsetSessionId}&quizOrder=${quizOrder}
  `);
};
//팝업 데이터 저장
export const postPopupData = async (clientId, quizId, createdAt) => {
  await postBackend("ab-labs/quiz/ui-data/popups", {
    clientId,
    quizId,
    createdAt,
  });
};
//아이트래킹 데이터 저장
export const postEyeTrackingData = async (clientId, quizId, ablabsEyeData) => {
  return await postBackend("ab-labs/quiz/ui-data/eyes", {
    clientId,
    quizId,
    ablabsEyeData,
  });
};
