import { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Stack, Typography, Divider, Button, TextField } from "@mui/material";
import { selectGroupById } from "@store/selectors";
import {
  postSubmissionAxios,
  updateSubmissionAxios,
  uploadSubmission,
} from "@api";
import { Check as CheckIcon, Error as ErrorIcon } from "@mui/icons-material";
import { format } from "date-fns";
import styled from "styled-components";
import { selectGroupTeacherName } from "@store/membership/selector";
import { useTranslation } from "react-i18next";

export const AssignmentItem = ({
  groupnotice,
  submissions,
  getSubmissionByClient,
  getGroupnoticeByGroup,
}) => {
  const {
    title = "",
    body = "",
    submitAt = "",
    releaseAt = "",
    groupId,
    groupnoticeId,
  } = groupnotice;
  const { grade, groupName, subjectName } = useSelector((state) =>
    selectGroupById(state, groupId)
  );
  const teacherName = useSelector((state) =>
    selectGroupTeacherName(state, groupId)
  );

  const { clientId } = useSelector((state) => state.user);

  const submitted = submissions.filter(
    (submission) => submission.groupnotice.groupnoticeId === groupnoticeId
  );

  const expiration = new Date(submitAt) < new Date();

  const { submission = {} } = { ...submitted[0] };
  const { submittedAt = "", title: submittedTitle } = submission;

  const [show, setShow] = useState(false);
  const [submissionTitle, setSubmissionTitle] = useState("");
  const [file, setFile] = useState(null);
  const [validation, setValidation] = useState(null);

  useEffect(() => {
    if (file && submissionTitle) setValidation(false);
  }, [file, submissionTitle]);

  const createOrUpdateSubmission = useCallback(async () => {
    if (!file || !submissionTitle) return setValidation(true);
    try {
      const filelink = await uploadSubmission(file, clientId, groupnoticeId);
      const body = {
        groupnoticeId,
        clientId,
        state: "CREATED",
        title: submissionTitle,
        body: "내용없음",
        filelink,
        submittedAt: new Date(),
      };
      if (Boolean(submitted.length)) {
        updateSubmissionAxios(body);
      } else postSubmissionAxios(body);
      setShow(false);
      getSubmissionByClient();
      getGroupnoticeByGroup();
    } catch (error) {
      console.log(error);
    }
  }, [submissionTitle, file, clientId, groupnoticeId, submitted]);

  const handleChangeFile = useCallback((e) => {
    const file = e.target.files[0];
    if (file.size > 20971520) {
      alert("과제 파일은 최대 20MB 까지 등록 가능합니다.");
      return;
    }
    setFile(e.target.files[0]);
  }, []);
  const { t } = useTranslation();
  return (
    <Stack border="1px solid #E0E0E0" borderRadius="4px" p={3} spacing={3}>
      <Typography>{title}</Typography>

      <Stack direction="row" spacing={3}>
        <Typography color="#00000099" variant="body2">
          {format(new Date(releaseAt), "yyyy-MM-dd HH:mm")}
        </Typography>
        <Typography color="#00000099" variant="body2">
          {`${grade} - ${groupName} ${subjectName}`}
        </Typography>
        <Typography color="#00000099" variant="body2">
          · {teacherName}
          {/* 선생님 공지 */}
        </Typography>
      </Stack>

      <Divider />

      <Typography
        variant="body2"
        bgcolor="#F5F5F5"
        px={1}
        py={0.5}
        borderRadius="4px"
        alignSelf="flex-start"
      >
        {`${grade} - ${groupName}  `}
        {/* 제출 기한 */}
        {`${t("assignment.text.submission")}
        ${format(new Date(submitAt), "yyyy-MM-dd HH:mm")}`}
      </Typography>

      <Typography variant="body2">
        <StyledDiv dangerouslySetInnerHTML={{ __html: body }} />
      </Typography>

      {expiration && !Boolean(submitted.length) && (
        <Typography
          variant="body2"
          color="#FF1744"
          display="flex"
          alignItems="center"
        >
          <ErrorIcon fontSize="10px" sx={{ mr: 0.5 }} />
          {/* 제출 기한이 지났어요! */}
          {t("assignment.norecord.alert.submission")}
        </Typography>
      )}

      {Boolean(submitted.length) && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={1}>
            <Typography
              variant="body2"
              color="#388E3C"
              display="flex"
              alignItems="center"
            >
              <CheckIcon fontSize="10px" sx={{ mr: 0.5 }} />
              {/* 제출완료 */}
              {t("assignment.button.submit")}
            </Typography>

            <Typography variant="body2" color="#00000099">
              {format(new Date(submittedAt), "yyyy-MM-dd HH:mm")}
            </Typography>
            <Typography variant="body2" color="#00000099">
              {submittedTitle}
            </Typography>
          </Stack>

          <Button variant="contained" onClick={() => setShow(true)}>
            {/* 다시 제출 */}
            {t("assignment.button.reset")}
          </Button>
        </Stack>
      )}

      {!expiration && !show && !Boolean(submitted.length) && (
        <Button
          variant="contained"
          sx={{ alignSelf: "flex-start" }}
          onClick={() => setShow(true)}
        >
          {/* 과제 제출하기 */}
          {t("assignment.text.button")}
        </Button>
      )}

      {!expiration && show && (
        <Stack bgcolor="#FAFAFA" p={3} borderRadius="4px" spacing={3}>
          <TextField
            size="small"
            // label="과제 제목"
            label={t("assignment.text.placeholder")}
            value={submissionTitle}
            onChange={(e) => setSubmissionTitle(e.target.value)}
          />
          <Stack direction="row" spacing={3} alignItems="center">
            <Button component="label">
              {/* 과제 파일 업로드 */}
              {t("assignment.text.filesupload")}
              <input type="file" hidden onChange={handleChangeFile} />
            </Button>
            {file && (
              <Typography variant="body2" color="#00000099">
                {file.name}
              </Typography>
            )}
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            alignSelf="flex-end"
            alignItems="center"
          >
            {validation && (
              <Typography
                variant="body2"
                color="#FF1744"
                display="flex"
                alignItems="center"
              >
                <ErrorIcon fontSize="10px" sx={{ mr: 0.5 }} />
                {/* {!submissionTitle
                  ? "과제 제목을 입력해주세요"
                  : "과제를 등록해 주세요"} */}
                {!submissionTitle
                  ? t("assignment.norecord.title")
                  : t("assignment.norecord.files")}
              </Typography>
            )}
            {/* <Button onClick={() => setShow(false)}>취소</Button> */}
            <Button onClick={() => setShow(false)}>{t("w.cancel")}</Button>
            <Button variant="contained" onClick={createOrUpdateSubmission}>
              {/* 제출 완료 */}
              {t("assignment.button.submit")}
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

const StyledDiv = styled.div`
  h1,
  h2,
  h3 {
    margin-top: 0;
    margin-bottom: 8px;
    font-weight: 500;
    line-height: 1.2;
  }
  h1 {
    font-size: 39px;
  }
  h2 {
    font-size: 31px;
  }
  h3 {
    font-size: 27px;
  }
  b,
  strong {
    font-weight: bold;
  }
  ol {
    list-style: decimal;
  }
  ul {
    list-style: disc;
  }
  ol,
  ul {
    margin-top: 0;
    padding-left: 32px;
    margin-bottom: 16px;
  }
  p {
    margin-top: 0;
    margin-bottom: 16px;
  }
  a {
    color: #0d6efd;
    text-decoration: underline;
  }
`;
