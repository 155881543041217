import React from "react";
import QuizAccodionPanel from ".";
import { useSelector } from "react-redux";
import {
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

const RankingPanel = ({ leaderboards }) => {
  const user = useSelector((state) => state.user);
  const { clientId } = user;
  const isMySelf = leaderboards.some((leader) => leader.clientId === clientId);
  return (
    <QuizAccodionPanel>
      <TableContainer bg="white" borderRadius="lg" >
        <Table variant="simple">
          <TableCaption>퀴즈팡 순위</TableCaption>
          <Thead>
            <Tr>
              <Th>순위</Th>
              <Th>학번</Th>
              <Th>이름</Th>
              <Th>점수</Th>
            </Tr>
          </Thead>
          <Tbody>
            {leaderboards.map((leaderDataItem) => (
              <Tr>
                <Td>{leaderDataItem.rank}위</Td>
                <Td>{leaderDataItem.studentNumber}</Td>
                <Td>{leaderDataItem.studentName}</Td>
                <Td>{leaderDataItem.score}점</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </QuizAccodionPanel>
  );
};

export default RankingPanel;
