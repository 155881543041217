import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import BookSvg from '@images/icons/book.svg';
import { Tooltip } from '@mui/material';

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-radius: 8px;
  background: ${(props) => (props.active ? '#E4F6F8' : '#fff')};
  :hover {
    background: ${(props) =>
      props.active ? 'rgba(5, 134, 146, 0.15)' : '#E4F6F8'};
  }
`;
const Icon = styled.img`
  width: 24px;
  height: 24px;
`;
const GroupSubject = styled.div`
  color: ${(props) => (props.active ? '#058692' : '#757575')};
  font-size: 14px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const GroupTeacher = styled.div`
  width: 78px;
  background-color: #eeeeee;
  color: ${(props) => (props.active ? '#058692' : '#757575')};
  font-size: 14px;
  padding: 4px;
  border-radius: 4px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const SidebarGroupTab = ({ group, findClientName, active = false }) => {
  const history = useHistory();
  return (
    <Box
      key={group.groupId}
      icon="book-open-variant"
      text={group.subjectName}
      onClick={() => {
        history.replace(`/managing/${group.groupId}`);
      }}
      active={active}
    >
      <Icon src={BookSvg} alt="BookSvg" />
      <Tooltip title={group.subjectName} arrow>
        <GroupSubject active={active}>{group.subjectName}</GroupSubject>
      </Tooltip>
      <Tooltip title={findClientName(group.groupId)} arrow>
        <GroupTeacher>{findClientName(group.groupId)}선생님</GroupTeacher>
      </Tooltip>
    </Box>
  );
};

export default SidebarGroupTab;
