import { makeFreshAllIds } from '../helpers';
import {
  PURGE_KAKAOCERT,
  REQUEST_CERT_SUCCESS,
  REQUEST_CERT_FAIL,
  CHECK_RESPONSE_SUCCESS,
  CHECK_RESPONSE_FAIL,
} from './actionTypes';

const initialState = {
  receiptId: '',
  status: 0,
  signed: false,
};

/*
  const decipherGroup = useSelector((state) => state.invite.decipherGroup);
  const decipherSchool = useSelector((state) => state.invite.decipherSchool); 
*/

const kakaocert = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_KAKAOCERT:
      return initialState;
    case REQUEST_CERT_SUCCESS:
      return { ...state, receiptId: action.payload.receiptId };
    case CHECK_RESPONSE_SUCCESS:
      return {
        ...state,
        status: action.payload.state,
        signed: action.payload?.signed,
      };
    case REQUEST_CERT_FAIL:
    case CHECK_RESPONSE_FAIL:
      // console.error('[Kakaocert Reducer] ', action.payload);
      return state;
    default:
      break;
  }
  return state;
};

export default kakaocert;
