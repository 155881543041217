import { makeFreshAllIds } from '../helpers';
import {
  PURGE_ANSWER,
  GET_ANSWERS_SUCCESS,
  GET_ANSWERS_FAIL,
  GET_ANSWER_CONTENT_SUCCESS,
  GET_ANSWER_CONTENT_FAIL,
  CREATE_SURVEY_ANSWER_SUCCESS,
  CREATE_SURVEY_ANSWER_FAIL,
  CREATE_SURVEY_ANSWERS_SUCCESS,
  CREATE_SURVEY_ANSWERS_FAIL,
  CREATE_QNA_ANSWER_SUCCESS,
  CREATE_QNA_ANSWER_FAIL,
  UPDATE_ANSWER_SUCCESS,
  UPDATE_ANSWER_FAIL,
  DELETE_ANSWER_SUCCESS,
  DELETE_ANSWER_FAIL,
  UPLOAD_ANSWER_SUCCESS,
  UPLOAD_ANSWER_FAIL,
} from './actionTypes';

const initialState = {
  byId: {},
  allIds: [],
};

const answer = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_ANSWER:
      return initialState;

    case GET_ANSWERS_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
        allIds: makeFreshAllIds(state.allIds, action.allIds),
      };

    case CREATE_QNA_ANSWER_SUCCESS:
    case CREATE_SURVEY_ANSWER_SUCCESS:
    case UPDATE_ANSWER_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.answer.answerId]: action.answer },
        allIds: makeFreshAllIds(state.allIds, [action.answer.answerId]),
      };

    case GET_ANSWER_CONTENT_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.answerId]: {
            ...state.byId[action.answerId],
            content: action.content,
          },
        },
      };

    case CREATE_SURVEY_ANSWERS_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
        allIds: makeFreshAllIds(state.allIds, action.allIds),
      };

    case UPLOAD_ANSWER_SUCCESS:
      return state;

    case DELETE_ANSWER_SUCCESS:
      return {
        ...state,
        allIds: [
          ...state.allIds.filter((answerId) => answerId != action.answerId),
        ],
      };

    // todo: handle errors
    case GET_ANSWERS_FAIL:
    case GET_ANSWER_CONTENT_FAIL:
    case CREATE_SURVEY_ANSWER_FAIL:
    case CREATE_SURVEY_ANSWERS_FAIL:
    case CREATE_QNA_ANSWER_FAIL:
    case UPDATE_ANSWER_FAIL:
    case UPLOAD_ANSWER_FAIL:
    case DELETE_ANSWER_FAIL:
      // console.error('[Answer Reducer] ', action.payload);
      return state;
    default:
      break;
  }
  return state;
};

export default answer;
