import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getQuizChoiceByQuizIdPromise,
  getQuizsetByQuizsetIdPromise,
} from "@app/store/actions";
import { useParams, useHistory } from "react-router-dom";

import { getQuizsetSessionPromise } from "@store/actions";

const QuizReadyStart = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { quizsetSessionId } = useParams();

  useEffect(() => {
    if (!quizsetSessionId) {
      return;
    }
    // fetch quizset data
    dispatch(getQuizsetSessionPromise(quizsetSessionId))
      .then((res) => dispatch(getQuizsetByQuizsetIdPromise(res?.quizsetId)))
      .then((res) => {
        const quizIds = JSON.parse(res.quizzes).map((x) => x.quizId);
        return Promise.all(
          quizIds.map((x) => dispatch(getQuizChoiceByQuizIdPromise(x)))
        );
      })
      .then((res) => {
        if (res) {
          console.log("[QuizBefore]", res);
          setTimeout(
            () => history.replace(`/inquizpang/session/${quizsetSessionId}/1`),
            500
          );
          // extra navigation might happen
          global.eventEmitter.emit("QUIZ_READY");
        }
      });
  }, [quizsetSessionId, dispatch, history]);

  return null;
};

export default QuizReadyStart;
