import { makeFreshAllIds } from '../helpers';
import {
  PURGE_CLASSROOM,
  GET_CLASSROOM_SUCCESS,
  GET_CLASSROOM_FAIL,
  GET_ACTIVE_CLASSROOM_SUCCESS,
  GET_ACTIVE_CLASSROOM_FAIL,
  GET_ACTIVE_CLASSROOM_BY_GROUP_SUCCESS,
  GET_ACTIVE_CLASSROOM_BY_GROUP_FAIL,
  DELETE_CLASSROOM_SUCCESS,
  DELETE_CLASSROOM_FAIL,
  SET_ACTIVE_CLASSROOM_EXIST,
  SET_ACTIVE_CLASSROOM_NOT_EXIST,
} from './actionTypes';

/**
 * @typedef {Object} ClassroomState
 * @property {{[key: string]: focuspang.Classroom}} byId
 * @property {string[]} allIds
 * @property {boolean} fetchedAllGroups
 * @property {boolean} selectedGroupId
 * @property {boolean} isClassSession
 * @property {focuspang.Classroom} classSession
 */

const initialState = {
  byId: {},
  allIds: [],
  fetchedAllGroups: false,
  selectedGroupId: null,
  isClassSession: false,
  classSession: null,
};

const classrooms = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_CLASSROOM:
      return initialState;

    case GET_CLASSROOM_SUCCESS:
      const activeClassroom =
        action.classroom.state === 'IN_CLASS'
          ? { isClassSession: true, classSession: action.classroom }
          : {};
      return {
        ...state,
        ...activeClassroom,
        byId: {
          ...state.byId,
          [action.classroom.classroomId]: action.classroom,
        },
        allIds: makeFreshAllIds(state.allIds, [action.classroom.classroomId]),
      };

    case DELETE_CLASSROOM_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.classroom.classroomId]: action.classroom,
        },
        allIds: state.allIds.filter(
          (classroomId) => classroomId !== action.classroom.classroomId,
        ),
      };

    case GET_ACTIVE_CLASSROOM_SUCCESS:
      return {
        ...state,
        isClassSession: true,
        classSession: action.classroom,
        participation: action.participation,
      };

    case GET_ACTIVE_CLASSROOM_BY_GROUP_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.classroom]: action.classroom },
        allIds: makeFreshAllIds(state.allIds, [action.classroom]),
        isClassSession: true,
        classSession: action.classroom,
      };

    case GET_CLASSROOM_FAIL:
    case DELETE_CLASSROOM_FAIL:
      // console.error('[Classroom Reducer] ', action.payload);
      return state;
    case GET_ACTIVE_CLASSROOM_FAIL:
    case GET_ACTIVE_CLASSROOM_BY_GROUP_FAIL:
      // todo only exception for 404 Not found
      return state;

    case SET_ACTIVE_CLASSROOM_NOT_EXIST:
      return {
        ...state,
        byId: {},
        allIds: [],
        isClassSession: false,
        classSession: null,
      };

    default:
      break;
  }
  return state;
};

export default classrooms;
