import { Button, Container, Spinner, Text, VStack } from "@chakra-ui/react";
import React from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import SurveySession from "./SurveySession";
import { useQuizset } from "@pages/QuizPang/hooks";
import { useSelector } from "react-redux";
import quizset from "@store/quizset/reducer";

const SurveyWrapper = ({
  PropsGroupId,
  PropsQuizsetId,
  PropsQuizsetSessionId,
  type,
  currentIndex,
  setCurrentIndex,
  surveys,
}) => {
  const { groupId, quizsetId, quizsetSessionId } = useParams();
  const user = useSelector((state) => state.user);
  const history = useHistory();

  const getQuizset = useQuizset(PropsQuizsetId ? PropsQuizsetId : quizsetId);
  console.log("SURVEY-WRAPPER 111", getQuizset);
  if (getQuizset.isLoading) {
    return (
      <Container maxW={"container.xl"} p={8}>
        <VStack
          dir="column"
          spacing={4}
          mb={8}
          alignItems={"center"}
          justifyContent={"center"}
          minHeight={"300px"}
          maxHeight={"300px"}
        >
          <Spinner />
          <Text fontSize="2xl" fontWeight="bold">
            작성할 설문지를 가져오고 있어요...
          </Text>
        </VStack>
      </Container>
    );
  }

  if (getQuizset.isError) {
    return (
      <Container maxW={"container.xl"} p={8}>
        <VStack
          dir="column"
          spacing={4}
          mb={8}
          alignItems={"center"}
          justifyContent={"center"}
          minHeight={"300px"}
          maxHeight={"300px"}
        >
          <Text fontSize="2xl" fontWeight="bold">
            작성할 설문지를 가져오는데 실패했어요.
          </Text>
          <Button
            onClick={() => {
              history.push(`/focussurvey/${groupId}`);
            }}
          >
            설문지 목록으로 돌아가기
          </Button>
        </VStack>
      </Container>
    );
  }

  // const quizCount = getQuizset.quizset.quizCount;
  const quizsetType = getQuizset.quizset.quizsetType;
  const quizTitle = getQuizset.quizset.title;
  const quizzes = getQuizset.quizset.quizzes;
  //quizCount with HeadLine if Headline type exists
  const quizCount = quizzes.length;
  return (
    <Container maxW={"container.xl"}>
      <SurveySession
        type={type}
        groupId={groupId}
        quizsetId={PropsQuizsetId ? PropsQuizsetId : quizsetId}
        quizsetSessionId={
          PropsQuizsetSessionId ? PropsQuizsetSessionId : quizsetSessionId
        }
        quizCount={quizCount}
        quizsetType={quizsetType}
        quizTitle={quizTitle}
        quizzes={quizzes}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        surveys={surveys}
      />
    </Container>
  );
};

export default SurveyWrapper;
