import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import InviteCodeJoinUpForm from "@components/Forms/inviteCodeJoinUpForm";
import { useTranslation } from "react-i18next";

function InviteCodeJoinUp({ showInvite, setShowInvite }) {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [infos, setInfos] = useState({
    code: "",
    studentNumber: "",
    name: "",
  });
  const handleChange = (e) => {
    setInfos({
      ...infos,
      [e.target.name]: e.target.value,
    });
  };

  switch (step) {
    case 1:
      return (
        <Modal
          show={showInvite}
          onHide={() => {
            setStep(1);
            setInfos({ code: "", studentNumber: "", name: "" });
            setShowInvite(false);
          }}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-lg">
              {/* 수업 추가하기 */}
              {t("timetable.addcourse.modal.title")}
            </Modal.Title>
          </Modal.Header>
          <div style={{ padding: 20 }}>
            {/* <div>선생님께 전달 받은 초대코드를 입력해주세요</div> */}
            <div>{t("timetable.addcourse.modal.description")}</div>
            <br />
            <InviteCodeJoinUpForm
              setShowInvite={setShowInvite}
              handleChange={handleChange}
              values={infos}
            />
          </div>
        </Modal>
      );

    default:
    //초대코드
  }
}

export default InviteCodeJoinUp;
