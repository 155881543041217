export const SAVE_GPS_DATA = 'SAVE_GPS_DATA';
export const DELETE_GPS_DATA = 'DELETE_GPS_DATA';
export const CLEAR_GPS_DATA = 'CLEAR_GPS_DATA';

export const SAVE_TOUCH_DATA = 'SAVE_TOUCH_DATA';
export const DELETE_TOUCH_DATA = 'DELETE_TOUCH_DATA';
export const CLEAR_TOUCH_DATA = 'CLEAR_TOUCH_DATA';

export const SAVE_KEYBOARD_DATA = 'SAVE_KEYBOARD_DATA';
export const DELETE_KEYBOARD_DATA = 'DELETE_KEYBOARD_DATA';
export const CLEAR_KEYBOARD_DATA = 'CLEAR_KEYBOARD_DATA';

export const SAVE_STYLUS_DATA = 'SAVE_STYLUS_DATA';
export const DELETE_STYLUS_DATA = 'DELETE_STYLUS_DATA';
export const CLEAR_STYLUS_DATA = 'CLEAR_STYLUS_DATA';

export const SAVE_ACCELGYRO_DATA = 'SAVE_ACCELGYRO_DATA';
export const DELETE_ACCELGYRO_DATA = 'DELETE_ACCELGYRO_DATA';
export const CLEAR_ACCELGYRO_DATA = 'CLEAR_ACCELGYRO_DATA';

export const SAVE_EYETRACKING_DATA = 'SAVE_EYETRACKING_DATA';
export const DELETE_EYETRACKING_DATA = 'DELETE_EYETRACKING_DATA';
export const CLEAR_EYETRACKING_DATA = 'CLEAR_EYETRACKING_DATA';

