import { useState, useEffect, useCallback } from "react";
import { Stack, Typography, Divider } from "@mui/material";
import SidebarClassRoom from "@components/Layout/SidebarClassRoom";
import BaseCard from "@components/Card/baseCard";
import { AssignmentItem } from "./AssignmentItem";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getGroupnoticeByGroupAxios, getSubmissionByClientAxios } from "@api";
import { useTranslation } from "react-i18next";

export const Assignment = () => {
  const { groupId } = useParams();
  const { clientId } = useSelector((state) => state.user);
  const [groupnotices, setGroupnotices] = useState([]);
  const [submissions, setSubmissions] = useState([]);

  useEffect(() => {
    getGroupnoticeByGroup();
    getSubmissionByClient();
  }, []);

  const getSubmissionByClient = useCallback(async () => {
    try {
      const response = getSubmissionByClientAxios(clientId).then((res) => {
        setSubmissions(res.data.groupnoticeSubmissions);
      });
    } catch (error) {}
  }, []);

  const getGroupnoticeByGroup = useCallback(async () => {
    try {
      const response = getGroupnoticeByGroupAxios(groupId).then((res) => {
        const submissions = res.data.groupnotices.filter(
          (groupnotice) => groupnotice.noticeType === "SUBMISSION"
        );
        const sorted = submissions.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setGroupnotices(sorted);
      });
    } catch (error) {
      setGroupnotices([]);
    }
  }, []);
  const { t } = useTranslation();
  return (
    <>
      <SidebarClassRoom inClass />
      <Stack ml="280px" p="14px">
        <BaseCard style={{ minHeight: "100vh" }}>
          <Stack px="40px" py="20px" minHeight="100%" spacing={3}>
            <Stack direction="row" alignItems="center" spacing={3}>
              {/* <Typography variant='h6'>과제</Typography> */}
              <Typography variant="h6">{t("assignment.text.title")}</Typography>
            </Stack>
            <Divider />
            {groupnotices.map((groupnotice) => (
              <AssignmentItem
                key={groupnotice.groupnoticeId}
                groupnotice={groupnotice}
                submissions={submissions}
                getSubmissionByClient={getSubmissionByClient}
                getGroupnoticeByGroup={getGroupnoticeByGroup}
              />
            ))}
          </Stack>
        </BaseCard>
      </Stack>
    </>
  );
};
