import { makeFreshAllIds } from '../helpers';
import {
  PURGE_SCHOOL,
  GET_SCHOOL_SUCCESS,
  GET_SCHOOL_FAIL,
  GET_SCHOOL_BY_INVITE_CODE_SUCCESS,
  GET_SCHOOL_BY_INVITE_CODE_FAIL,
  GET_ALL_SCHOOLS_SUCCESS,
  GET_ALL_SCHOOLS_FAIL,
  GET_FILTERED_SCHOOLS_SUCCESS,
  GET_FILTERED_SCHOOLS_FAIL,
} from './actionTypes';

/**
 * define type
 * "schoolId": "dbf66a7d-a26a-4802-a958-de1fbdbf637f",
"name": "포커스팡학교",
"engName": "focuspangshcool",
"schoolType": "학교",
"address": "서울대학교",
"founder": "공립",
"code": "0",
"maxStudentAccount": 800,
"maxTeacherAccount": 200
 */

/**
 * @typedef {Object} SchoolsState
 * @property {{[key: string]: focuspang.School}} byId
 * @property {string[]} allIds
 * @property {{[key: string]: focuspang.School}} byIdFilter
 * @property {string[]} allFilteredIds
 * @property {boolean} fetchedAllSchools
 * @property {string} [selectedSchoolId]
 * @property {focuspang.School} decipherSchool
 */

const initialState = {
  byId: {},
  allIds: [],
  byIdFilter: {},
  allFilteredIds: [],
  // todo: temp
  fetchedAllSchools: false,
  selectedSchoolId: null,
  decipherSchool: {},
};

const schools = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_SCHOOL:
      return initialState;

    case GET_SCHOOL_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.school.schoolId]: action.school },
        allIds: makeFreshAllIds(state.allIds, [action.school.schoolId]),
      };

    case GET_SCHOOL_BY_INVITE_CODE_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.school.schoolId]: action.school },
        allIds: makeFreshAllIds(state.allIds, [action.school.schoolId]),
        decipherSchool: action.school,
      };

    case GET_ALL_SCHOOLS_SUCCESS:
      return {
        ...state,
        fetchedAllGroups: true,
        byId: action.byId,
        allIds: action.allIds,
      };

    case GET_FILTERED_SCHOOLS_SUCCESS:
      return {
        ...state,
        byIdFilter: action.byIdFilter,
        allFilteredIds: action.allFilteredIds,
      };

    case GET_SCHOOL_FAIL:
    case GET_SCHOOL_BY_INVITE_CODE_FAIL:
    case GET_ALL_SCHOOLS_FAIL:
    case GET_FILTERED_SCHOOLS_FAIL:
      // console.error('[School reducer]', action.payload);
      return state;

    default:
      break;
  }
  return state;
};

export default schools;
