import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  createReportLogPromise,
  purgeReportLog,
  setReportClientId,
  setReportErrorLog,
  setReportLocationLog,
} from "@store/actions";
import useUserAgent from "utils/deviceInfo/useUserAgent";
import { useInterval } from "./utils";
const EventLogManager = (props) => {
  const clientId = useSelector((state) => state.user.clientId);
  const location = useLocation();
  const locationHistory = useRef([]);
  const reports = useSelector((state) => state.reportLog.reports);
  const dispatch = useDispatch();
  const { os: osType } = useUserAgent();
  const uploadData = {
    clientId,
    osType,
    reports,
  };
  const sendAndInitReport = (uploadData, location) => {
    dispatch(createReportLogPromise(uploadData));
    dispatch(purgeReportLog());
    dispatch(setReportLocationLog(location.pathname));
  };
  //clientId
  useEffect(() => {
    dispatch(setReportClientId(clientId));
  }, []);
  // send report
  useInterval(() => {
    sendAndInitReport(uploadData, location);
  }, 5 * 60 * 1000);

  //recent page log
  useEffect(() => {
    const prevLocation = locationHistory.current.pop();
    locationHistory.current.push(location.pathname);
    if (prevLocation !== location.pathname) {
      dispatch(setReportLocationLog(location.pathname));
    }
  }, [location]);

  // error log
  useEffect(() => {
    const originalConsoleError = console.error;
    console.error = function() {
      const axiosError = [...arguments].filter((elem) => elem.isAxiosError)[0];
      const domException = [...arguments].filter(
        (elem) => elem instanceof DOMException
      )[0];
      if (axiosError) {
        const errorContent = {
          message: axiosError.message,
          code: axiosError.code,
          method: axiosError.config.method,
          baseURL: axiosError.config.baseURL,
          url: axiosError.config.url,
          payload:
            axiosError.config.method === "post"
              ? JSON.parse(axiosError.config.data)
              : "",
        };

        dispatch(setReportErrorLog(JSON.stringify(errorContent)));
      }
      // string으로만 이루어진 에러 배열이면
      else if (areAllElementsStrings([...arguments])) {
        const errorContent = [...arguments].reduce(
          (prevStr, currStr) => prevStr + " " + currStr
        );
        dispatch(setReportErrorLog(errorContent));
      }
      // DOM Exception
      else if (domException) {
        const errorMessage = [...arguments]
          .filter((elem) => typeof elem === "string")
          .reduce((prev, cur) => prev + " " + cur);
        const errorContent = {
          message: `DOMException ${domException.message} ${errorMessage}`,
          code: domException.code,
          name: domException.name,
        };
        dispatch(setReportErrorLog(JSON.stringify(errorContent)));
      } else {
        // 기타 에러
        dispatch(setReportErrorLog(JSON.stringify(arguments)));
      }
      originalConsoleError(...arguments);
    };
    return () => {
      console.error = originalConsoleError;
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      sendAndInitReport(uploadData, location);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
};

export default EventLogManager;

function areAllElementsStrings(arr) {
  for (let i = 0; i < arr.length; i++) {
    if (typeof arr[i] !== "string") {
      return false;
    }
  }
  return true;
}
