import React from "react";
import styled from "styled-components";
import { Input } from "@mui/material";
import useKeyboardLogger from "hooks/useKeyboardLogger";

export const QuizLongInput = ({
  setLongResult,
  setKeyboardData,
  deviceType,
  input,
  setInput,
  quizTimelimit,
  timer,
  quizId,
}) => {
  const handleKeyData = (data) => {
    console.log(data, "quiz-input");
    setKeyboardData((prevData) => [...data]);
  };

  useKeyboardLogger(handleKeyData, input, quizTimelimit, timer, quizId);

  const handleInputChange = (text) => {
    const inputValue = text.target.value;
    setInput(inputValue);
    setLongResult(inputValue);
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div style={{ justifyContent: "center", display: "flex" }}>
          <Input
            onChange={handleInputChange}
            style={{
              width: 700,
              height: 200,
              backgroundColor: "#61616114",
              borderRadius: 8,
              padding: 16,
              textAlignVertical: "top",
              fontSize: 16,
            }}
            placeholderTextColor="#00000099"
            placeholder="서술형 입력"
            multiline={true}
            value={input}
          />
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: 1px;
  box-sizing: border-box;
`;
