import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  registerStudentPromise,
  signInStudentPromise,
  getActiveClassroomByGroupPromise,
  createParticipationPromise,
  setSocketData,
} from "@store/actions";
import { socketKind, socketMethod, socketUrl } from "constants/socketConstants";
import { Box, Button, HStack, Input, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const RestInfoForm = ({
  prevStep,
  handleChange,
  values,
  setModalShowFalse,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const decipherGroup = useSelector((state) => state.groups.decipherGroup);
  const decipherSchool = useSelector((state) => state.schools.decipherSchool);
  const [loading, setLoading] = useState(false);
  // const nameRegex = /^[가-힣]+$/;
  const numberRegex = /^\d{5,5}$/;

  useEffect(() => {
    console.log("deciphered : ", decipherGroup, decipherSchool);
    if (
      Object.keys(decipherGroup).length === 0 ||
      Object.keys(decipherSchool).length === 0
    ) {
      // alert("올바르지 않은 초대 코드예요. 다시 확인해주세요");
      alert(t("login.Info.modal.alert.notcode"));
      prevStep();
      return;
    }
  }, []);

  const signIn = async (e) => {
    if (numberRegex.test(values.studentNumber) == false) {
      setLoading(false);
      // alert("5자리 올바른 학번을 입력해주세요.");
      alert(t("login.Info.modal.alert.correctnumber"));
    } else {
      setLoading(true);
      dispatch(
        registerStudentPromise({
          //임시로 signin 대신 register 사용하기
          schoolId: decipherSchool.schoolId,
          groupId: decipherGroup.groupId,
          userName: values.name.trim(),
          studentNumber: values.studentNumber,
          userId: "",
          password: "0000",
        })
      ).then((response) => {
        console.log("[register student]", response);
        if (!response) {
          dispatch(
            signInStudentPromise({
              groupId: decipherGroup.groupId,
              studentNumber: values.studentNumber,
              userName: values.name.trim(),
            })
          ).catch((err) => {
            setLoading(false);
            // alert("로그인 실패");
            alert(t("login.Info.modal.alert.loginfail"));
            console.log("[signIn Error]", err);
          });

          return;
        }
        dispatch(
          setSocketData({
            method: "POST",
            uri: "/classroom/sendImage",
            groupId: decipherGroup.groupId,
            clientId: user.clientId,
            type: "JOIN_GROUP",
            data: "",
          })
        );

        dispatch(getActiveClassroomByGroupPromise(decipherGroup.groupId)).then((
          /** @type {import('@store/classrooms/reducer').ClassroomSession} */ activeClassroomSession
        ) => {
          if (!activeClassroomSession) {
            console.log(
              "[restInfoForm]: 현재 수업 중인 수업이 존재합니다.",
              activeClassroomSession
            );
            dispatch(
              createParticipationPromise({
                classroomId: activeClassroomSession?.classroomId,
                clientId: user.clientId,
                attendedAt: new Date().toISOString(),
              })
            ).then((e) => {
              dispatch(
                setSocketData({
                  method: socketMethod.POST,
                  uri: socketUrl.classroomSendImage,
                  groupId: decipherGroup.groupId,
                  clientId: user.clientId,
                  type: socketKind.classroom.ATTEND_CLASS,
                  data: "",
                })
              );
            });
          }
        });
      });
    }
    e.preventDefault();
  };

  return (
    <Box p={4}>
      <form onSubmit={signIn}>
        <Stack spacing={2} direction={"column"}>
          <Text fontSize="xl" fontWeight="bold" color="gray.600">
            {decipherSchool.name +
              " " +
              // decipherGroup.grade +
              // "학년 "
              `${t("w.grade", { count: decipherGroup.grade, ordinal: true })}` +
              // decipherGroup.groupName +
              // "반"
              `  ${t("w.class", {
                count: decipherGroup.groupName,
              })}`}
          </Text>
          <HStack spacing={2}>
            <Input
              type="text"
              name="studentNumber"
              // placeholder="학번 5자리"
              placeholder={t("login.Info.modal.placeholder.Studentnumber")}
              required
              value={values.studentNumber}
              onChange={handleChange}
              maxLength="5"
              w={200}
            />

            <Input
              type="text"
              name="name"
              // placeholder="이름"
              placeholder={t("login.Info.modal.placeholder.name")}
              required
              value={values.name}
              onChange={handleChange}
              maxLength="25"
              w={200}
            />
            {loading ? (
              // <Button isLoading>로그인중</Button>
              <Button isLoading>{t("login.Info.modal.loading")}</Button>
            ) : (
              <Button type="submit" name="submit" onClick={setModalShowFalse}>
                {t("login.Info.modal.button ")}
              </Button>
            )}
          </HStack>
        </Stack>
      </form>
    </Box>
  );
};

export default RestInfoForm;
