import styled from 'styled-components'
import { ReactComponent as ArrowLeftImg } from '@assets/images/icons/arrow-left.svg';
import { ReactComponent as ArrowRightImg } from '@assets/images/icons/arrow-right.svg';

const DatePrevBtn = styled.button`
  width: 20px;
  height: 20px;
  border: 1px solid #212120;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Date = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 10px;  
  color: #212120;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.25px;
`
const DateNextBtn = styled.button`
  width: 20px;
  height: 20px;
  border: 1px solid #212120;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AttendanceDetailDatePicker = ({ year, month, date, setDate }) => {
  return (
    <>
      <DatePrevBtn onClick={() => {
        setDate(-1)
      }}>
        <ArrowLeftImg />
      </DatePrevBtn>
      <Date>{year}년 {month}월 {date}일</Date>
      <DateNextBtn onClick={() => {
        setDate(1)
      }}>
        <ArrowRightImg />
      </DateNextBtn>
    </>
  )
}

export default AttendanceDetailDatePicker