import { useState } from 'react';
import { Btn, ContentsBody, ContentsHeader, DateContainer, BackBtn } from './index.module';
import { ReactComponent as BackImg } from '@images/icons/back.svg';
import { ReactComponent as SettingWhiteImg } from '@images/icons/setting-white.svg';
import AttendanceDetailList from './AttendanceDetailList';
import AttendanceDetailDatePicker from './AttendanceDetailDatePicker';
import AttendanceStateChangePopup from './AttendanceStateChangePopup';

const AttendanceDetail = ({ grade, _class, year, month, date, setDate, onShowChangeAttendanceState, back }) => {
  const [showChangeAttendanceStatePopup, setShowChangeAttendanceStatePopup] = useState(false);

  return (
    <>
      <ContentsHeader>
        <BackBtn onClick={back} >
          <BackImg />
          뒤로가기
        </BackBtn>
        <DateContainer>
          <AttendanceDetailDatePicker year={year}
                                      month={month}
                                      date={date}
                                      setDate={setDate}
                                      onShowChangeAttendanceState={onShowChangeAttendanceState}
          />
        </DateContainer>
      </ContentsHeader>
      <ContentsBody>
        <AttendanceDetailList onShowChangeAttendanceStatePopup={() => { setShowChangeAttendanceStatePopup(true) }} />
      </ContentsBody>

      {
        // 출석 상태 변경 팝업
        showChangeAttendanceStatePopup && (
          <AttendanceStateChangePopup confirm={() => { setShowChangeAttendanceStatePopup(false) }}
                                      cancel={() => { setShowChangeAttendanceStatePopup(false) }}
          />
        )
      }
    </>
  )
}

export default AttendanceDetail