import React, { useEffect, useState } from "react";
import { createQuizresponse, getQuizChoiceByQuizId } from "@api";
import {
  Box,
  Image,
  Spinner,
  Stack,
  Text,
  VStack,
  RadioGroup,
  CheckboxGroup,
} from "@chakra-ui/react";
import {
  purgeHasAnswer,
  purgeSurveyResponseOk,
  purgeUploadResponse,
  purgeUploadTrigger,
  setHasAnswer,
  setSurveyResponseOk,
  setUploadResponse,
} from "@store/actions";
import { useQuery, useMutation } from "@tanstack/react-query";

import { useDispatch, useSelector } from "react-redux";

import RadioCard from "./RadioCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TrueOrFalseCard from "./TrueOrFalseCard";
import useCreateQuizResponse from "./useCreateQuizResponse";
import SurveyLoading from "./SurveyLoading";

const SurveyTrueOrFalse = ({
  quizId,
  quizsetSessionId,
  link,
  linkType,
  title,
  isLastQuiz,
  groupId,
  quiz,
  setQuizEnded,
  type,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const uploadTrigger = useSelector((state) => state.control.uploadTrigger);
  const [choiceId, setChoiceId] = useState(null);

  const { data: choices, isLoading: queryIsLoading, isError } = useQuery({
    queryKey: ["surveyChoice", quizId],
    queryFn: () => {
      try {
        const choices = getQuizChoiceByQuizId(quizId).then((r) => {
          return r.data.choices;
        });
        return choices;
      } catch (error) {
        console.error(error);
        // error 처리
      }
    },
  });

  const { mutate: mutateResponse, isLoading: mutateIsLoading } = useMutation(
    (quizResponse) => {
      try {
        createQuizresponse(quizResponse).then((r) => {
          setChoiceId(null);
          dispatch(purgeUploadTrigger());
          dispatch(purgeHasAnswer());
          dispatch(purgeUploadResponse());
          setQuizEnded(false);
          dispatch(setSurveyResponseOk());

          if (type !== "PACKAGE" && isLastQuiz) {
            dispatch(purgeSurveyResponseOk());
            alert("제출이 완료되었습니다!");
            history.push(`/focussurvey/${groupId}`);
          } else if (type === "PACKAGE" && isLastQuiz) {
            alert("제출이 완료되었습니다!");
          }
        });
      } catch (error) {
        setChoiceId(null);
        dispatch(purgeUploadTrigger());
        dispatch(purgeHasAnswer());
        dispatch(purgeUploadResponse());
        console.log(error);
        alert("제출에 실패했습니다.");
      }
    }
  );

  //정답 비어있는 지 확인
  if (choiceId !== null) {
    dispatch(setHasAnswer());
  } else {
    dispatch(purgeHasAnswer());
  }

  const handleCreateQuizresponse = async () => {
    const quizresponse = {
      quizsetSessionId: quizsetSessionId,
      choiceId: choiceId,
      clientId: user.clientId,
    };
    mutateResponse(quizresponse);
  };

  useCreateQuizResponse(uploadTrigger, handleCreateQuizresponse);

  if (uploadTrigger) {
    return <SurveyLoading />;
  }
  return (
    <Stack direction={"column"} spacing={4} mb={8}>
      {title !== "" && (
        <Box
          border="1px"
          borderColor="gray.200"
          borderRadius={"lg"}
          p={8}
          overflow={"auto"}
          maxHeight={"200px"}
        >
          <Text fontSize="xl" fontWeight="bold">
            {title}
          </Text>
        </Box>
      )}
      {linkType === "IMG" && link !== "" && (
        <Box
          borderRadius={"lg"}
          border="1px"
          borderColor="gray.200"
          p={8}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          h={{
            base: "400px",
            md: "400px",
            lg: "400px",
          }}
        >
          <Image crossOrigin={"anonymous"} src={link} />
        </Box>
      )}
      <Box
        borderRadius={"lg"}
        border="1px"
        borderColor="gray.200"
        p={8}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={4}
        minHeight={"200px"}
      >
        <RadioGroup
          display={"flex"}
          justifyContent={"center"}
          alignItems={"flex-start"}
          // flexDirection={'column'}
          gap={6}
          value={choiceId}
        >
          {!queryIsLoading &&
            choices?.map((data, index) => {
              return (
                <TrueOrFalseCard
                  key={index}
                  index={index}
                  value={data?.choiceId}
                  selectedChoiceId={choiceId}
                  setChoiceId={setChoiceId}
                >
                  {data?.description}
                </TrueOrFalseCard>
              );
            })}
        </RadioGroup>
      </Box>
    </Stack>
  );
};

export default SurveyTrueOrFalse;
