import axios from 'axios';
// import store from '@store';
// import { REACT_APP_BACKEND_ADDR, REACT_APP_ROOT_AUTH_TOKEN } from 'process.env'
// import { getStorageData, storeStorageData } from './storage';

const Backend = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ADDR,
});

Backend.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    return Promise.reject(error);
  },
);

const BackendAlt = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ADDR_ALT,
});

BackendAlt.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    return Promise.reject(error);
  },
);

const BackendArchive = axios.create({
  baseURL: process.env.REACT_APP_ARCHIVE_ADDR,
});

BackendArchive.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    return Promise.reject(error);
  },
);
const BackendDP = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ADDR_DP,
});

BackendDP.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    return Promise.reject(error);
  },
);
const NEISBackend = axios.create({
  baseURL: process.env.REACT_APP_NEIS_OPEN_DATA_ADDR,
});

NEISBackend.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    return Promise.reject(error);
  },
);

const BackendAddrSlash = process.env.REACT_APP_BACKEND_ADDR;
const BackendAddr = BackendAddrSlash.substring(0, BackendAddrSlash.length - 1);

let header = {
  headers: {
    Authorization: '',
    'Access-Control-Max-Age': 600,
  },
};

export const resetToken = async () => {
  console.log('Resetting token');
  if ('Authorization' in header.headers) {
    delete header.headers.Authorization;
  }
  return header;
};

export const refreshToken = async () => {
  if (header.headers.Authorization === '') {
    const token = localStorage.getItem('token');
    if (token) {
      header.headers.Authorization = `Bearer ${token}`;
    }
  }
};

export const setToken = async (token) => {
  localStorage.setItem('token', token);
  header.headers.Authorization = `Bearer ${token}`;
  return header;
};

export const setRootToken = async () => {
  const root_user = {
    id: 1,
    token: process.env.REACT_APP_ROOT_AUTH_TOKEN,
  };
  console.log('Setting token to: ', root_user.token);
  header.headers.Authorization = `Bearer ${root_user.token}`;
  return header;
};

export const getBackend = async (endpoint, annonymous = false) => {
  await refreshToken();
  if (annonymous) {
    return await Backend.get(endpoint, {
      headers: { Authorization: '' },
    });
  }
  return await Backend.get(endpoint, {
    headers: { Authorization: header.headers.Authorization },
  });
};

export const getBackendAlt = async (endpoint, annonymous = false) => {
  if (annonymous) {
    return await BackendAlt.get(endpoint, {
      headers: { Authorization: '' },
    });
  }
  return await BackendAlt.get(endpoint, {
    headers: { Authorization: header.headers.Authorization },
  });
};

export const getBackendDP = async (endpoint, annonymous = false) => {
  if (annonymous) {
    return await BackendDP.get(endpoint, {
      headers: { Authorization: '' },
    });
  }
  return await BackendDP.get(endpoint, {
    headers: { Authorization: header.headers.Authorization },
  });
};

export const postBackend = async (
  endpoint,
  payload = {},
  annonymous = false,
) => {
  await refreshToken();
  if (annonymous) {
    return await Backend.post(endpoint, payload, {
      headers: { Authorization: '' },
    });
  }
  console.log('postBackend: ', endpoint, payload, header);
  return await Backend.post(endpoint, payload, {
    headers: { Authorization: header.headers.Authorization },
  });
};

export const postArchiveBackend = async (
  endpoint,
  payload = {},
  annonymous = false,
) => {
  await refreshToken();
  if (annonymous) {
    return await BackendArchive.post(endpoint, payload, {
      headers: { Authorization: '' },
    });
  }
  console.log('postBackend: ', endpoint, payload, header);
  return await BackendArchive.post(endpoint, payload, {
    headers: { Authorization: header.headers.Authorization },
  });
};

export const postBackendAlt = async (
  endpoint,
  payload = {},
  annonymous = false,
) => {
  if (annonymous) {
    return await BackendAlt.post(endpoint, payload, {
      headers: { Authorization: '' },
    });
  }
  console.log('postBackendAlt: ', endpoint, payload, header);
  return await BackendAlt.post(endpoint, payload, {
    headers: { Authorization: header.headers.Authorization },
  });
};

export const postBackendDP = async (
  endpoint,
  payload = {},
  annonymous = false,
) => {
  if (annonymous) {
    return await BackendDP.post(endpoint, payload, {
      headers: { Authorization: '' },
    });
  }
  console.log('postBackendDP: ', endpoint, payload, header);
  return await BackendDP.post(endpoint, payload, {
    headers: { Authorization: header.headers.Authorization },
  });
};

export const patchBackend = async (
  endpoint,
  payload = {},
  annonymous = false,
) => {
  await refreshToken();
  if (annonymous) {
    return await Backend.patch(endpoint, payload, {
      headers: { Authorization: '' },
    });
  }
  return await Backend.patch(endpoint, payload, {
    headers: { Authorization: header.headers.Authorization },
  });
};

export const patchBackendAlt = async (
  endpoint,
  payload = {},
  annonymous = false,
) => {
  if (annonymous) {
    return await BackendAlt.patch(endpoint, payload, {
      headers: { Authorization: header.headers.Authorization },
    });
  }
  console.log('patchBackendAlt: ', endpoint, payload, header);
  return await BackendAlt.patch(endpoint, payload, {
    headers: { Authorization: header.headers.Authorization },
  });
};

export const patchBackendDP = async (
  endpoint,
  payload = {},
  annonymous = false,
) => {
  if (annonymous) {
    return await BackendDP.patch(endpoint, payload, {
      headers: { Authorization: header.headers.Authorization },
    });
  }
  console.log('patchBackendDP: ', endpoint, payload, header);
  return await BackendDP.patch(endpoint, payload, {
    headers: { Authorization: header.headers.Authorization },
  });
};

export const patchArchiveBackend = async (
  endpoint,
  payload = {},
  annonymous = false,
) => {
  if (annonymous) {
    return await BackendArchive.patch(endpoint, payload, {
      headers: { Authorization: header.headers.Authorization },
    });
  }
  console.log('patchBackendDP: ', endpoint, payload, header);
  return await BackendArchive.patch(endpoint, payload, {
    headers: { Authorization: header.headers.Authorization },
  });
};

export const postBackendFormData = async (endpoint, payload = {}) => {
  const formData = new FormData();
  Object.keys(payload).map((k) => {
    formData.append(k, payload[k]);
  });
  return await Backend.post(endpoint, formData, {
    headers: {
      ...header.headers,
      'Content-Type': 'multipart/form-data',
    },
  });
};

// export const patchBackend = async (endpoint, payload={}, annonymous=false) => {
//   if(annonymous) {
//     return await Backend.patch(endpoint, payload, {headers: {Authorization: ''}});
//   }
//   console.log('patchBackend: ', endpoint, payload, header);
//   return await Backend.patch(endpoint, payload, header);
// }

export const getNEISBackend = async (endpoint, annonymous = false) => {
  console.log('NEIS BACKEND ADDR', process.env.REACT_APP_NEIS_OPEN_DATA_ADDR);
  if (annonymous) {
    return await NEISBackend.get(endpoint, { headers: { Authorization: '' } });
  }
  return await NEISBackend.get(endpoint, header);
};

export const postNEISBackend = async (
  endpoint,
  payload = {},
  annonymous = false,
) => {
  if (annonymous) {
    return await Backend.post(endpoint, payload, {
      headers: { Authorization: '' },
    });
  }
  return await Backend.post(endpoint, payload, {
    headers: { Authorization: header.headers.Authorization },
  });
};

export const deleteBackend = async (
  endpoint,
  payload = {},
  annonymous = true,
) => {
  await refreshToken();
  if (annonymous) {
    return await Backend.delete(
      endpoint,
      {
        headers: { Authorization: '' },
      },
      payload,
    );
  }
  return await Backend.delete(
    endpoint,
    {
      headers: { Authorization: header.headers.Authorization },
    },
    payload,
  );
};

export const uploadDataToPresignedUrl = async (url, data) => {
  const response = await axios.put(url, data);
  return response;
};
