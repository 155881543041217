import { makeFreshAllIds } from '../helpers';
import {
  PURGE_PROGRAMCONFIG,
  GET_PROGRAMCONFIG_SUCCESS,
  GET_PROGRAMCONFIG_FAIL,
  GET_GROUP_PROGRAMCONFIGS_SUCCESS,
  GET_GROUP_PROGRAMCONFIGS_FAIL,
  CREATE_PROGRAMCONFIG_SUCCESS,
  CREATE_PROGRAMCONFIG_FAIL,
  UPDATE_PROGRAMCONFIG_SUCCESS,
  UPDATE_PROGRAMCONFIG_FAIL,
  DELETE_PROGRAMCONFIG_SUCCESS,
  DELETE_PROGRAMCONFIG_FAIL,
  UPDATE_EXTENSION_PROGRAMCONFIGSDATA_SUCCESS,
  UPDATE_EXTENSION_PROGRAMCONFIGSDATA_FAIL,
} from './actionTypes';

/**
 * @typedef {Object} ProgramconfigsState
 * @property {{[key: string]: focuspang.Programconfig}} byId
 * @property {string[]} allIds
 */

const initialState = {
  byId: {},
  allIds: [],
  extensionResponseData: [],
};

const programconfigs = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_PROGRAMCONFIG:
      return initialState;

    case GET_PROGRAMCONFIG_SUCCESS:
    case CREATE_PROGRAMCONFIG_SUCCESS:
    case UPDATE_PROGRAMCONFIG_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.programconfig.programconfigId]: action.programconfig,
        },
        allIds: makeFreshAllIds(state.allIds, [
          action.programconfig.programconfigId,
        ]),
      };

    case GET_GROUP_PROGRAMCONFIGS_SUCCESS:
      Object.keys(state.byId).forEach((programconfig) => {
        if (programconfig === 'undefined' || programconfig === '') {
          delete state.byId[programconfig];
          delete state.allIds[programconfig];
        }
        // array comparison, delete legacy things.
        if (
          !action.allIds.includes(programconfig) &&
          action.byId[action.allIds[0]].groupId ===
            state.byId[programconfig].groupId
        ) {
          delete state.byId[programconfig];
          delete state.allIds[programconfig];
        }

        if (programconfig === state.byId[programconfig]) {
          delete state.byId[programconfig];
          delete state.allIds[programconfig];
        }
      });
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
        allIds: makeFreshAllIds(state.allIds, action.allIds),
      };

    case UPDATE_EXTENSION_PROGRAMCONFIGSDATA_SUCCESS:
      return {
        ...state,
        extensionResponseData: action.response,
      };

    case DELETE_PROGRAMCONFIG_SUCCESS:
      delete state.byId[action.programconfig];
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.programconfig]: action.programconfig,
        },
        allIds: state.allIds.filter(
          (programconfigId) => programconfigId !== action.programconfig,
        ),
      };

    case UPDATE_EXTENSION_PROGRAMCONFIGSDATA_FAIL:
    case CREATE_PROGRAMCONFIG_FAIL:
    case UPDATE_PROGRAMCONFIG_FAIL:
    case GET_PROGRAMCONFIG_FAIL:
    case DELETE_PROGRAMCONFIG_FAIL:
      // console.error('[Programconfig Reducer] ', action.payload);
      return state;

    case GET_GROUP_PROGRAMCONFIGS_FAIL:
      // program configs not found, purge it
      return initialState;

    default:
      break;
  }
  return state;
};

export default programconfigs;
