import React, { useState, memo } from "react";
import { SurveyConstans } from "./surveyConstans";

import { Stack } from "@chakra-ui/react";
import SurveyLongInput from "./SurveyLongInput";
import SurveyShortInput from "./SurveyShortInput";
import SurveyMultiple from "./SurveyMultiple";
import SurveyTrueOrFalse from "./SurveyTrueOrFalse";
import SurveyStylus from "./SurveyStylus";
import SurveyRadio from "./SurveyRadio";
import SurveyHeadline from "./SurveyHeadline";
import SurveyTime from "./SurveyTime";
import SurveyDay from "./SurveyDay";

const SurveyView = memo(
  ({
    quiz,
    quizsetSessionId,
    isLastQuiz,
    groupId,
    uploadTrigger,
    uploading,
    setUploading,
    setQuizEnded,
    quizEnded,
    type,
    keyboardData,
    setKeyboardData,
  }) => {
    const quizType = quiz.quizType;
    const quizId = quiz.quizId;
    const link = quiz?.link;
    const linkType = quiz?.linkType;
    const title = quiz?.title;

    console.log(quizType, SurveyConstans.HEADLINE, "ccc");
    return (
      <Stack direction={"column"} spacing={4}>
        {quizType === SurveyConstans.HEADLINE && (
          <SurveyHeadline
            quizId={quizId}
            quizsetSessionId={quizsetSessionId}
            link={link}
            linkType={linkType}
            title={title}
            setQuizEnded={setQuizEnded}
          />
        )}
        {quizType === SurveyConstans.SECTION && (
          <SurveyHeadline
            quizId={quizId}
            quizsetSessionId={quizsetSessionId}
            link={link}
            linkType={linkType}
            title={title}
            setQuizEnded={setQuizEnded}
          />
        )}
        {quizType === SurveyConstans.LONG && (
          <SurveyLongInput
            quizId={quizId}
            quizsetSessionId={quizsetSessionId}
            link={link}
            linkType={linkType}
            title={title}
            isLastQuiz={isLastQuiz}
            groupId={groupId}
            setQuizEnded={setQuizEnded}
            type={type}
            keyboardData={keyboardData}
            setKeyboardData={setKeyboardData}
          />
        )}
        {quizType === SurveyConstans.SHORT && (
          <SurveyShortInput
            quizId={quizId}
            quizsetSessionId={quizsetSessionId}
            link={link}
            linkType={linkType}
            title={title}
            isLastQuiz={isLastQuiz}
            groupId={groupId}
            setQuizEnded={setQuizEnded}
            type={type}
            keyboardData={keyboardData}
            setKeyboardData={setKeyboardData}
          />
        )}
        {quizType === SurveyConstans.MULTIPLE && (
          <SurveyMultiple
            quizId={quizId}
            quizsetSessionId={quizsetSessionId}
            link={link}
            linkType={linkType}
            title={title}
            isLastQuiz={isLastQuiz}
            groupId={groupId}
            setQuizEnded={setQuizEnded}
            type={type}
          />
        )}
        {quizType === SurveyConstans.OX && (
          <SurveyTrueOrFalse
            quizId={quizId}
            quizsetSessionId={quizsetSessionId}
            link={link}
            linkType={linkType}
            title={title}
            isLastQuiz={isLastQuiz}
            groupId={groupId}
            quiz={quiz}
            setQuizEnded={setQuizEnded}
            type={type}
          />
        )}
        {quizType === SurveyConstans.STYLUS && (
          <SurveyStylus
            quizId={quizId}
            quizsetSessionId={quizsetSessionId}
            link={link}
            linkType={linkType}
            title={title}
            isLastQuiz={isLastQuiz}
            groupId={groupId}
            setQuizEnded={setQuizEnded}
            quizEnded={quizEnded}
            type={type}
          />
        )}
        {quizType === SurveyConstans.RADIO && (
          <SurveyRadio
            quizId={quizId}
            quizsetSessionId={quizsetSessionId}
            link={link}
            linkType={linkType}
            title={title}
            isLastQuiz={isLastQuiz}
            groupId={groupId}
            setQuizEnded={setQuizEnded}
            type={type}
          />
        )}
        {quizType === SurveyConstans.TIME && (
          <SurveyTime
            quizId={quizId}
            quizsetSessionId={quizsetSessionId}
            link={link}
            linkType={linkType}
            title={title}
            isLastQuiz={isLastQuiz}
            groupId={groupId}
            setQuizEnded={setQuizEnded}
            type={type}
          />
        )}
        {quizType === SurveyConstans.DAY && (
          <SurveyDay
            quizId={quizId}
            quizsetSessionId={quizsetSessionId}
            link={link}
            linkType={linkType}
            title={title}
            isLastQuiz={isLastQuiz}
            groupId={groupId}
            setQuizEnded={setQuizEnded}
            type={type}
          />
        )}
      </Stack>
    );
  }
);

export default SurveyView;
