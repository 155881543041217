import { Center, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
const QuizLoading = () => {
  const { t } = useTranslation();
  return (
    <Center height="70vh">
      <Heading>
        {/* 퀴즈 결과를 불러오고 있습니다. 잠시만 기다려주세요! */}
        {t("quizpang.test.text.resultloading")}
      </Heading>
    </Center>
  );
};

export default QuizLoading;
