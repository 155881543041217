import React from "react";
import { Box } from "@chakra-ui/react";
import QuizMultiple from "./QuizType/QuizMultiple";
import QuizOX from "./QuizType/QuizOX";
import QuizShort from "./QuizType/QuizShort";
import QuizAccordionPanel from ".";

const ResultPanel = ({ choices, quizType, answerData, totalParticipants }) => {
  const oxType = quizType === "OX";
  const renderQuizTypeCard = (
    quizType,
    choiceDataItem,
    answerData,
  ) => {
    switch (quizType) {
      case "OX":
        return (
          <QuizOX
            answerData={answerData}
            choiceDataItem={choiceDataItem}
            totalParticipants={totalParticipants}
          />
        );
      case "MULTIPLE":
        return (
          <QuizMultiple
            answerData={answerData}
            choiceDataItem={choiceDataItem}
            totalParticipants={totalParticipants}
          />
        );
      case "SHORT":
        return (
          <QuizShort
            quizType={quizType}
            answerData={answerData}
            choiceDataItem={choiceDataItem}
            totalParticipants={totalParticipants}
          />
        );
      default:
        return null;
    }
  };

  return (
    <QuizAccordionPanel>
      <Box
        display={oxType && "flex"}
        alignItems={oxType && "center"}
        justifyContent={oxType && "center"}
      >
        {choices.map((choiceDataItem) => (
          <>
            {renderQuizTypeCard(
              quizType,
              choiceDataItem,
              answerData,
              totalParticipants,
            )}
          </>
        ))}
      </Box>
    </QuizAccordionPanel>
  );
};

export default ResultPanel;
