import { getQuizsetByQuizsetId, getCrewQuizset } from "@api";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

export const useQuizset = (quizsetId, testCrewType) => {
  const {
    data: quizset,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["quizsetQuizzes", quizsetId, testCrewType],
    queryFn: () => {
      if (testCrewType) {
        return getCrewQuizset(quizsetId, testCrewType);
      }
      return getQuizsetByQuizsetId(quizsetId);
    },
    enabled: !!quizsetId,
    select: ({ data }) => ({
      ...data,
      quizzes: JSON.parse(data.quizzes),
    }),
  });

  const quizTotalTime = useMemo(
    () =>
      quizset?.quizzes?.reduce(
        (prev, curr) => prev + Number(curr.timelimit),
        0
      ),
    [quizset?.quizzes]
  );
  return {
    quizset,
    quizTotalTime,
    isLoading,
    isError,
  };
};
