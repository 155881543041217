import {
  PURGE_CONTROL,
  SET_DIRTY,
  SET_SELECTED_PAGE,
  SET_SELECTED_CLASS,
  SET_APPROVAL_COMPLETED,
  SET_SIDEBAR_STATE,
  SET_DEV_MODE,
  SET_CLASSROOM_STATE,
  SET_FILELINK_STATE,
  SET_SOCKET,
  SET_SCREEN_DATA,
  SET_TEACHER_SCREEN,
  SET_SELECTED_STUDENT,
  RESET_SOCKET,
  SET_SOCKET_DATA,
  RESET_SOCKET_DATA_QUEUE,
  NEED_SEND_REGISTER_NOTY,
  SET_STUDENT_IMAGES,
  SET_TEACHER_CLIENT_ID,
  SET_BIG_URI,
  CLEAR_BIG_URI,
  SET_REFRESH_STATE,
  SET_QUIZ_STATE,
  SET_NETWORK_STATE,
  CLEAR_NETWORK_STATE,
  SET_CAMERA_PERMISSION,
  SET_MICROPHONE_PERMISSION,
  SET_LOCATION_PERMISSION,
  SET_DEVICEMOTION_PERMISSION,
  SET_RECIEVED_GROUP_ID,
  SET_UPLOAD_TRIGGER,
  SET_UPLOAD_RESPONSE,
  SET_UPLOAD_ARCHIVE_RESPONSE,
  PURGE_UPLOAD_TRIGGER,
  PURGE_UPLOAD_RESPONSE,
  PURGE_UPLOAD_ARCHIVE_RESPONSE,
  SET_FOCUS_TYPE,
  PURGE_FOCUS_TYPE,
  SET_FOCUS_TIME_STATE,
  PURGE_FOCUS_TIME_STATE,
  SET_HAS_ANSWER,
  PURGE_HAS_ANSWER,
  SET_SURVEY_RESPONSE_OK,
  PURGE_SURVEY_RESPONSE_OK,
  SET_NETWORK_SPEED,
} from "./actionTypes";

/**
 * @typedef {Object} ScreenData
 * @property {string} method
 * @property {string} uri
 * @property {string} type
 * @property {string} clientId
 * @property {string} groupId
 * @property {string} data
 */

/**
 * @typedef {Object} SocketData
 * @property {Object} data
 * @property {(() => void)?} onSend
 */

/**
 * @typedef {Object} ControlState
 * @property {number} dirty
 * @property {string} selectedPage
 * @property {string?} selectedClassId
 * @property {boolean} approvalCompleted
 * @property {boolean} sidebarState
 * @property {boolean} devMode
 * @property {boolean} classroomState
 * @property {number} filelinkState
 * @property {unknown} socket
 * @property {SocketData[]} socketDataQueue
 * @property {Date?} socketResetTime
 * @property {ScreenData?} screenData
 * @property {unknown} teacherScreen
 * @property {string?} selectedStd - selected student id
 */

/**
 * @type {ControlState}
 */
const initialState = {
  focusTimeState: false,
  focusType: null,
  uploadTrigger: false,
  uploadResponse: false,
  uploadArchiveResponse: false,
  hasAnswer: false,
  surveyResponseOk: false,

  refreshDate: 0,
  dirty: 0,
  selectedPage: "",
  selectedClassId: null,

  approvalCompleted: false,
  sidebarState: false,

  teacherClientId: null,

  devMode: false,
  classroomState: false,
  filelinkState: 0,
  socket: null,
  socketDataQueue: [],
  socketResetTime: null,
  screenData: null,
  teacherScreen: null,
  selectedStd: null,
  registerNoty: false,
  studentImage: {},
  receivedGroupId: {},
  images: [],
  bigUri: "",
  quizEnded: false,
  hasCameraPermission: false,
  hasLocationPermission: false,
  hasMicrophonePermission: false,
  hasDevicemotionPermission: false,
  networkState: 0,
  networkSpeed: 10,
};

const controls = (state = initialState, action) => {
  // console.log('controls reducer', state, action);

  switch (action.type) {
    case NEED_SEND_REGISTER_NOTY:
      return { ...state, registerNoty: action.registerNoty };

    case PURGE_CONTROL:
      return initialState;

    case SET_DIRTY:
      return { ...state, dirty: state.dirty + 1 };

    case SET_SELECTED_PAGE:
      return { ...state, selectedPage: action.selectedPage };

    case SET_SELECTED_CLASS:
      return { ...state, selectedClassId: action.selectedClassId };

    case SET_APPROVAL_COMPLETED:
      return { ...state, approvalCompleted: true };
    case SET_SIDEBAR_STATE:
      return { ...state, sidebarState: true };
    case SET_STUDENT_IMAGES:
      return {
        ...state,
        studentImage: {
          ...state.studentImage,
          [action.data.clientId]: action.data.image,
        },
      };
    case SET_DEV_MODE:
      return { ...state, devMode: action.devMode };
    case SET_CLASSROOM_STATE:
      return { ...state, classroomState: action.classState };
    case SET_FILELINK_STATE:
      return { ...state, filelinkState: state.filelinkState + 1 };
    case SET_SOCKET:
      return { ...state, socket: action.socket };
    case SET_SCREEN_DATA:
      return { ...state, screenData: action.screenData };
    case SET_TEACHER_CLIENT_ID:
      return { ...state, teacherClientId: action.clientId };
    case SET_TEACHER_SCREEN:
      return { ...state, teacherScreen: action.teacherScreen };
    case SET_SELECTED_STUDENT:
      return { ...state, selectedStd: action.selectedStd };
    case SET_SOCKET_DATA:
      return {
        ...state,
        socketDataQueue: [...state.socketDataQueue, action.socketData],
      };
    case RESET_SOCKET:
      return { ...state, socketResetTime: action.socketResetTime };
    case RESET_SOCKET_DATA_QUEUE:
      return { ...state, socketDataQueue: [] };
    case SET_BIG_URI:
      return { ...state, bigUri: action.bigUri };
    case CLEAR_BIG_URI:
      return { ...state, bigUri: "" };
    case SET_REFRESH_STATE:
      return { ...state, refreshDate: action.date };
    case SET_QUIZ_STATE:
      return { ...state, quizEnded: action.quizState };
    case SET_NETWORK_STATE:
      return { ...state, networkState: action.networkState };
    case CLEAR_NETWORK_STATE:
      return { ...state, networkState: 0 };
    case SET_CAMERA_PERMISSION:
      return { ...state, hasCameraPermission: true };
    case SET_MICROPHONE_PERMISSION:
      return { ...state, hasMicrophonePermission: true };
    case SET_LOCATION_PERMISSION:
      return { ...state, hasLocationPermission: true };
    case SET_DEVICEMOTION_PERMISSION:
      return { ...state, hasDevicemotionPermission: true };
    case SET_RECIEVED_GROUP_ID:
      return { ...state, receivedGroupId: action.groupId };
    case SET_UPLOAD_TRIGGER:
      return { ...state, uploadTrigger: true };
    case PURGE_UPLOAD_TRIGGER:
      return { ...state, uploadTrigger: false };
    case SET_UPLOAD_RESPONSE:
      return { ...state, uploadResponse: true };
    case PURGE_UPLOAD_RESPONSE:
      return { ...state, uploadResponse: false };
    case SET_UPLOAD_ARCHIVE_RESPONSE:
      return {
        ...state,
        uploadArchiveResponse: action.uploadArchiveResponse,
      };
    case PURGE_UPLOAD_ARCHIVE_RESPONSE:
      return { ...state, uploadArchiveResponse: false };
    case SET_FOCUS_TYPE:
      return {
        ...state,
        focusType: action.focusType,
      };
    case PURGE_FOCUS_TYPE:
      return {
        ...state,
        focusType: null,
      };
    case SET_FOCUS_TIME_STATE:
      return {
        ...state,
        focusTimeState: action.focusTimeState,
      };
    case PURGE_FOCUS_TIME_STATE:
      return {
        ...state,
        focusTimeState: false,
      };
    case SET_HAS_ANSWER:
      return {
        ...state,
        hasAnswer: true,
      };
    case PURGE_HAS_ANSWER:
      return {
        ...state,
        hasAnswer: false,
      };
    case SET_SURVEY_RESPONSE_OK:
      return {
        ...state,
        surveyResponseOk: true,
      };
    case PURGE_SURVEY_RESPONSE_OK:
      return {
        ...state,
        surveyResponseOk: false,
      };

    case SET_NETWORK_SPEED:
      return {
        ...state,
        networkSpeed: action.networkSpeed,
      };
    default:
      break;
  }
  return state;
};

export default controls;
