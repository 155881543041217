import styled from 'styled-components';
import TableTd from './TableTd';

const Table = styled.table`
  width: 100%;
  margin-top: 30px;
`
const THead = styled.thead``
const Tr = styled.tr``
const Th = styled.th`
  width: 187px;
  height: 40px;
  border: 1px solid #E4E4E4;
  background: #EDEDED;
  color: #212120;
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &:first-child {
    color: #E70000;
  }
  &:last-child {
    color: #0009DD;
  }
`
const TBody = styled.tbody``

const AttendanceCalendar = ({ year, month, calendar, selectDate }) => {
  return (
    <Table>
      <THead>
        <Tr>
          {
            ['일', '월', '화', '수', '목', '금', '토'].map((item, index) => (
              <Th key={index}>{item}</Th>
            ))
          }
        </Tr>
      </THead>
      <TBody>
        {
          [...Array(5)].map((item, i) => (
            <Tr key={i}>
              {
                [...Array(7)].map((item, j) => (
                  <TableTd key={j} i={i} j={j}
                          year={year}
                          month={(() => { if (i === 0 && calendar[i * 7 + j] > 7) return month - 1; else if (i === 4 && calendar[i * 7 + j] < 7) return month + 1; else return month; })()}
                          date={calendar[i * 7 + j]}
                          selectDate={selectDate}
                  />
                ))
              }
            </Tr>
          ))
        }
      </TBody>
    </Table>
  )
}

export default AttendanceCalendar