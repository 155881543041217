import React from "react";
import QuizAccordionPanel from ".";
import { Box, Text } from "@chakra-ui/react";
import ReactQuill from "react-quill";
import { useTranslation } from "react-i18next";

const ExplainPanel = ({ resultDataItem }) => {
  const { description } = resultDataItem;
  const { t } = useTranslation();
  return (
    <QuizAccordionPanel>
      <Text ml="16px" color="#707070" fontSize="14px">
        {/* 문제 해설 */}
        {t("quizpang.quizresult.explanation")}
      </Text>
      <Box borderRadius="lg">
        {description === null || description === "" ? (
          <ReactQuill
            // value={"설명이 없습니다."}
            value={t("quizpang.quizresult.noexplanation")}
            readOnly={true}
            theme="bubble"
            modules={{
              toolbar: false,
            }}
          />
        ) : (
          <ReactQuill
            value={description}
            readOnly={true}
            theme="bubble"
            modules={{
              toolbar: false,
            }}
          />
        )}
      </Box>
    </QuizAccordionPanel>
  );
};

export default ExplainPanel;
