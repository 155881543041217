import {
  GET_GROUP_ACTIVITY_FAIL,
  GET_GROUP_ACTIVITY_SUCCESS,
  GET_SUBGROUP_ACTIVITY_FAIL,
  GET_SUBGROUP_ACTIVITY_SUCCESS,
  GROUP_ACTIVITY_LOADING,
} from './actionTypes';

const initialState = {
  activityList: [],
  subgroupList: [],
  isLoading: false,
};

const groupActivity = (state = initialState, action) => {
  switch (action.type) {
    case GROUP_ACTIVITY_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case GET_GROUP_ACTIVITY_SUCCESS:
      return {
        ...state,
        activityList: action.payload.activityList,
      };
    case GET_SUBGROUP_ACTIVITY_SUCCESS:
      return {
        ...state,
        subgroupList: action.payload.subgroupList.map((subgroup) => ({
          ...subgroup,
          students: JSON.parse(subgroup.students),
        })),
      };
    case GET_SUBGROUP_ACTIVITY_FAIL:
      return {
        ...state,
        subgroupList: [],
      };
    case GET_GROUP_ACTIVITY_FAIL:
      // console.error("[Group Activity Reducer] ", action.payload);
      return state;

    default:
      break;
  }
  return state;
};

export default groupActivity;
