import styled from "styled-components";
import React, { useEffect, useState } from "react";
import BaseCard from "./baseCard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch } from "react-redux";
import { ButtonConstants } from "constants/buttonConstants";
import { setReportLog } from "@store/actions";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  padding: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.15px;
`;

const Body = styled.div`
  margin-top: 16px;
  display: ${(props) => (props.open ? "block" : "none")};
  background-color: #ffffff;
`;

const ToggleButton = styled.div`
  color: #0ba1ae;
  display: flex;
  align-items: center;
  cursor: pointer;

  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1.25px;
`;

const CollapseCard = ({ title, children, initialOpen }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (initialOpen) {
      setOpen(true);
    }
  }, [initialOpen]);
  const { t } = useTranslation();
  return (
    <BaseCard radius={8}>
      <Wrapper>
        <Header style={{ marginBottom: open ? 16 : 0 }}>
          <Title>{title}</Title>
          <ToggleButton
            onClick={() => {
              setOpen((prev) => !prev);
              dispatch(setReportLog(ButtonConstants.COLLAPSE_CARD.TOGGLE));
            }}
          >
            {/* {open ? "접기" : "펼치기"} */}
            {open
              ? t("focustime.button.click.dashboard.close")
              : t("focustime.button.click.dashboard.open")}
            <KeyboardArrowDownIcon
              fontSize="small"
              style={{
                transform: `rotate(${open ? 180 : 0}deg)`,
                color: "#0BA1AE",
              }}
            />
          </ToggleButton>
        </Header>
        <Body open={open}>{children}</Body>
      </Wrapper>
    </BaseCard>
  );
};

export default CollapseCard;
