import { getQuiz } from "@api";
import { useQuery } from "@tanstack/react-query";

export const useQuiz = (quizId) => {
  const { data: quiz } = useQuery({
    queryKey: ["quiz", quizId],
    queryFn: () => {
      if (quizId) return getQuiz(quizId);
    },
    enabled: !!quizId,
    select: ({ data }) => ({
      ...data,
      choices: JSON.parse(data.choices),
    }),
  });

  return {
    quiz,
  };
};
