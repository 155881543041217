export const ButtonConstants = {
  SIDEBAR: {
    HOME: "SIDEBAR_HOME",
    SELF_MANAGING: "SIDEBAR_SELF_MANAGING",
    CONFIG: "SIDEBAR_CONFIG",
    NOTICE: "SIDEBAR_NOTICE",
    CLASSROOM: "SIDEBAR_CLASSROOM",
    BAND: "SIDEBAR_BAND",
    QUIZPANG: "SIDEBAR_QUIZPANG",
    ASSIGNMENT: "SIDEBAR_ASSIGNMENT",
    SIDEBAR: "SIDEBAR",
  },
  HOME: {
    ADD_CLASS: "ADD_CLASS",
    ADD_CLASS_CANCEL: "ADD_CLASS_CANCEL",
    ADD_CLASS_CONFIRM: "ADD_CLASS_CONFIRM",
    CANCLE: "CANCEL",
    CONFIRM: "CONFIRM",
    TO_CLASS: "TO_CLASS",
  },
  SELF_MANAGING: {
    //todo: 초대 된 상태 확인 필요
    SUBMIT: "SUBMIT",
  },
  CONFIG: {
    CHANGE_PROFILE_IMAGE: "CHANGE_PROFILE_IMAGE",
    LOAD_IMAGE: "LOAD_IMAGE",
    CANCLE: "CANCEL",
    CONFIRM_IMAGE_CHANGE: "CONFIRM_IMAGE_CHANGE",
    LOGOUT: "LOGOUT",
  },
  BASEMODAL: {
    CANCEL: "MODAL_CANCEL",
    COMPLETE: "MODAL_COMPLETE",
  },
  CLASSROOM: {
    FILELINK: "FILELINK",
    WEBLINK: "WEBLINK",
    FULLSCREEN: "FULLSCREEN",
    END_FULLSCREEN: "END_FULLSCREEN",
  },
  COMMENTS_LAYER: {
    PEN: "COMMENTS_LAYER_PEN",
    ERASER: "COMMENTS_LAYER_ERASER",
    CLEAR: "COMMENTS_LAYER_CLEAR",
    SCREENSHARE: "COMMENTS_LAYER_SCREENSHARE",
  },
  BAND: { FULLSCREEN: "FULLSCREEN", CHANGE_SIZE: "CHANGE_SIZE" },
  QUIZPANG: {
    LOAD_MORE: "LOAD_MORE",
    QUIZ_BATTLE_LOG_TAP: "QUIZ_BATTLE_LOG_TAP",
    SURVEY_TAP: "SURVEY_TAP",
    QUIZ_EXIT: "QUIZ_EXIT",
  },

  COLLAPSE_CARD: {
    TOGGLE: "TOGGLE",
  },
  SURVEY: {
    JOIN_CURRENT_SURVEY: "JOIN_CURRENT_SURVEY",
    START_SURVEY: "START_SURVEY",
    BACK_TO_SURVEY_HOME: "BACK_TO_SURVEY_HOME",
    RELOAD_SURVEY: "RELOAD_SURVEY",
    NEXT_QUESTION: "NEXT_QUESTION",
    END_SURVEY: "END_SURVEY",
  },
  // LONG: 'LONG',
  // SHORT: 'SHORT',
  // RADIO: 'RADIO',
  // OX: 'OX',
  // HEADLINE: 'HEADLINE',
  // TIME: 'TIME',
  // STYLUS: 'STYLUS',
  // MULTIPLE: 'MULTIPLE',
  // DAY: 'DAY',
};
