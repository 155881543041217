import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useQuizsetSurvey } from "./hooks/useSurvey";
import {
  Box,
  Button,
  Container,
  Grid,
  GridItem,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import SidebarClassRoom from "@components/Layout/SidebarClassRoom";
import SurveyCardWrapper from "./survey-card-wrapper";
import { surveyList } from "constants/surveyPackageList";
import {
  purgeSurveyListIds,
  setReportLog,
  setSurveyListIds,
} from "@store/actions";
import { dispatch } from "@adobe/redux-saga-promise";
import { ButtonConstants } from "constants/buttonConstants";
import { useTranslation } from "react-i18next";

const SurveyMainWrapper = (props) => {
  const { groupId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const surveyListArray = surveyList;

  // 동기적으로 설문페이지로 이동하는 함수 먼저 초기화 한 후에 이동
  const handleSurveyPage = async () => {
    await dispatch(purgeSurveyListIds());
    await dispatch(setSurveyListIds(convertSurveyList(surveyListArray)));
    setTimeout(() => {
      history.replace(`/survey/${groupId}`);
    }, 2000);
  };

  const convertSurveyList = (surveyListArray) => {
    let result = "";
    surveyListArray.forEach((item, index) => {
      if (index === 0) {
        result += item;
      } else {
        result += `,${item}`;
      }
    });
    return result;
  };
  const { t } = useTranslation();
  return (
    <Stack dir="column" spacing={4} mb={8}>
      <VStack align="flex-start" spacing={4} borderRadius="lg">
        <Button
          colorScheme="blue"
          onClick={() => {
            handleSurveyPage();
            dispatch(setReportLog(ButtonConstants.SURVEY.JOIN_CURRENT_SURVEY));
          }}
        >
          {/* 현재 진행중인 설문 참여 */}
          {t("quizpang.survey.button.insurvey")}
        </Button>
      </VStack>
      <SurveyCardWrapper />
    </Stack>
  );
};

export default SurveyMainWrapper;
