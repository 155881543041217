import { Box, Container, Grid, GridItem, Stack, Text } from '@chakra-ui/react';
import SidebarClassRoom from '@components/Layout/SidebarClassRoom';
import React from 'react';
import SurveyCardWrapper from './SurveyCardWrapper';

const FocusSurvey = () => {
  return (
    <Grid gridTemplateColumns={'280px 1fr'} templateAreas={`"sidebar main"`}>
      <GridItem area={'sidebar'}>
        <SidebarClassRoom inClass />
      </GridItem>
      <GridItem area={'main'} p={8}>
        <Container maxW={'container.xl'}>
          <Stack dir="column" spacing={4} mb={8}>
            <Box bg="gray.200" borderRadius={'lg'} p={8}>
              <Text fontSize="2xl" fontWeight="bold">
                설문지
              </Text>
            </Box>
            <SurveyCardWrapper />
          </Stack>
        </Container>
      </GridItem>
    </Grid>
  );
};
export default FocusSurvey;
