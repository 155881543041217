import { call, put, takeLeading } from 'redux-saga/effects';

import {
  createReportLogPromise,
  createReportLogSuccess,
  createReportLogFail,
} from './actions';

import * as api from '@api';

import { implementPromiseAction } from '@adobe/redux-saga-promise';
function* createReportLogPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createReport, action.payload);
      yield put(createReportLogSuccess(response.data.reports));
      return yield response.data;
    } catch (error) {
      if (error?.response?.status === 500) {
        yield put(createReportLogFail(error));
      }
    }
  });
}

function* reportLogSaga() {
  yield takeLeading(createReportLogPromise, createReportLogPromiseHandler);
}

export default reportLogSaga;
