import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  getActiveManagingPromise,
  getActiveManagingSuccess,
  getActiveManagingFail,
  setActiveManagingExist,
  setActiveManagingNotExist,
  createManagingPromise,
  createManagingSuccess,
  createManagingFail,
  deleteManagingPromise,
  deleteManagingSuccess,
  deleteManagingFail,
  updateManagingPromise,
  updateManagingSuccess,
  updateManagingFail,
  updateManagingLoading,
} from '@store/actions';
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';
import {
  getActiveManagingByGroupFail,
  getActiveManagingByGroupPromise,
  getActiveManagingByGroupSuccess,
  getManagingFail,
  getManagingPromise,
  getManagingsByGroupFail,
  getManagingsByGroupPromise,
  getManagingsByGroupSuccess,
  getManagingSuccess,
} from './actions';

function* createManagingPromiseHandler(action) {
  yield put(updateManagingLoading(true));
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createManaging, action.payload);
      yield put(createManagingSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(createManagingFail(error));
    } finally {
      yield put(updateManagingLoading(false));
    }
  });
}

function* getManagingPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getManaging, action.payload);
      yield put(getManagingSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getManagingFail(error));
    }
  });
}

function* getManagingsByGroupPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getManagingsByGroup, action.payload);
      console.log('response', response);
      yield put(getManagingsByGroupSuccess(response.data.managings));
      return yield response.data;
    } catch (error) {
      yield put(getManagingsByGroupFail(error));
    }
  });
}

function* getActiveManagingPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getActiveManaging, action.payload);
      if (response.data.managing.state === 'IN_MANAGING') {
        yield put(getActiveManagingSuccess(response.data));
      }
      return yield response.data;
    } catch (error) {
      yield put(getActiveManagingFail(error));
      // yield put(setActiveManagingNotExist());
      return null;
    }
  });
}

function* getActiveManagingByGroupPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getActiveManagingByGroup, action.payload);
      yield put(getActiveManagingByGroupSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getActiveManagingByGroupFail(error));
      yield put(setActiveManagingNotExist());
      return null;
    }
  });
}

function* deleteManagingPromiseHandler(action) {
  yield put(updateManagingLoading(true));
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.deleteManaging, action.payload);

      yield put(deleteManagingSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(deleteManagingFail(error));
    }
    // finally {
    //   yield put(updateManagingLoading(false));
    // }
  });
}

function* updateManagingPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.updateManaging, action.payload);
      yield put(updateManagingSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(updateManagingFail(error));
      throw error;
    }
  });
}

function* managingSaga() {
  yield takeLeading(getManagingPromise, getManagingPromiseHandler);
  yield takeEvery(
    getManagingsByGroupPromise,
    getManagingsByGroupPromiseHandler
  );

  yield takeLeading(getActiveManagingPromise, getActiveManagingPromiseHandler);
  yield takeLeading(
    getActiveManagingByGroupPromise,
    getActiveManagingByGroupPromiseHandler
  );
  yield takeLeading(createManagingPromise, createManagingPromiseHandler);
  yield takeLeading(deleteManagingPromise, deleteManagingPromiseHandler);
  yield takeLeading(updateManagingPromise, updateManagingPromiseHandler);
}

export default managingSaga;
