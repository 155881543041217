import {
  SET_COMMENTS_TEACHER,
  SET_COMMENTS_STUDENT,
  SET_COMMENTS_LAYER_ACTIVE,
  GET_COMMENTS_LAYER_FAIL,
  GET_COMMENTS_LAYER_SUCCESS,
  SET_COMMENTS_TARGET,
  SET_COMMENTS_SHARED_SCREEN,
  SET_MY_COMMENTS_SHARED,
  DELETE_COMMENTS_STUDENT_ALL,
} from './actionTypes';
import randomColor from 'randomcolor';

const color = randomColor({ hue: 'blue', count: 18 });
const strokeColor = color[Math.floor(Math.random() * color.length)];
const initialState = {
  isCommentsLayerActive: false,
  isMyCommentsSharing: true,
  strokeColor,
  commentsLayer: {},
  commentsTarget: [],
  studentComments: {},
  teacherComments: '',
  studentSharedImage: '',
};

const commentsLayer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMENTS_TARGET:
      return {
        ...state,
        commentsTarget: action.payload || [],
      };
    case SET_COMMENTS_STUDENT:
      return {
        ...state,
        studentComments: {
          ...state.studentComments,
          [action.payload.clientId]: action.payload.image,
        },
      };
    case DELETE_COMMENTS_STUDENT_ALL:
      return {
        ...state,
        studentComments: {},
        studentSharedImage: '',
      };
    case SET_COMMENTS_SHARED_SCREEN:
      return {
        ...state,
        studentSharedImage: action.payload,
      };
    case SET_MY_COMMENTS_SHARED:
      return {
        ...state,
        isMyCommentsSharing: action.payload,
      };
    case SET_COMMENTS_TEACHER:
      return {
        ...state,
        teacherComments: action.payload,
      };
    case SET_COMMENTS_LAYER_ACTIVE:
      return {
        ...state,
        isCommentsLayerActive: action.payload,
        teacherComments: '',
        studentComments: {},
        commentsTarget: [],
        studentSharedImage: '',
      };
    case GET_COMMENTS_LAYER_SUCCESS:
      const commentsLayers = action.payload.commentsLayers;
      if (commentsLayers?.length) {
        return {
          ...state,
          commentsLayer: commentsLayers[commentsLayers.length - 1],
          isCommentsLayerActive:
            commentsLayers[commentsLayers.length - 1].isActivated,
        };
      }
      return {
        ...state,
        commentsLayer: {},
        isCommentsLayerActive: false,
      };

    case GET_COMMENTS_LAYER_FAIL:
      // console.error("[Comments Layer Reducer] ", action.payload);
      return state;

    default:
      return state;
  }
};

export default commentsLayer;
