import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./CardLectureInfo.module.scss";
import BaseCard from "./baseCard";
import { selectAllClients } from "@store/selectors";
import { useDispatch, useSelector } from "react-redux";
import { selectTeacherMemberships } from "@store/membership/selector";
import { Tooltip } from "@mui/material";
import styled from "styled-components";
import { setReportLog } from "@store/actions";
import { ButtonConstants } from "constants/buttonConstants";
import { useTranslation } from "react-i18next";

/**
 * @typedef {Object} CardLectureInfoProps
 * @property {Timetable} timetable
 * @property {Group} group
 * @property {Timetable[]} prop_tables
 * @property {string} prop_weekday
 */

/**
 *
 * @param {CardLectureInfoProps} props
 * @returns
 */

const TopBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
`;

const ClassStartTime = styled.div`
  width: 80px;
  flex-direction: row;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.4px;
  color: #00000099;
`;

const TeacherNameWrapper = styled.div`
  width: 60px;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.4px;
  color: #00000099;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const BottomBoxWrapper = styled.div`
  padding-left: 8px;
  padding-right: 8px;
`;

const SubjectInfo = styled.div`
  width: 140px;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.4px;
  color: #000000de;
  padding-bottom: 4px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const CardLectureInfo = ({ timetable, group, prop_tables, prop_weekday }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const teacherMemberships = useSelector((state) =>
    selectTeacherMemberships(state)
  );
  const clients = useSelector((state) => selectAllClients(state));
  // const teacherMembershipByGroup = useSelector((state) =>
  //   selectTeacherMembershipByGroup(state, group)
  // );
  // console.log('CardLectureInfo', teacherMembershipByGroup);
  const dispatch = useDispatch();
  const navigateToClassRoom = () => {
    if (group.groupType === "AFTER") {
      history.push({
        pathname: `/after-school`,
        state: { group, timetable },
      });
    } else {
      history.push(`/notice/${group.groupId}`);
    }
  };

  const findClientName = (groupId) => {
    if (!groupId) return null;

    const foundMembership = teacherMemberships?.find(
      (membership) => membership.groupId === groupId
    );

    if (!foundMembership) return null;

    const foundClient = clients.find(
      (client) => foundMembership?.clientId === client.clientId
    );

    if (foundClient !== null) return foundClient?.userName;

    return null;
  };

  return (
    <BaseCard
      className={styles["container"]}
      style={{ backgroundColor: "#FFF" }}
    >
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigateToClassRoom();
          dispatch(setReportLog(ButtonConstants.HOME.TO_CLASS));
        }}
      >
        <TopBoxWrapper>
          <ClassStartTime>
            {/* {timetable.period + "교시 " + timetable.startTime} */}
            {t("period", { count: timetable.period, ordinal: true })}
            {/* <div>{timetable.startTime}</div> */}
          </ClassStartTime>
          <Tooltip title={findClientName(group.groupId)} arrow>
            <TeacherNameWrapper>
              {findClientName(group.groupId)}
            </TeacherNameWrapper>
          </Tooltip>
        </TopBoxWrapper>

        <Tooltip title={group.subjectName}>
          <BottomBoxWrapper>
            <SubjectInfo>{group.subjectName}</SubjectInfo>
          </BottomBoxWrapper>
        </Tooltip>

        {/* <ClassTimeline
          period={timetable.period}
          timeString={`${timetable.startTime} ~ ${timetable.endTime}`}
        /> */}
      </div>
    </BaseCard>
  );
};

export default CardLectureInfo;
