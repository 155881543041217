import { Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const Countdown = ({
  startSecond,
  onZero,
  isSmall = false,
  saveQuizTime,
  setTimer,
}) => {
  const [second, setSecond] = useState(startSecond || 5);

  useInterval(() => {
    if (second !== 0) {
      setSecond(second - 1);
      setTimer(second);
      saveQuizTime && saveQuizTime(second - 1);
    }
  }, 1000);

  useEffect(() => {
    if (second !== 0) return;
    if (onZero) onZero();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [second]);
  const { t } = useTranslation();
  return (
    <VStack spacing={2}>
      {/* <Text fontSize="lg">남은시간</Text> */}
      <Text fontSize="lg">{t("quizpang.test.text.remaintime")}</Text>
      <Text
        fontSize="3xl"
        fontWeight="bold"
        color={second < 3 ? "orange.400" : "blue.400"}
      >
        {second}
      </Text>
    </VStack>
  );
};
