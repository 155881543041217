import { getUserAgent } from "@pages/QuizPang/utils";
import { divideHangul } from "hangul-util";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { postPopupData } from "@api/quizpang/quizpangApi";

function transformKeyCodetoKey(keyCode) {
  let specialKey;
  switch (keyCode) {
    case "Backquote":
      specialKey = "`";
      break;
    case "Minus":
      specialKey = "-";
      break;
    case "Equal":
      specialKey = "=";
      break;
    case "BracketLeft":
      specialKey = "[";
      break;
    case "BracketRight":
      specialKey = "]";
      break;
    case "Backslash":
      specialKey = "\\";
      break;
    case "Semicolon":
      specialKey = ";";
      break;
    case "Quote":
      specialKey = "'";
      break;
    case "Comma":
      specialKey = ",";
      break;
    case "Period":
      specialKey = ".";
      break;
    case "Slash":
      specialKey = "/";
      break;
    case "Space":
      specialKey = "SPACE";
      break; // 수정된 부분
    case "Colon":
      specialKey = ":";
      break;
    case "ShiftLeft":
      specialKey = "Shift";
      break;
    default:
      specialKey = keyCode;
  }
  return specialKey;
}

function useKeyboardLogger(onKeyData, input, quizTimelimit, timer, quizId) {
  const sessionDeviceType = getUserAgent();
  const keyStartTimeRef = useRef(new Map());
  const letterRef = useRef({ letter: "" });
  const keyDownEventqueue = useRef([]);
  const user = useSelector((state) => state.user);
  const [alertState, setAlertState] = useState(true);
  const [random, setRandom] = useState(10);
  const [spaceCount, setSpaceCount] = useState(0);

  useEffect(() => {
    setRandom(Math.floor(Math.random() * 10) + 1);
  }, [quizId]);

  useEffect(() => {
    if (quizTimelimit - timer < 3) {
      setAlertState(true);
    } else {
      setAlertState(false);
    }
  }, [quizTimelimit, timer]);

  useEffect(() => {
    const asyncPostPopupData = async () => {
      await postPopupData(user.clientId, quizId, new Date().toISOString());
    };
    if (alertState && input.length > 0) {
      if (random < 3) {
        alert("너무 빠르게 입력하셨습니다.");
        asyncPostPopupData();
        // TODO: 여기다 키보드 입력 피드백 발생하였다고 서버에 보내는 코드 추가하기
      }
    }
  }, [alertState, input]);

  useEffect(() => {
    /**
     *  함수는 키보드의 키를 누를 때 호출됩니다.
     * 누른 키의 시작 시간을 기록하고, 해당 키가 이미 기록되어 있는지 확인한 후, 기록되어 있지 않으면 시작 시간을 저장합니다.
     */
    const handleKeyDown = (event) => {
      if (!keyStartTimeRef.current.has(event.code)) {
        keyStartTimeRef.current.set(event.code, Date.now());
        let letter = event.key;
        let touched = false;
        if (!event.code.startsWith("Key") && event.code !== "Space") {
          letter = transformKeyCodetoKey(event.code);
          touched = true;
        }

        // Space 키가 눌릴 때 카운트를 증가시키고 로그를 출력
        if (event.code === "Space") {
          letter = "SPACE"; // 수정된 부분
          setSpaceCount((prevCount) => {
            const newCount = prevCount + 1;
            console.log(`Space key pressed ${newCount} times`);
            return newCount;
          });
        }

        keyDownEventqueue.current.unshift({
          letter: letter,
          touched: touched,
          code: event.code,
        });
      }
    };

    const handleInput = (event) => {
      keyDownEventqueue.current.forEach((elem, index, arr) => {
        if (
          index === 0 &&
          elem.letter === "Process" &&
          elem.touched === false &&
          elem.code.startsWith("Key")
        ) {
          arr[0].letter = divideHangul(event.data).slice(-1)[0];
          arr[0].touched = true;
        } else if (keyDownEventqueue.current[0].letter.length === 1) {
          keyDownEventqueue.current.shift();
          letterRef.current.letter = divideHangul(event.data).slice(-1)[0];
          keyDownEventqueue.current.unshift({
            letter: letterRef.current.letter,
            touched: true,
          });
        }
      });
    };

    /**
     * 함수는 키보드의 키를 뗄 때 호출됩니다.
     * 해당 키의 시작 시간을 검색하고, 이벤트의 종료 시간을 계산합니다.
     */
    const handleKeyUp = (event) => {
      if (keyStartTimeRef.current.has(event.code)) {
        const startTime = keyStartTimeRef.current.get(event.code);
        const endTime = new Date().toISOString().slice(0, -1) + "Z";
        const heldTime = Date.now() - startTime; // 키를 누르고 있던 시간 계산 11/03 데이터 분석 서버 상에서 이미 로직이 있기 때문에 사용되지 않음

        if (keyDownEventqueue.current.length !== 0) {
          const test = keyDownEventqueue.current.reverse().map((elem) => {
            return {
              deviceType:
                sessionDeviceType === "Android"
                  ? "a"
                  : sessionDeviceType === "iOS"
                  ? "i"
                  : "o",
              letter: elem.letter,
              eventType: "keyboard",
              timeStamp: endTime,
              key_action: "KEY_UP", // 키를 뗏을 때의 액션
              input: input,
            };
          });
          onKeyData(test); // [ ]
        }
        keyStartTimeRef.current.delete(event.code);
        keyDownEventqueue.current = [];
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("input", handleInput);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("input", handleInput);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [onKeyData, input]);

  return keyStartTimeRef;
}

export default useKeyboardLogger;
