import {
  getQuizresponseByQuizsetSessionId,
  imageToFile,
  postBackend,
  uploadQuizStylusWrite,
} from '@api';
import { Box, Stack } from '@mui/material';
import { QuizStylusWrite } from '@pages/QuizPang/QuizSession';
import { getUserAgent } from '@pages/QuizPang/utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import 'react-quill/dist/quill.bubble.css';
import { useDispatch } from 'react-redux';
import { createSurveyresponse } from '@store/actions';
import { QuizStylusWriteTldrawer } from '@pages/QuizPang/QuizSession/quiz-stylus-write-tldrawer';
import { useScratch } from 'react-use';

export const StylusChoices = ({
  title,
  quizId,
  quizsetSessionId,
  submit,
  quizType,
  clientId,
  groupId,
  link,
  setStylusData,
  stylusData,
  containerRef,
  upload,
}) => {
  const [stylusImage, setStylusImage] = useState(null);
  const [stylusImageUpdateTrigger, setStylusImageUpdateTrigger] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();

  const responseLink = useRef(null);

  const uploadStylusImage = React.useCallback(
    async (stylusImage, clientId, quizId, quizsetSessionId) => {
      const file = imageToFile(stylusImage, clientId);
      const link = await uploadQuizStylusWrite(file, quizsetSessionId, quizId);
      responseLink.current = link;
    },
    []
  );

  const onSubmit = React.useCallback(async () => {
    try {
      await postBackend(`quizresponse`, {
        quizsetSessionId: quizsetSessionId,
        quizId,
        clientId: clientId,
        responseLink: responseLink.current,
      });
      history.push(`/quizpang/${groupId}`);
    } catch (error) {
      console.error(error);
    }
  }, [clientId, quizId, quizsetSessionId, groupId, history]);

  useEffect(() => {
    const uploadAndDispatch = async (
      stylusImage,
      clientId,
      quizId,
      quizsetSessionId
    ) => {
      await setStylusImageUpdateTrigger((prev) => prev + 1);
      setTimeout(async () => {
        await uploadStylusImage(
          stylusImage,
          clientId,
          quizId,
          quizsetSessionId
        );
        dispatch(
          createSurveyresponse(
            quizId,
            null,
            quizType,
            null,
            responseLink.current
          )
        );
      }, 500);
    };
    if (upload) {
      uploadAndDispatch();
    }
  }, [upload]);

  React.useEffect(() => {
    if (stylusImage === null) return;
    uploadStylusImage(stylusImage, clientId, quizId, quizsetSessionId);
  }, [stylusImage, clientId, quizId, quizsetSessionId, uploadStylusImage]);

  React.useEffect(() => {
    if (submit) {
      onSubmit();
    }
  }, [submit, onSubmit]);

  React.useEffect(() => {
    (async () => {
      const res = await getQuizresponseByQuizsetSessionId(quizsetSessionId);
      const imageLink = res.data.quizresponses[0].responseLink;
    })();
  }, [quizsetSessionId]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        mb: 2,
      }}
    >
      <Stack
        spacing={2}
        direction="column"
        sx={{
          width: '100%',
          // 스크롤 가능하게
          overflow: 'auto',
          // 높이 지정
          height: '100%',
          // 스크롤바 숨기기
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <ReactQuill
          value={title}
          readOnly
          theme="bubble"
          style={{
            width: '100%',
          }}
        />
        {link && link !== 'NotSet' ? (
          <img
            crossOrigin="anonymous"
            src={link}
            alt="quiz-image"
            style={{
              width: '50%',
              height: 'auto',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: '20px',
            }}
          />
        ) : null}

        <div className="flex justify-center items-center mb-10">
          <QuizStylusWrite
            containerRef={containerRef}
            deviceType={getUserAgent()}
            stylusData={setStylusData}
            setStylusData={setStylusData}
            setStylusImage={setStylusImage}
            setResponseLink={responseLink}
            stylusImageUpdateTrigger={stylusImageUpdateTrigger}
            quizMode={'survey'}
          />
        </div>
      </Stack>
    </Box>
  );
};
